// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import HttpApi from 'i18next-http-backend';

// i18n
//  .use(HttpApi)
//  .use(initReactI18next)
//  .init({
//  fallbackLng: 'en',
//  debug: true,
// interpolation: {
//  escapeValue: false,
//  },
// backend: {
//      loadPath: 'https://node207217-hr-skript.de1.mageteknik.com/{{lng}}/translation.json',
//    },
//  });

// export default i18n;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { resources } from "./translations";
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "tr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
import { zodResolver } from '@hookform/resolvers/zod';
import { App, Button, Form } from 'antd';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, redirect } from 'react-router-dom';
import { z } from 'zod';
import MyInput from '../../components/MyInput';
import { useLazyQuery } from '../../hooks/useAxios';
import styles from './SignIn.module.css';
import MyCheckbox from '../../components/MyCheckbox';
import store from '../../store/store';
import { setToken, setRefreshToken } from '../../store/auth';
import { useDispatch } from 'react-redux';

const schema = z.object({
    email: z.string(),
    // minimum one uppercase one lowercase one number
    password: z.string().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/, {
        message: <Trans i18nKey="SignIn.passwordError" />
    })
});

const SignIn = () => {
    const { notification } = App.useApp();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const form = useForm({ resolver: zodResolver(schema) });
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = form;
    const { data, loading, error, refetch } = useLazyQuery('/auth/login', {
        method: 'post'
    });
    console.log(loading)
    const onSubmit = async (data) => {
        refetch({ data });
    };

    useEffect(() => {
        if (data) {
            dispatch(setToken(data?.accessToken));
            dispatch(setRefreshToken(data?.refreshToken));
        }
    }, [data, dispatch]);

    useEffect(() => {
        if (error) {
            notification.error({
                message: error?.message || error?.errorCode
            });
        }
    }, [error]);

    return (
        <>
            <title>{t('SignIn.title')}</title>
            <div className={styles.container}>
                <div className={styles.login}>
                    <FormProvider {...form}>
                        <Form
                            onFinish={handleSubmit(onSubmit)}
                            className={styles.form}
                        >
                            <h1>{t('SignIn.title')}</h1>
                            <MyInput
                                name="email"
                                label={t('SignIn.username')}
                                autoComplete="email"
                            />
                            <MyInput
                                name="password"
                                label={t('SignIn.password')}
                                type="password"
                            />
                            {/* <MyCheckbox label={t('SignIn.rememberMe')}  name="rememberMe" /> */}
                            <Link
                                to="/forgot-password"
                                className={styles.forgotPassword}
                            >
                                {t('ForgotPassword.title')}
                            </Link>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                {t('SignIn.title')}
                            </Button>
                        </Form>
                    </FormProvider>
                </div>
                <div className={styles.register}>
                    <div className={styles.title}>
                        <div>
                            <h1>{t('SignIn.heading')}</h1>
                        </div>
                        <div>
                            <p>
                                Hemen kayıt olun ve çalışanlarınızı kolayca
                                takip edin.
                            </p>
                        </div>
                        <div>
                            <Link to={"/register"}>
                                <Button>Kayıt Ol</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignIn;

import { RouterProvider } from 'react-router-dom';
import './App.css';
import router from './router/routes';


function App() {
  // const { isSigned, token } = useSelector((state) => state.auth);

  // const isAuthenticated = isSigned || token;

  // const { data, error, loading, refetch, called } = useLazyQuery('/auth/me');

  // useEffect(() => {
  //   isAuthenticated && refetch();
  // }, [isAuthenticated, refetch]);

  // if (isAuthenticated && !called) {
  //   return <div>Loading...</div>;
  // }
  // if (loading) {
  //   return <div>Loading...</div>;
  // }
  // if (error) {
  //   // if (error.response.status === 401) store.dispatch({ type: 'SET_AUTH', payload: { isSigned: false, token: null } });
  // }
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;

import { DeleteOutlined, EditOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Card, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BranchModal from '../../components/BranchModal';
import MyTable from '../../components/MyTable';
import useRefreshTable from '../../components/MyTable/useRefreshTable';
import UserAssignModal from '../../components/UserAssignModal/UserAssingModal';
import WithAggrementCheck from '../../components/WithAgreementCheck';
import styles from './Branches.module.css';


const Branches = () => {
    const { screen_permissions } = useSelector((state) => state.auth);
    const userPermissions =
        screen_permissions.find(
            (screen) => screen.screen_name === 'branch_screen'
        )?.permissions || [];
    const { t } = useTranslation();
    const { refreshTable, handleRefreshTable } = useRefreshTable();
    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [branchId, setBranchId] = useState(null);
    const [status, setStatus] = useState('new');

    const initialColumns = [
        {
            title: <Trans i18nKey="Branches.id" />,
            dataIndex: 'branch_id',
            key: 'branch_id',
            sorter: true
        },
        {
            title: <Trans i18nKey="Branches.name" />,
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: <Trans i18nKey="Branches.address" />,
            dataIndex: 'address',
            key: 'address',
            responsive: ['md']
        },
        {
            title: <Trans i18nKey="Branches.phone_number" />,
            dataIndex: 'phone_number',
            key: 'phone_number',
            responsive: ['lg']
        },
        {
            title: <Trans i18nKey="Branches.email" />,
            dataIndex: 'email',
            key: 'email',
            responsive: ['lg']
        },
        ...(userPermissions.includes('edit') || userPermissions.includes('delete') ? [
        {
            title: <Trans i18nKey="Tables.action" />,
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                 <Button type="primary" onClick={() => handleAssignUsers(data.branch_id)}>
                    <UsergroupAddOutlined />
                  </Button>
                {userPermissions.includes('edit') ? (
                    <Button
                        className={styles.editButton}
                        type="primary"
                        onClick={() => handleEdit(data)}
                    >
                        <EditOutlined />
                    </Button>
                ) : null }
                   {userPermissions.includes('delete') ? (
                    <Button
                        className={styles.deleteButton}
                        type="primary"
                        danger
                        onClick={() => handleDelete(data)}
                    >
                        <DeleteOutlined className={styles.deleteIcon} />
                    </Button>
                   ) : null } 
                   
                 
                    
                </Space>
            )
        } ] : [] )
    ];

    const handleAssignUsers = useCallback((branch_id) => {
        setBranchId(branch_id);
        setAssignModalOpen(true);
      }, []);

    const handleEdit = useCallback((data) => {
        setModalData(data);
        setStatus('edit');
        setIsOpen(true);
    }, []);

    const handleDelete = useCallback(async (data) => {
        setModalData(data);
        setStatus('delete');
        setIsOpen(true);
    }, []);

    const handleCreate = useCallback(() => {
        setStatus('new');
        setIsOpen(true);
    }, []);

    useEffect(() => {
        if (!isOpen) {
            setModalData({});
        }
    }, [isOpen]);

    return (
        <>
            {/* <Header enableCreate={userPermissions.includes('create') ? true : false} enableCreateTitle={t('Branches.createBranch')} handleCreate={() => handleCreate()} /> */}

            <title>{t('Branches.title')}</title>
            <div>
                <BranchModal
                    open={isOpen}
                    setIsOpen={setIsOpen}
                    modalData={modalData}
                    status={status}
                    handleRefreshTable={handleRefreshTable}
                />
                  <UserAssignModal
                    isOpen={assignModalOpen}
                    setIsOpen={setAssignModalOpen}
                    id={branchId}
                    handleRefreshTable={handleRefreshTable}
                    type="branch"
                    assignEndpoint="/user/branch/assign"
                    />
                    
                    <Card
                            title={t('Branches.title')}
                            extra={
                                userPermissions.includes('create') && (
                                    <Button onClick={() => handleCreate()}>
                                        {t('Branches.createBranch')}
                                    </Button>
                                )
                            }
                        >
                        <MyTable
                        initialColumns={initialColumns}
                        url="/global/branches"
                        refreshTable={refreshTable}
                        pagination={{ position: ['bottomCenter'] }}
                        searchEnabled={true}
                    />
                    </Card>
               
            </div>
        </>
    );
};

export default WithAggrementCheck(Branches);

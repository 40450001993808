import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Form, Radio } from "antd";

const MyRadioButton = ({ label, name, options }) => {
    const { control, formState: { errors } } = useFormContext();

    return (
        <Form.Item
            label={label}
            validateStatus={errors[name] ? "error" : ""}
            help={errors[name]?.message}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
        >
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <Radio.Group {...field}>
                        {options.map((option, index) => (
                            <Radio key={index} value={option.value}>
                                {option.label}
                                {option.icon}
                            </Radio>
                        ))}
                    </Radio.Group>
                )}
            />
        </Form.Item>
    );
};

export default MyRadioButton;

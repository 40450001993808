import { Form, Select } from 'antd';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const { Option } = Select;

const MySelect = ({
    name,
    label,
    options,
    placeholder,
    mode,
    // control,
    // errors,
    onChange,
    labelCol = 24,
    wrapperCol = 24,
    ...rest
}) => {
    const { control, formState: { errors } } = useFormContext();
    return (
        <Form.Item
            label={label}
            validateStatus={errors[name] && 'error'}
            help={errors[name]?.message}
            name={name}
            labelCol={{
                span: labelCol,
                style: { paddingBottom: 0, display: 'grid' }
            }}
            wrapperCol={{ span: wrapperCol }}
            style={{ marginBottom: 0 }}
        >
            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <Select
                        {...field}
                        id={name}
                        placeholder={placeholder}
                        mode={mode}
                        onChange={(value) => {
                            field.onChange(value)
                            onChange && onChange(value);
                        }}
                        options={options}
                        {...rest}
                    >
                        {/* {options.map((option) => (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        ))} */}
                    </Select>
                )}
            />
        </Form.Item>
    );
};

export default MySelect;

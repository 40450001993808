import React from 'react';
import { useParams } from 'react-router-dom';

const Team = () => {
    const { id } = useParams()

    return <div>Team Page : {id}</div>;
};

export default Team;

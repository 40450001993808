import React, { useEffect, useState } from 'react';
import { Col, notification, Select } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MyInput from '../MyInput';
import MyModal from '../MyModal';
import { useLazyQuery } from '../../hooks/useAxios';

const { Option } = Select;

export default function DepartmentModal({
    open,
    setIsOpen,
    modalData,
    handleRefreshTable,
    status
}) {
    const [modData, setModData] = useState(modalData);
    console.log(modalData, 'modal');

    const { data: getModalData, refetch: getModal } = useLazyQuery(
        `/global/departments/${modalData.department_id}`
    );

    const getData = async () => {
        const test = await getModal();
        if (test?.result === 'success') {
            const department_tr_name = test.data.department_tr_name;
            const department_en_name = test.data.department_en_name;
            setModData({ department_tr_name, department_en_name });
        }
    };

    useEffect(() => {
        if (modalData.department_id && status === 'edit') {
            getData();
        }
    }, [modalData.department_id]);

    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const [selectedManager, setSelectedManager] = useState(
        modalData?.department_manager?.manager_user_id || null
    );
    const [selectedManagerName, setSelectedManagerName] = useState(
        modalData?.department_manager?.firstname != null
            ? modalData?.department_manager?.firstname +
                  ' ' +
                  modalData?.department_manager?.lastname
            : ''
    );
    const form = useForm({
        defaultValues: {
            department_tr_name: modData?.department_tr_name || '',
            department_en_name: modData?.department_en_name || '',
        }
    });

    const { handleSubmit, reset } = form;

    const apiConfig = {
        url:
            status === 'edit'
                ? `/global/departments/edit/${modalData.department_id}`
                : status === 'delete'
                  ? `/global/departments/delete/${modalData.department_id}`
                  : `/global/departments/create`,
        method:
            status === 'edit' ? 'put' : status === 'delete' ? 'delete' : 'post'
    };

    const { loading, refetch } = useLazyQuery(apiConfig.url, {
        method: apiConfig.method
    });

    const {
        data: userData,
        loadingUsers,
        refetch: usersRefetch
    } = useLazyQuery('/user/list');

    const { data: managerCreateData, refetch: createManager } = useLazyQuery(
        'role/create_department_manager',
        {
            method: 'post'
        }
    );

    const { data: managerEditData, refetch: setManager } = useLazyQuery(
        '/role/department-manager/edit',
        {
            method: 'put'
        }
    );
    const [departments, setDepartments] = useState([]);

    const { data: departmentsData, refetch: fetchDepartments } = useLazyQuery(
        '/global/departments-with-user'
    );
    useEffect(() => {
        if (open) {
            reset({
                department_tr_name: modData?.department_tr_name || '',
                department_en_name: modData?.department_en_name || ''
            });
            fetchDepartments().then((response) => {
                if (response?.result === 'success') {
                    setDepartments(response.data || []);
                }
            });
        }
    }, [open, modData, reset, status]);

    const handleManagerChange = (value) => {
        setSelectedManager(value);
    };

    useEffect(() => {
        if (!loadingUsers && userData) {
            const usersList = userData?.data?.results || [];
            setUsers(
                usersList.map((user) => ({
                    user_id: user.user_id,
                    full_name: `${user.firstname} ${user.lastname}`
                })) || []
            );
            setSelectedManager(
                modalData?.department_manager?.manager_user_id || null
            );
            setSelectedManagerName(
                modalData?.department_manager?.firstname != null
                    ? modalData?.department_manager?.firstname +
                          ' ' +
                          modalData?.department_manager?.lastname
                    : ''
            );
        }
    }, [loadingUsers, userData]);

    const onSubmit = async (data) => {
        try {
            const departmentData = {
                department_tr_name: data.department_tr_name,
                department_en_name: data.department_en_name
            };

            const response = await refetch({
                data:
                    status === 'new'
                        ? { departments: [departmentData] }
                        : departmentData
            });

            if (response.result === 'success') {
                if (selectedManager) {
                    const managerData1 = {
                        department_id: response.data.department_id,
                        manager_user_id: selectedManager
                    };

                    if (
                        modalData?.department_manager?.manager_user_id == null
                    ) {
                        const managerResponse = await createManager({
                            data: managerData1
                        });
                    } else {
                        const managerResponse = await setManager({
                            data: managerData1
                        });
                    }
                }

                notification.success({
                    message: t(`Departments.department_${status}_success`)
                });
                handleRefreshTable();
                setIsOpen(false);
            } else {
                notification.error({
                    message: response.result.message
                });
            }
        } catch (error) {
            notification.error({
                message: t('An error occurred!')
            });
        }
    };

    return (
        <MyModal
            title={t('Department Modal')}
            isOpen={open}
            setIsOpen={setIsOpen}
            handleSubmit={handleSubmit(onSubmit)}
            confirmLoading={loading}
            rowGap={16}
            formMethods={form}
        >
            {status !== 'delete' ? (
                <>
                    <Col span={24}>
                        <MyInput
                            name="department_tr_name"
                            label={t('Departments.department_tr')}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput
                            name="department_en_name"
                            label={t('Departments.department_en')}
                        />
                    </Col>
                    <Col span={24}>
                        <label>{t('Departments.manager')}</label>
                        <Select
                            style={{ width: '100%' }}
                            placeholder={t('Departments.select_manager')}
                            onChange={handleManagerChange}
                            value={selectedManager}
                        >
                             {departments
                                .filter(department => department.department_id === modalData.department_id)
                                .flatMap(department => 
                                    department.users?.map(user => (
                                        <Option key={user.user_id} value={user.user_id}>
                                            {user.firstname} {user.lastname} 
                                        </Option>
                                    ))
                                )
                            }
                        </Select>
                    </Col>
                </>
            ) : (
                <p>{modalData.department_name} departmanını silmek istediğinize emin misiniz ?</p>
                
                
            )}
        </MyModal>
    );
}

export default function AggreementTextSample() {
  return (
    <>
      <p>
        Kişisel verilerinizin korunması ve işlenmesi ile ilgili olarak bilgilendirme metnidir. 6698 sayılı Kişisel
        Verilerin Korunması Kanunu ("KVKK") uyarınca, kişisel verilerinizin işlenmesi ve korunması ile ilgili haklarınız
        konusunda sizleri bilgilendirmek isteriz.
      </p>
      <p>
        1. Veri Sorumlusu ve Temsilcisi: Kanun uyarınca, kişisel verileriniz; veri sorumlusu olarak bizim tarafımızdan
        aşağıda açıklanan kapsamda işlenebilecektir.
      </p>
      <p>
        2. Kişisel Verilerin İşlenme Amacı: Kişisel verileriniz, Şirketimiz tarafından sunulan ürün ve hizmetlerden
        sizleri faydalandırmak için gerekli çalışmaların iş birimlerimiz tarafından yapılması, Şirketimiz tarafından
        sunulan ürün ve hizmetlerin sizlerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek
        sizlere önerilmesi için gerekli olan faaliyetlerin yürütülmesi, Şirketimizin ve Şirketimizle iş ilişkisi
        içerisinde olan kişilerin hukuki ve ticari güvenliğinin temin edilmesi amaçlarıyla işlenmektedir.
      </p>
      <p>
        3. İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği: Toplanan kişisel verileriniz; yukarıda
        belirtilen amaçlarla iş ortaklarımıza, tedarikçilerimize, grup şirketlerimize, kanunen yetkili kamu kurumları ve
        özel kişilere, KVKK’nın 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde
        aktarılabilecektir.
      </p>
      <p>
        4. Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi: Kişisel verileriniz, her türlü sözlü, yazılı ya da
        elektronik ortamda, yukarıda yer verilen amaçlar doğrultusunda Şirketimiz tarafından sunulan ürün ve hizmetlerin
        belirlenen yasal çerçevede sunulabilmesi ve bu kapsamda Şirketimizin sözleşme ve yasadan doğan mesuliyetlerini
        eksiksiz ve doğru bir şekilde yerine getirebilmesi için toplanır.
      </p>
      <p>
        5. Kişisel Veri Sahibi Olarak Haklarınız: KVKK’nın 11. maddesi uyarınca, veri sahipleri olarak aşağıdaki haklara
        sahipsiniz:
        <ul>
          <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
          <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</li>
          <li>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
          <li>Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
          <li>Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
          <li>
            KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini
            isteme,
          </li>
          <li>
            Yukarıda belirtilen düzeltme, silme veya yok edilme işlemlerinin, kişisel verilerinizin aktarıldığı üçüncü
            kişilere bildirilmesini isteme,
          </li>
          <li>
            İşlenen verilerin münhasıran otomatik sistemler aracılığıyla analiz edilmesi suretiyle aleyhinize bir
            sonucun ortaya çıkmasına itiraz etme,
          </li>
          <li>
            Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın
            giderilmesini talep etme.
          </li>
        </ul>
      </p>
    </>
  );
};

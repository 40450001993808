import { Col, notification, Row, Select, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './SalariesModal.module.css';
import { useLazyQuery } from '../../hooks/useAxios';
import MyInput from '../MyInput';
import MyModal from '../MyModal';

const { Option } = Select;

export default function SalaryModal({ open, setIsOpen, modalData, handleRefreshTable, status }) {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    salary_price: '',
    salary_currency: '',
    salary_agreement_date: '',
    title_name: '',
    effective_date: '',
  });

  const [currencies, setCurrencies] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState('');
  const [agreementDate, setAgreementDate] = useState(null);

  const form = useForm({
    defaultValues: formData,
  });

  const { handleSubmit, reset } = form;

  const { data: salaryHistory, refetch: fetchSalaryHistory } = useLazyQuery(`/user/salary/${modalData.user_id}`);
  const { data: companySettings, refetch: fetchCurrencies } = useLazyQuery('/business/currency/list');

  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  useEffect(() => {
    if (open && status === 'edit' && modalData.user_id) {
      fetchSalaryHistory();
    }
  }, [open, status, modalData.user_id, fetchSalaryHistory]);

  useEffect(() => {
    if (salaryHistory?.result === 'success') {
      const updatedData = {
        salary_price: '',
        salary_currency: defaultCurrency || modalData.salary_currency || '',
        salary_agreement_date: '',
        effective_date: '',
        title_name: modalData.title_name || '',
      };

      setFormData(updatedData);
      reset(updatedData);
      if (updatedData.salary_agreement_date) {
        setAgreementDate(moment(updatedData.salary_agreement_date));
      }
    } else if (status === 'create') {
      const initialData = {
        salary_price: '',
        salary_currency: '',
        salary_agreement_date: '',
        title_name: '',
        effective_date: '',
      };
      setFormData(initialData);
      reset(initialData);
      setAgreementDate(null);
    }
  }, [salaryHistory, status, reset, modalData]);

  useEffect(() => {
    if (companySettings?.data) {
      setCurrencies(companySettings.data);
      if (companySettings.data.length === 1) {
        setDefaultCurrency(companySettings.data[0].currency);
        setFormData((prev) => ({ ...prev, salary_currency: companySettings.data[0].currency }));
      }
    } else {
      console.error('Currencies not found in response', companySettings);
    }
  }, [companySettings]);

  const apiConfig = {
    url: `/user/add/salary/${modalData.user_id}`,
    method: status === 'edit' ? 'post' : 'post',
  };

  const { loading, refetch: performSalaryAction } = useLazyQuery(apiConfig.url, { method: apiConfig.method });

  const onSubmit = async (data) => {
    try {
      const response = await performSalaryAction({ data });

      if (response.result === 'success') {
        notification.success({
          message: t(`Salaries.salary_${status}_success`),
        });
        handleRefreshTable();
        setIsOpen(false);
      } else {
        notification.error({
          message: response.result.message,
        });
      }
    } catch (error) {
      notification.error({
        message: t('An error occurred!'),
      });
    }
  };

  const columns = [
    {
      title: t('Salaries.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, data) => `${data.firstname} ${data.lastname}`,
    },
    {
      title: t('Salaries.salary_price'),
      key: 'salary_price',
      render: (_, record) => {
        return <span>{record?.salary_price + ' ' + record?.salary_currency}</span>;
      },
    },
    {
      title: t('Salaries.salary_agreement_date'),
      dataIndex: 'salary_agreement_date',
      key: 'salary_agreement_date',
    },
    {
      title: t('Salaries.department_name'),
      dataIndex: 'department_name',
      key: 'department_name',
    },
    {
      title: t('Salaries.title_name'),
      dataIndex: 'title_name',
      key: 'title_name',
    },
  ];
  const inputColSpan = companySettings?.data.length > 1 ? 20 : 24;
  const selectColSpan = companySettings?.data.length > 1 ? 4 : 0;
  return (
    <MyModal
      title={t(`Salaries.${status}_salary`)}
      isOpen={open}
      setIsOpen={setIsOpen}
      handleSubmit={handleSubmit(onSubmit)}
      confirmLoading={loading}
      rowGap={16}
      width={800}
      formMethods={form}
    >
      <div className={styles.salariesContainer}>
        <Col span={24}>
          <MyInput
            name="title_name"
            label={t('Salaries.title_name')}
            type="text"
            rules={{ required: t('Salaries.required') }}
          />
        </Col>

        <Row gutter={16} style={{ paddingLeft: '8px', marginRight: '0' }}>
          <Col span={inputColSpan}>
            <MyInput
              name="salary_price"
              label={t('Salaries.salary_price')}
              type="number"
              rules={{ required: t('Salaries.required') }}
            />
          </Col>
          {selectColSpan > 0 && (
            <Col span={selectColSpan} style={{ paddingTop: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label>{t('Salaries.currency')}</label>
                <Select
                  value={defaultCurrency || formData.salary_currency}
                  onChange={(value) => setFormData((prev) => ({ ...prev, salary_currency: value }))}
                  style={{ width: '100%' }}
                >
                  {currencies.map((currency) => (
                    <Option key={currency.currency} value={currency.currency}>
                      {currency.currency}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
          )}
        </Row>
        <Row gutter={16} style={{ marginBottom: '10px', paddingLeft: '8px', paddingRight: '8px' }}>
          <Col span={12}>
            <MyInput
              name="salary_agreement_date"
              label={t('Salaries.salary_agreement_date')}
              type="date"
              rules={{ required: t('Salaries.required') }}
              onChange={(date, dateString) => {
                setFormData((prev) => ({ ...prev, salary_agreement_date: dateString }));
                setAgreementDate(date);
              }}
              disabledDate={(current) => current && current < moment().startOf('day')}
            />
          </Col>
          <Col span={12}>
            <MyInput
              name="effective_date"
              label={t('Salaries.effective_date')}
              type="date"
              onChange={(dateString) => setFormData(() => ({ effective_date: dateString }))}
              disabled={!agreementDate}
              disabledDate={(current) => {
                if (!agreementDate) return true;
                return current && current < agreementDate.startOf('day');
              }}
            />
          </Col>
        </Row>

        <Table columns={columns} dataSource={salaryHistory?.data} rowKey="id" pagination={false} size="small" />
      </div>
    </MyModal>
  );
}

import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header';
import './styles.css';

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState('1');

  const onChange = (key) => {
    const tabItem = tabItems.find((item) => item.key === key);
    if (tabItem) {
      navigate(tabItem.path);
    }
  };

  useEffect(() => {
    setActiveKey(tabItems.find((item) => location.pathname.includes(item.path))?.key || '1');
  }, [location, navigate]);

  // TODO Fix
  useEffect(() => {
    if (activeKey === '1') {
      navigate('/settings/company-info');
    }
  }, [activeKey]);

  // TODO Need to add all settings
  const tabItems = [
    {
      label: 'Company Info',
      key: '1',
      path: '/settings/company-info',
    },
    {
      label: 'Rules',
      key: '2',
      path: '/settings/rules',
    },
    {
      label: 'Subscription Info',
      key: '3',
      path: '/settings/subscription-info',
    },
    // {
    //   label: 'Departure Reason',
    //   key: '4',
    //   path: '/settings/departure-reason',
    // },
    // {
    //   label: 'Expense Settings',
    //   key: '5',
    //   path: '/settings/expense-settings',
    // },
    {
      label: 'System Settings',
      key: '6',
      path: '/settings/system-settings',
    },
    {
      label: 'Departments',
      key: '7',
      path: '/settings/departments',
    },

    {
      label: 'Branches',
      key: '8',
      path: '/settings/branches',
    },
    {
      label: 'Titles',
      key: '9',
      path: '/settings/titles',
    },
    {
      label: 'Roles',
      key: '10',
      path:'/settings/roles',
    },
  ];

  return (
    <>
      <Header />
      <Tabs onChange={onChange} type="card" items={tabItems} activeKey={activeKey} />
      <Outlet />
    </>
  );
};

export default Settings;

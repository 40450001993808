import { Modal, notification, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '../../hooks/useAxios';

const UserAssignModal = ({ isOpen, setIsOpen, id, handleRefreshTable, type, assignEndpoint }) => {
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const { data: usersData, refetch: fetchUsers } = useLazyQuery('/user/list');
  const { data: editAssignData, refetch: assign } = useLazyQuery(assignEndpoint, {
    method: 'PUT',
  });
  useEffect(() => {
    if (isOpen) {
      setSelectedUsers([]);
      fetchUsers().then((response) => {
        if (response?.result === 'success') {
          setUsers(response?.data?.results || []);
        }
      });
    }
  }, [isOpen, fetchUsers]);

  const handleSubmit = async () => {
    const response = await assign({
      data: {
        users: selectedUsers,
        [`${type}_id`]: id,
      }
    });

    if (response?.result === 'success') {
      handleRefreshTable();
      setIsOpen(false);
      notification.success({
        message: t('notificationSuccess.success'),
        description: t(`${type}.assignSuccess`),

      });
    } else {
      notification.error({
        message: t('notificationSuccess.error'),
        description: t(`${type}.assignFailed`),
      });
    }
  };

  return (
    <Modal
      title={t(`${type}.assignUsers`)}
      visible={isOpen}
      onCancel={() => setIsOpen(false)}
      onOk={handleSubmit}
      okText={t(`${type}.assign`)}
      cancelText={t(`${type}.cancel`)}
    >
    <div style={{fontSize:'14px',fontWeight:'500'}}>{t('User.assign')}</div>
      <Select
        mode="multiple"
        style={{ width:'100%', paddingTop: '10px', paddingBottom: '15px' }}
        placeholder={t(`${type}.selectUsers`)}
        value={selectedUsers}
        onChange={(value) => setSelectedUsers(value)}
      >
        {users.map((user) => (
          <Select.Option key={user.user_id} value={user.user_id}>
            {user.firstname} {user.lastname}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

export default UserAssignModal;

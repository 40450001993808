import { Button, DatePicker, Form, Input, Modal, notification } from 'antd';
import moment from 'moment';
import 'moment/locale/tr';
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useTranslation } from 'react-i18next';
import { Header } from '../../components/Header';
import { useLazyQuery } from '../../hooks/useAxios';

moment.locale('tr');
const localizer = momentLocalizer(moment);

const Events = () => {
  const { t } = useTranslation();
  const [events, setEvents] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [currentYear, setCurrentYear] = useState(moment().year());

  const { data: holidayData, refetch: fetchHolidays } = useLazyQuery(`/global/public-holiday?year=${currentYear}`);

  useEffect(() => {
    const getHolidays = async () => {
      try {
        const response = await fetchHolidays();
        if (response && response.result === 'success') {
          const holidays = response.data.map((holiday) => ({
            title: holiday.title,
            start: new Date(holiday.date),
            end: new Date(holiday.date),
            allDay: true,
            isHoliday: true,
          }));
          setHolidays(holidays);
          setEvents(holidays);
        } else {
          notification.error({
            message: 'Failed to fetch holidays',
            description: response?.result?.message || 'Unknown error',
          });
        }
      } catch (error) {
        console.error('Error fetching holidays:', error);
        notification.error({
          message: 'Error fetching holidays',
          description: error.message || 'Unknown error',
        });
      }
    };

    getHolidays();
  }, [fetchHolidays, currentYear]);

  const handleAddEvent = (values) => {
    const startDate = values.date[0].toDate();
    const endDate = values.date[1].toDate();

    const isHolidayOverlap = holidays.some(
      (holiday) =>
        moment(startDate).isSame(moment(holiday.start), 'day') || moment(endDate).isSame(moment(holiday.start), 'day')
    );

    if (isHolidayOverlap) {
      notification.error({
        message: t('holidayOverlapError'),
        description: t('holidayOverlapErrorMessage'),
      });
      return;
    }

    const newEvent = {
      title: values.title,
      start: startDate,
      end: endDate,
    };
    setEvents([...events, newEvent]);
    setIsModalVisible(false);
    form.resetFields();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const eventStyleGetter = (event) => {
    const backgroundColor = event.isHoliday ? '#ff4d4f' : '#1677ff';
    const style = {
      height: event.isHoliday ? '100%' : '10px',
      backgroundColor: backgroundColor,
      borderRadius: event.isHoliday ? '0' : '0',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      zIndex: event.isHoliday ? 0 : 1,
    };
    return {
      style: style,
    };
  };

  const isDateDisabled = (date) => {
    return holidays.some((holiday) => moment(date).isSame(moment(holiday.start), 'day'));
  };

  const handleNavigate = (date, view) => {
    if (view === 'month') {
      setCurrentYear(moment(date).year());
    }
  };

  return (
    <>
      <title>{t('Events.title')}</title>
      <Header />
      <div>
        <Button style={{ marginBottom: '10px' }} type="primary" onClick={showModal}>
          {t('addEventButton')}
        </Button>
        <Modal title={t('addEventModalTitle')} visible={isModalVisible} onCancel={handleCancel} footer={null}>
          <Form form={form} onFinish={handleAddEvent}>
            <Form.Item
              name="title"
              label={t('titleLabel')}
              rules={[
                {
                  required: true,
                  message: t('titleRequiredMessage'),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="date"
              label={t('dateLabel')}
              rules={[
                {
                  required: true,
                  message: t('dateRangeRequiredMessage'),
                },
              ]}
            >
              <DatePicker.RangePicker showTime format="YYYY-MM-DD HH:mm" disabledDate={isDateDisabled} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t('addButton')}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          views={['month', 'week', 'day']}
          // selectable
          popup
          // onSelectEvent={(event) => alert(event.title)}
          // onSelectSlot={(slotInfo) => {
          //     if (!isDateDisabled(slotInfo.start)) {
          //         form.setFieldsValue({
          //             date: [moment(slotInfo.start), moment(slotInfo.end)]
          //         });
          //         showModal();
          //     } else {
          //         notification.warning({
          //             message: t('holidaySelectionWarning')
          //         });
          //     }
          // }}
          eventPropGetter={eventStyleGetter}
          onNavigate={handleNavigate}
          components={{
            toolbar: (props) => {
              const goToBack = () => {
                let mDate = props.date;
                let view = props.view;
                let newDate = view === 'month' ? moment(mDate).subtract(1, 'month') : moment(mDate).subtract(1, 'week');
                props.onNavigate('prev', newDate.toDate());
              };

              const goToNext = () => {
                let mDate = props.date;
                let view = props.view;
                let newDate = view === 'month' ? moment(mDate).add(1, 'month') : moment(mDate).add(1, 'week');
                props.onNavigate('next', newDate.toDate());
              };

              const goToCurrent = () => {
                let now = new Date();
                props.onNavigate('current', now);
              };

              return (
                <div className="rbc-toolbar">
                  <span className="rbc-btn-group">
                    <Button
                      type="primary"
                      onClick={goToBack}
                      style={{ backgroundColor: props.view === 'prev' ? '#1677ff' : '' }}
                    >
                      {t('backButton')}
                    </Button>
                    <Button
                      type="primary"
                      onClick={goToCurrent}
                      style={{ backgroundColor: props.view === 'current' ? '#1677ff' : '' }}
                    >
                      {t('todayButton')}
                    </Button>
                    <Button
                      type="primary"
                      onClick={goToNext}
                      style={{ backgroundColor: props.view === 'next' ? '#1677ff' : '' }}
                    >
                      {t('nextButton')}
                    </Button>
                  </span>
                  <span className="rbc-toolbar-label">{props.label}</span>
                  <span className="rbc-btn-group">
                    <Button
                      type="primary"
                      onClick={() => props.onView('month')}
                      style={{ backgroundColor: props.view === 'month' ? '#1677ff' : '' }}
                    >
                      {t('monthButton')}
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => props.onView('week')}
                      style={{ backgroundColor: props.view === 'week' ? '#1677ff' : '' }}
                    >
                      {t('weekButton')}
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => props.onView('day')}
                      style={{ backgroundColor: props.view === 'day' ? '#1677ff' : '' }}
                    >
                      {t('dayButton')}
                    </Button>
                  </span>
                </div>
              );
            },
          }}
        />
      </div>
    </>
  );
};

export default Events;

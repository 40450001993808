import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Space, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import MyTable from '../../components/MyTable';
import useRefreshTable from '../../components/MyTable/useRefreshTable';
import RoleModal from '../../components/RoleModal';
import { useLazyQuery } from '../../hooks/useAxios';

import styles from './Roles.module.css';

const Roles = () => {
  const { t } = useTranslation();
  const { refreshTable, handleRefreshTable } = useRefreshTable();

  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [status, setStatus] = useState('new');
  const [screens, setScreens] = useState([]);

  const { data: screensData, refetch: fetchScreens } = useLazyQuery(`/global/screens`, {
    method: 'get',
  });

  useEffect(() => {
    const fetchScreenData = async () => {
      try {
        const response = await fetchScreens();
        if (response.result === 'success') {
          setScreens(response.data);
        }
      } catch (error) {
        console.error('Error fetching screens:', error);
      }
    };

    fetchScreenData();
  }, [fetchScreens]);

  const permissionLabels = {
    1: 'Create',
    2: 'Edit',
    3: 'Delete',
    4: 'View',
  };

  const getTagColor = (permission) => {
    switch (permission) {
      case 1:
        return 'blue';
      case 2:
        return 'green';
      case 3:
        return 'purple';
      case 4:
        return 'gold';
      default:
        return 'grey';
    }
  };

  const getPermissionLabel = (permission) => {
    return permissionLabels[permission] || 'Unknown';
  };

  // used redux to get screen permission of logged in user and then checked his permissions
  const { screen_permissions } = useSelector((state) => state.auth);
  const userPermissions = screen_permissions.find((screen) => screen.screen_name === 'roles_screen')?.permissions || [];

  const initialColumns = [
    {
      title: <Trans i18nKey="Roles.role_id" />,
      dataIndex: 'role_id',
      key: 'role_id',
      sorter: true,
    },
    {
      title: <Trans i18nKey="Roles.role_name" />,
      dataIndex: 'role_name',
      key: 'role_name',
    },
    ...screens.map((screen) => ({
      title: <Trans i18nKey={screen.screen_name} />,
      key: screen.screen_name,
      dataIndex: 'access_screen',
      render: (_, record) => (
        <>
          {record.access_screen
            .filter((s) => s.screen_name === screen.screen_name)
            .flatMap((s) => s.permissions)
            .map((permission) => (
              <Tag
                color={getTagColor(permission)}
                key={permission}
                style={{ textAlign: 'center', display: 'flex', alignItems: 'center', margin: '5px' }}
              >
                {getPermissionLabel(permission)}
              </Tag>
            ))}
        </>
      ),
    })),
    {
      title: <Trans i18nKey="Tables.action" />,
      key: 'action',
      render: (_, data) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleEdit(data)}>
            <EditOutlined />
          </Button>
          <Button type="primary" danger onClick={() => handleDelete(data)}>
            <DeleteOutlined className={styles.deleteIcon} />
          </Button>
        </Space>
      ),
    },
  ];

  const handleEdit = useCallback((data) => {
    setModalData(data);
    setStatus('edit');
    setIsOpen(true);
  }, []);

  const handleDelete = useCallback((data) => {
    setModalData(data);
    setStatus('delete');
    setIsOpen(true);
  }, []);

  const handleCreate = useCallback(() => {
    setStatus('new');
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setModalData({});
    }
  }, [isOpen]);

  return (
    <>
      {/* <Header enableCreate={userPermissions.includes('create') ? true : false} enableCreateTitle={t('Roles.createRole')} handleCreate={() => handleCreate()} /> */}
      <title>{t('Roles.title')}</title>
      <div>
        <RoleModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          modalData={modalData}
          status={status}
          handleRefreshTable={handleRefreshTable}
        />
        <Card
            title={t('Roles.title')}
            extra={
                userPermissions.includes('create') && (
                    <Button onClick={() => handleCreate()}>
                        {t('Roles.createRole')}
                    </Button>
                )
            }
        >
        <MyTable
                  initialColumns={initialColumns}
                  url="/global/roles-screen"
                  refreshTable={refreshTable}
                  searchEnabled={true}
                  pagination={{ position: ['bottomCenter'] }}
                />

        </Card>
       
      </div>
    </>
  );
};

export default Roles;

import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Col,
    Flex,
    Input,
    Popover,
    Row,
    Table,
    theme
} from 'antd';
import useMyTable from './useMyTable';
import MyTableFiltration from './MyTableFiltration';

const MyTable = ({
    initialColumns,
    searchEnabled = false,
    initialPageSize = 10,
    refreshTable,
    isOffline = false,
    initialData,
    enablePagination = true,
    enableColumnFilter = true,
    url,
    rowKey = 'id',
    Filtration = null,
    ...props
}) => {
    const {
        token: { marginSM }
    } = theme.useToken();

    const {
        columns,
        data: onlineData,
        loading,
        handleTableChange,
        pagination,
        handleToggleColumn,
        hiddenColumns,
        handleSearch,
        getColumns,
        defaultSearchValue
    } = useMyTable({
        columns: initialColumns,
        url: url,
        initialPageSize: initialPageSize,
        enableSearching: searchEnabled,
        refreshTable,
        isOffline,
        enablePagination
    });
    const data = isOffline ? initialData : onlineData;

    const Menu = () => {
        return (
            <Checkbox.Group
                style={{ width: '100%' }}
                onChange={handleToggleColumn}
                value={columns
                    .filter((column) => !hiddenColumns.includes(column.key))
                    .map((column) => column.key)}
            >
                <Row>
                    {columns.map((column) => (
                        <Col span={8} key={column.key}>
                            <Checkbox value={column.key}>
                                {column.title}
                            </Checkbox>
                        </Col>
                    ))}
                </Row>
            </Checkbox.Group>
        );
    };

    return (
        <>
            <Row gutter={[16, 24]}>
                {searchEnabled && (
                    <Col flex={'200px'}>
                        <Input.Search
                            placeholder="Search"
                            onSearch={handleSearch}
                            style={{ width: 200 }}
                            loading={loading}
                            defaultValue={defaultSearchValue}
                        />
                    </Col>
                )}
                {Filtration && <Col span={12} >
                    <MyTableFiltration >
                        {Filtration}
                    </MyTableFiltration>
                </Col>}
                <Flex gap={4}>
                    {/* {enableColumnFilter && (
                        <Popover
                            content={<Menu />}
                            trigger="click"
                            placement="bottomLeft"
                        >
                            <Button>
                                Columns <DownOutlined />
                            </Button>
                        </Popover>
                    )} */}
                </Flex>
            </Row>

            <div style={{ overflow: 'auto', background: '#fff', marginTop:'10px' }}>
                <Table
                    columns={getColumns()}
                    dataSource={data}
                    pagination={enablePagination && pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    rowKey={rowKey}
                    {...props}
                />
            </div>
        </>
    );
};
export default MyTable;

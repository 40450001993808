import { useState } from 'react';
import { Button, Card, Flex, Radio, Space, Tabs, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { EditOutlined, DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Header } from '../../components/Header';
import MyTable from '../../components/MyTable';
import useRefreshTable from '../../components/MyTable/useRefreshTable';
import ExpenseModal from '../../components/ExpenseModal/ExpenseModal';

const getFilter = (filter) => {
  switch (filter) {
    case 'waiting':
      return {
        status: '?status=1',
      };
    case 'approved':
      return {
        status: '?status=2',
      };
    case 'rejected':
      return {
        status: '?status=3',
      };
    case 'closed':
      return {
        status: '?status=4',
      };
    default:
      return {
        status: '',
      };
  }
};

const Expenses = () => {
  const { screen_permissions } = useSelector((state) => state.auth);
  const userPermissions =
    screen_permissions.find((screen) => screen.screen_name === 'expense_screen')?.permissions || [];

  const [isOpen, setIsOpen] = useState(false);
  const [expenseItem, setExpenseItem] = useState(1);
  const [modalData, setModalData] = useState();
  const [status, setStatus] = useState();
  const [filter, setFilter] = useState('upcoming');
  const filterTabs = getFilter(filter);
  const { t } = useTranslation();

  const initialColumns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: <Trans i18nKey={'Expenses.name'} />,
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: <Trans i18nKey={'Expenses.description'} />,
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: <Trans i18nKey={'global.created_at'} />,
      key: 'created_at',
      render: (_, record) => <span>{dayjs(record?.created_at).format('DD/MM/YYYY HH:mm')}</span>,
    },
    {
      title: <Trans i18nKey={'Expenses.status'} />,
      key: 'status',
      render: (_, record) => {
        const status =
          record.status === 1
            ? 'processing'
            : record.status === 2
              ? 'success'
              : record.status === 3
                ? 'rejected'
                : 'warning';
        const statusLabel =
          record.status === 1
            ? 'Onay bekleniyor'
            : record.status === 2
              ? 'Onaylandı'
              : record.status === 3
                ? 'Reddedildi'
                : 'Tamamlandı';

        return <Tag color={status}>{statusLabel}</Tag>;
      },
    },
    ...(userPermissions.includes('edit') || userPermissions.includes('delete')
      ? [
          {
            title: 'Action',
            key: 'action',
            render: (_, data) => {
              if (data?.status !== 4) {
                return (
                  <Space size="middle">
                    {userPermissions.includes('delete') &&
                      userPermissions.includes('edit') &&
                      userPermissions.includes('create') && (
                        <Button type="primary" onClick={() => handleApprove(data)}>
                          <CheckOutlined />
                        </Button>
                      )}
                    {userPermissions.includes('edit') && (
                      <Button type="primary" onClick={() => handleEdit(data)}>
                        <EditOutlined />
                      </Button>
                    )}
                    {userPermissions.includes('delete') && data?.business_id !== null && (
                      <Button type="primary" danger onClick={() => handleDelete(data)}>
                        <DeleteOutlined />
                      </Button>
                    )}
                  </Space>
                );
              }
            },
          },
        ]
      : []),
  ];

  const { refreshTable, handleRefreshTable } = useRefreshTable();

  const handleCreate = () => {
    setStatus('new');
    setExpenseItem(0);
    setIsOpen(true);
    setModalData();
  };

  const handleEdit = (data) => {
    setStatus('edit');
    setModalData(data);
    setIsOpen(true);
  };

  const handleDelete = (data) => {
    console.log('delete');
    setStatus('delete');
    setModalData(data);
    setIsOpen(true);
  };

  const handleApprove = (data) => {
    console.log(data, 'approve');
    setStatus('approve');
    setModalData(data);
    setIsOpen(true);
  };

  const tabItems = [
    {
      key: 'expenses',
      label: 'Expenses',
      children: (
        <>
          <Radio.Group buttonStyle="solid" value={filter} onChange={(e) => setFilter(e.target.value)}>
          <Radio.Button value="">Tümü</Radio.Button>
            <Radio.Button value="waiting">Onay Bekleyenler</Radio.Button>
            <Radio.Button value="approved">Onaylananlar</Radio.Button>
            <Radio.Button value="rejected">Reddedilenler</Radio.Button>
            <Radio.Button value="closed">Tamamlananlar</Radio.Button>
          </Radio.Group>
          <h1></h1>
          <Card title="Harcama Talepleri">
            <MyTable
              initialColumns={initialColumns}
              url={"/expense/list" + filterTabs.status}
              refreshTable={refreshTable}
              pagination={{ position: ['bottomCenter'] }}
            />
          </Card>
        </>
      ),
    },
    {
      key: 'status',
      title: 'Durum',
    },
  ];

  return (
    <>
      <Header
        enableCreate={userPermissions.includes('create') ? true : false}
        enableCreateTitle="Harcama Talebi Oluştur"
        handleCreate={handleCreate}
      />

      <Tabs defaultValue={'expenses'} items={tabItems} />

      <ExpenseModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalData={modalData}
        handleRefreshTable={handleRefreshTable}
        status={status}
        expenseItem={expenseItem}
        setExpenseItem={setExpenseItem}
        userPermissions={userPermissions}
      />
    </>
  );
};

export default Expenses;

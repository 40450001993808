import { DeleteOutlined, EditOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Card, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import MyTable from '../../components/MyTable';
import useRefreshTable from '../../components/MyTable/useRefreshTable';
import TitleModal from '../../components/TitleModal';

import { useSelector } from 'react-redux';
import UserAssignModal from '../../components/UserAssignModal/UserAssingModal';
import styles from './Titles.module.css';

const Titles = () => {
    const {screen_permissions} = useSelector((state)=> state.auth);
    const userPermissions = 
        screen_permissions.find(
            (screen)=>screen.screen_name === 'title_screen'
        )?.permissions || [];

    const { t } = useTranslation();
    const { refreshTable, handleRefreshTable } = useRefreshTable();

    const [isOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [status, setStatus] = useState('new');
    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [titleId, setTitleId] = useState(null);


    const initialColumns = [
        {
            title: <Trans i18nKey="Titles.id" />,
            dataIndex: 'user_title_id',
            key: 'user_title_id',
            sorter: true
        },
        {
            title: <Trans i18nKey="Titles.title_name" />,
            dataIndex: 'title_name',
            key: 'title_name'
        },
        ...(userPermissions.includes('edit') || 
        userPermissions.includes('delete')
        ? [
        {
            title: <Trans i18nKey="Tables.action" />,
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                 <Button type="primary" onClick={() => handleAssignUsers(data.user_title_id)}>
                    <UsergroupAddOutlined />
                  </Button>
               {userPermissions.includes('edit') && data.business_id !== null && (
                                <Button type="primary" onClick={() => handleEdit(data)}>
                                    <EditOutlined />
                                </Button>
                            )}
                    {userPermissions.includes('delete') && data.business_id !== null && ( 
                        <Button
                        type="primary"
                        danger
                        onClick={() => handleDelete(data)}
                    >
                        <DeleteOutlined className={styles.deleteIcon} />
                    </Button>
                    )}
                   
                </Space>
            )
        }  ] : [] )
    ];
    const handleEdit = useCallback((data) => {
        setModalData(data);
        setStatus('edit');
        setIsOpen(true);
    }, []);

    const handleDelete = useCallback((data) => {
        setModalData(data);
        setStatus('delete');
        setIsOpen(true);
    }, []);

    const handleCreate = useCallback(() => {
        setStatus('new');
        setIsOpen(true);
    }, []);

    useEffect(() => {
        if (!isOpen) {
            setModalData({});
        }
    }, [isOpen]);
    const handleAssignUsers = useCallback((user_title_id) => {
        setTitleId(user_title_id);
        setAssignModalOpen(true);
      }, []);
    return (
        <>
            {/* <Header enableCreate={userPermissions.includes('create') ? true : false} enableCreateTitle={t('Titles.createTitle')} handleCreate={() => handleCreate()} /> */}

            <title>{t('Titles.title')}</title>
            <div>
                <TitleModal
                    open={isOpen}
                    setIsOpen={setIsOpen}
                    modalData={modalData}
                    status={status}
                    handleRefreshTable={handleRefreshTable}
                />
                 <UserAssignModal
                    isOpen={assignModalOpen}
                    setIsOpen={setAssignModalOpen}
                    id={titleId}
                    handleRefreshTable={handleRefreshTable}
                    type="title"
                    assignEndpoint="/user/title/update"
                    />
                <Card
                            title={t('Titles.title')}
                            extra={
                                userPermissions.includes('create') && (
                                    <Button onClick={() => handleCreate()}>
                                        {t('Titles.createTitle')}
                                    </Button>
                                )
                            }
                        >
                    <MyTable
                        initialColumns={initialColumns}
                        url="/global/titles"
                        refreshTable={refreshTable}
                        pagination={{ position: ['bottomCenter'] }}
                        searchEnabled={true}
                    />
                </Card>

               
            </div>
        </>
    );
};
export default Titles;

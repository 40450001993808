import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Flex, List } from 'antd';
import { useForm } from 'react-hook-form';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import styles from './ExpenseSettings.module.css';
import { useLazyQuery } from '../../../hooks/useAxios';
import MyModal from '../../../components/MyModal';
import MyInput from '../../../components/MyInput';

const ExpenseSettings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [status, setStatus] = useState();

  const form = useForm({
    defaultValues: {
      type_tr_name: '',
      description_tr: '',
      type_en_name: '',
      description_en: '',
    },
  });

  const { handleSubmit, reset } = form;

  const { data, loading, refetch } = useLazyQuery('/expense/expense-types');
  const {
    data: createExpenseTypeData,
    loading: createExpenseTypeLoading,
    refetch: createExpenseType,
  } = useLazyQuery('/expense/expense-types/create', { method: 'POST' });

  const {
    data: editExpenseTypeData,
    loading: editExpenseTypeLoading,
    refetch: editExpenseType,
  } = useLazyQuery(`/expense/expense-types/update/${modalData?.id}`, { method: 'PUT' });

  const {
    data: getExpenseTypeData,
    loading: getExpenseTypeLoading,
    refetch: getExpenseType,
  } = useLazyQuery(`/expense/get-expense-type/${modalData?.id}`);

  const {
    data: deleteExpenseTypeData,
    loading: deleteExpenseTypeLoading,
    refetch: deleteExpenseType,
  } = useLazyQuery(`/expense/expense-types/delete/${modalData?.id}`, { method: 'DELETE' });

  useEffect(() => {
    const getData = async () => {
      const response = await refetch();
      console.log(response, 'response1234');
    };
    getData();
  }, [refetch]);

  useEffect(() => {
    if (modalData && status === 'edit' && isOpen) {
      const editData = async () => {
        const response = await getExpenseType();
        console.log(response, 'responseEDIT');
        if (response?.result === 'success') {
          reset({
            type_tr_name: response.data[0].type_tr_name || '',
            description_tr: response.data[0].description_tr || '',
            type_en_name: response.data[0].type_en_name || '',
            description_en: response.data[0].description_en || '',
          });
        }
      };
      editData();
    } else {
      reset({
        type_tr_name: '',
        description_tr: '',
        type_en_name: '',
        description_en: '',
      });
    }
  }, [isOpen, modalData, reset]);

  const onSubmit = async (data) => {
    console.log(data, 'data123');
    // return true;
    // TODO make a send request for creating expense types
    if (status === 'new') {
      const response = await createExpenseType({ data });
      console.log(response, 'response create');
    } else if (status === 'edit') {
      const responseEdit = await editExpenseType({ data });
      console.log(responseEdit, 'response edit');
    } else if (status === 'delete') {
      const responseDelete = await deleteExpenseType();
      console.log(responseDelete, 'response delete');
    }
    refetch();
    setIsOpen(false);
  };

  const handleCreate = () => {
    setStatus('new');
    setModalData([]);
    setIsOpen(true);
  };

  const handleEdit = (data) => {
    console.log(data, 'handleEdit');
    getExpenseType();
    setStatus('edit');
    setModalData(data);
    setIsOpen(true);
  };

  const handleDelete = (data) => {
    console.log('delete');
    setStatus('delete');
    setModalData(data);
    setIsOpen(true);
  };

  if (loading || !data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <MyModal
        title="Masraf Talebi Seçeneği Ekle"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleSubmit={handleSubmit(onSubmit)}
        confirmLoading={loading}
        rowGap={16}
        formMethods={form}
      >
        {status !== 'delete' ? (
          <>
            <Col span={24}>
              <MyInput name="type_tr_name" label="Masraf Adı Türkçe" />
            </Col>
            <Col span={24}>
              <MyInput name="description_tr" label="Masraf Açıklaması Türkçe" />
            </Col>
            <Col span={24}>
              <MyInput name="type_en_name" label="Masraf Adı İngilizce" />
            </Col>
            <Col span={24}>
              <MyInput name="description_en" label="Masraf Açıklaması İngilizce" />
            </Col>
          </>
        ) : (
          <>are you sure want to delete item?</>
        )}
      </MyModal>
      <Card title="Expense Settings">
        <Card title="Expense Types" extra={<Button onClick={() => handleCreate()}>Ekle</Button>}>
          <Flex justify="space-between">
            <p>
              Şirketinize ait masraf talebi seçenekleri listesi. Listeye yeni masraf talebi seçenekleri
              ekleyebilirsiniz.
            </p>
          </Flex>
          <List
            bordered
            dataSource={data.data}
            renderItem={(item) => (
              <List.Item
                actions={
                  item.business_id != null
                    ? [
                        <Button type="primary" onClick={() => handleEdit(item)}>
                          <EditOutlined />
                        </Button>,
                        <Button type="primary" danger onClick={() => handleDelete(item)}>
                          <DeleteOutlined />
                        </Button>,
                      ]
                    : null
                }
              >
                <div className={styles.listItemContainer}>
                  <div>
                    <span className={styles.title}>{item.name}</span>
                    <br />
                    <span>{item.description}</span>
                  </div>
                </div>
              </List.Item>
            )}
          />
        </Card>
      </Card>
    </>
  );
};

export default ExpenseSettings;

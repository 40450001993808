import { useCallback, useEffect, useState } from 'react';
import axiosInstance from '../api';
import { notification } from 'antd';
import { t } from 'i18next';

const useQuery = (url, options = {}) => {
  const {
    variables = {},
    method = 'get',
    onCompleted = () => {},
    onError = () => {},
    contentType = 'application/json',
  } = options;
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance(url, {
        method: method,
        data: variables,
        headers: contentType,
      });
      setData(response?.data?.data);
      onCompleted(response?.data?.data);

      setError(null);
    } catch (err) {
      setError(err);
      setData(null);
      onError(err?.response?.data?.message || err, err?.response?.data || err?.message || err);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, error, loading, refetch: fetchData };
};

const useLazyQuery = (url, options = {}) => {
  const {
    method = 'get',
    variables,
    onCompleted = () => {},
    onError = () => {},
    contentType = 'application/json',
  } = options;
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [called, setCalled] = useState(false); // Added state for tracking if fetchData has been called

  const fetchData = useCallback(
    async (parameters = {}) => {
      const { data, params } = parameters;
      setLoading(true);
      setCalled(true); // Update called state to true when fetchData is flled
      try {
        const response = await axiosInstance(
          url,
          {
            data,
            params,
            method: method,
            headers: contentType,
          },
          {}
        );
        //TODO setData response for result response
        setData(response.data);
        setError(null);
        onCompleted(response.data);

        return response.data;
      } catch (err) {
        setError(err?.response?.data || err);
        setData(null);
        onError(err?.response?.data?.message || err, err?.response?.data || err?.message || err);
        // return err?.response?.data;
        console.log(err?.response?.data, 'asd');
        return err?.response?.data;
        // throw err?.response?.data?.message || err?.response?.data || err?.message || err;
      } finally {
        setLoading(false);
      }
    },
    [url]
  );

  return { data, error, loading, called, refetch: fetchData };
};

const useLazyDelete = (baseUrl, options = {}) => {
  const {
    onCompleted = () => { },
    onError = () => { },
    notificationOptions = {
      successMessage: 'Operation Successful',
      successDescription: 'The operation was completed successfully.',
      errorMessage: 'Operation Failed',
      errorDescription: 'The operation failed due to an error.',
    },
  } = options;

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [called, setCalled] = useState(false);

  const fetchData = useCallback(
    async (id) => {
      setLoading(true);
      setCalled(true);
      try {
        const response = await axiosInstance.delete(`${baseUrl}/${id}`);
        setData(response.data);
        setError(null);
        onCompleted(response.data);
        notification.success({
          message: notificationOptions.successMessage,
          description: notificationOptions.successDescription,
        });
        return response.data;
      } catch (err) {
        if (err?.response?.data?.errorCode) {
          notification.error({
            message: t(`errorCode.${err?.response?.data?.errorCode}.title`),
            description: t(`errorCode.${err?.response?.data?.errorCode}.message`),
          });
        } else {
          notification.error({
            message: notificationOptions.errorMessage,
            description: notificationOptions.errorDescription,
          });
        }

        setError(err.response ? err.response.data : err);
        setData(null);
        onError(err.response ? err.response.data : err);
      } finally {
        setLoading(false);
      }
    },
    [baseUrl, onCompleted, onError]
  );

  return { data, error, loading, called, refetch: fetchData };
};

export { useLazyQuery, useQuery, useLazyDelete };

import React, { useState } from 'react';
import MyModal from '../MyModal';
import MySelect from '../MySelect';
import { useForm } from 'react-hook-form';
import { Col } from 'antd';

const data = {
    general: {
        id: 'general',
        name: 'Genel Bilgiler',
        items: {
            firstName: {
                id: 'firstName',
                name: 'Ad'
            },
            lastName: {
                id: 'lastName',
                name: 'Soyad'
            },
            workEmail: {
                id: 'workEmail',
                name: 'E-posta (iş)'
            },
            email: {
                id: 'email',
                name: 'E-posta (Kişisel)'
            },
            workPhone: {
                id: 'workPhone',
                name: 'Telefon (İş)'
            },
            mobilePhone: {
                id: 'mobilePhone',
                name: 'Telefon (Kişisel)'
            },
            employmentStartDate: {
                id: 'employmentStartDate',
                name: 'İşe Başlama Tarihi'
            },
            contractType: {
                id: 'contractType',
                name: 'Sözleşme Türü'
            },
            contractEndDate: {
                id: 'contractEndDate',
                name: 'Sözleşme Bitiş Tarihi'
            },
            accessType: {
                id: 'accessType',
                name: 'Erişim Türü'
            },
            manager: {
                id: 'manager',
                name: 'Yönetici'
            },
            accrualStartDate: {
                id: 'accrualStartDate',
                name: 'İzne Esas Hak Ediş Tarihi'
            }
        }
    },
    units: {
        id: 'units',
        name: 'Birimler',
        items: {
            f59d26a607f17a923fafd148add92a89: {
                id: 'f59d26a607f17a923fafd148add92a89',
                name: 'Şirket'
            },
            b9f7e9e87cc1b02a99d610f0eb61ef74: {
                id: 'b9f7e9e87cc1b02a99d610f0eb61ef74',
                name: 'Şube'
            },
            d9d2fbf22eddba1087728f95c2015e67: {
                id: 'd9d2fbf22eddba1087728f95c2015e67',
                name: 'Departman'
            },
            a23209634f972d10c5ca252ccb1162a5: {
                id: 'a23209634f972d10c5ca252ccb1162a5',
                name: 'Unvan'
            }
        }
    },
    personal: {
        id: 'personal',
        name: 'Kişisel Bilgiler',
        items: {
            birthday: {
                id: 'birthday',
                name: 'Doğum Tarihi'
            },
            idNumber: {
                id: 'idNumber',
                name: 'Kimlik Numarası'
            },
            maritalStatus: {
                id: 'maritalStatus',
                name: 'Medeni Hal'
            },
            gender: {
                id: 'gender',
                name: 'Cinsiyet'
            },
            levelOfDisability: {
                id: 'levelOfDisability',
                name: 'Engel Derecesi'
            },
            educationStatus: {
                id: 'educationStatus',
                name: 'Eğitim Durumu'
            },
            educationLevel: {
                id: 'educationLevel',
                name: 'Tamamlanan En Yüksek Eğitim Seviyesi'
            },
            educational_institution: {
                id: 'educationalInstitution',
                name: 'Son Tamamlanan Eğitim Kurumu'
            },
            nationality: {
                id: 'nationality',
                name: 'Uyruğu'
            },
            numberOfChildren: {
                id: 'numberOfChildren',
                name: 'Çocuk sayısı'
            },
            bloodType: {
                id: 'bloodType',
                name: 'Kan Grubu'
            }
        }
    },
    other: {
        id: 'other',
        name: 'Diğer Bilgiler',
        items: {
            addressPhoneNumber: {
                id: 'addressPhoneNumber',
                name: 'Telefon Numarası'
            },
            address: {
                id: 'address',
                name: 'Adres'
            },
            addressMore: {
                id: 'addressMore',
                name: 'Adres (devam)'
            },
            homePhone: {
                id: 'homePhone',
                name: 'Ev Telefonu'
            },
            country: {
                id: 'country',
                name: 'Ülke'
            },
            city: {
                id: 'city',
                name: 'Şehir'
            },
            postalCode: {
                id: 'postalCode',
                name: 'Posta Kodu'
            }
        }
    },
    bank: {
        id: 'bank',
        name: 'Banka Bilgileri',
        items: {
            bankName: {
                id: 'bankName',
                name: 'Banka Adı'
            },
            accountNo: {
                id: 'accountNo',
                name: 'Hesap No'
            },
            accountType: {
                id: 'accountType',
                name: 'Hesap Tipi'
            },
            iban: {
                id: 'iban',
                name: 'IBAN'
            }
        }
    },
    emergency: {
        id: 'emergency',
        name: 'Acil Durum Bilgileri',
        items: {
            nameSurname: {
                id: 'nameSurname',
                name: 'İsim Soyisim'
            },
            phoneNumber: {
                id: 'phoneNumber',
                name: 'Telefon Numarası'
            },
            familyRelationship: {
                id: 'familyRelationship',
                name: 'Yakınlık Derecesi'
            }
        }
    },
    transaction: {
        id: 'transaction',
        name: 'Ödemeler',
        items: {
            type: {
                id: 'type',
                name: 'Ödeme Türü'
            },
            'amount-currency': {
                id: 'amount-currency',
                name: 'Ödeme Tutarı Ve Para Birimi'
            },
            'expense-category': {
                id: 'expense-category',
                name: 'Harcama Kategorisi'
            },
            status: {
                id: 'status',
                name: 'Durum'
            },
            paid: {
                id: 'paid',
                name: 'Ödendi'
            },
            description: {
                id: 'description',
                name: 'Açıklama'
            },
            createdAt: {
                id: 'createdAt',
                name: 'Oluşturulma Tarihi'
            },
            date: {
                id: 'date',
                name: 'Tarih'
            },
            installmentPlan: {
                id: 'installmentPlan',
                name: 'Taksit'
            },
            rejectReason: {
                id: 'rejectReason',
                name: 'Reddedilme Nedeni'
            },
            isGross: {
                id: 'isGross',
                name: 'Net/Brüt'
            }
        }
    },
    timelogs: {
        id: 'timelogs',
        name: 'Mesailer',
        items: {
            startDate: {
                id: 'startDate',
                name: 'Mesai Başlangıç Tarihi'
            },
            description: {
                id: 'description',
                name: 'Açıklama'
            },
            usedMinute: {
                id: 'usedMinute',
                name: 'Çalışma Süresi'
            },
            convertType: {
                id: 'convertType',
                name: 'Durum'
            }
        }
    },
    leaveDays: {
        id: 'leaveDays',
        name: 'İzinler',
        items: {
            createdAt: {
                id: 'createdAt',
                name: 'Oluşturulma Tarihi'
            },
            startDate: {
                id: 'startDate',
                name: 'İzin Başlangıç Tarihi'
            },
            endDate: {
                id: 'endDate',
                name: 'İzin Bitiş Tarihi'
            },
            returnDate: {
                id: 'returnDate',
                name: 'İzin Göreve Başlama Tarihi'
            },
            usedDays: {
                id: 'usedDays',
                name: 'Kullanılan Gün'
            },
            leaveType: {
                id: 'leaveType',
                name: 'İzin Türü'
            },
            leaveFormSigned: {
                id: 'leaveFormSigned',
                name: 'İmzalandı'
            },
            status: {
                id: 'status',
                name: 'Durum'
            },
            rejectReason: {
                id: 'rejectReason',
                name: 'Reddedilme Nedeni'
            },
            comment: {
                id: 'comment',
                name: 'Açıklama'
            }
        }
    },
    salaryInformation: {
        id: 'salaryInformation',
        name: 'Maaş Bilgileri',
        items: {
            salary: {
                id: 'salary',
                name: 'Maaş'
            },
            currency: {
                id: 'currency',
                name: 'Para Birimi'
            },
            effectiveDate: {
                id: 'effectiveDate',
                name: 'Geçerlilik Tarihi'
            },
            salaryPeriod: {
                id: 'salaryPeriod',
                name: 'Ücret Türü'
            },
            netGross: {
                id: 'netGross',
                name: 'Net/Brüt'
            }
        }
    },
    customFields: {
        id: 'customFields',
        name: 'Özel Alanlar',
        items: []
    }
};

export default function FieldModal() {
    const form = useForm();
    const [open, setOpen] = useState(true);
    return (
        <MyModal
            title="Field Modal"
            isOpen={open}
            setIsOpen={setOpen}
            handleSubmit={() => { }}
            confirmLoading={false}
            rowGap={16}
            formMethods={form}
        >
            {Object.keys(data).map((key) => {
                return (
                    <Col span={24}>
                        <MySelect
                            labelCol={6}
                            wrapperCol={18}
                            key={key}
                            name={key}
                            label={data[key].name}

                            options={Object.keys(data[key].items).map(
                                (item) => ({
                                    value: item,
                                    label: data[key].items[item].name
                                })
                            )}
                        />
                    </Col>
                );
            })}
        </MyModal>
    );
}

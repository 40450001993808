import { Card, Col, Row, Typography } from 'antd';
import React from 'react';
import '../styles.css'; // Import the CSS file
import subscriptionInfoData from './subscriptionInfo.json';

const { Title, Text } = Typography;

const SubscriptionInfo = () => {
  return (
    <Card title="Subscription Information" className="custom-card">
      <Row gutter={[16, 16]} className="info-list">
        <Col span={12}>
          <Title level={5}>Plan Name:</Title>
          <Text>{subscriptionInfoData.subscription_info.plan_name}</Text>
        </Col>
        <Col span={12}>
          <Title level={5}>Plan Status:</Title>
          <Text>{subscriptionInfoData.subscription_info.plan_status}</Text>
        </Col>
        <Col span={12}>
          <Title level={5}>Plan Start Date:</Title>
          <Text>{subscriptionInfoData.subscription_info.plan_start_date}</Text>
        </Col>
        <Col span={12}>
          <Title level={5}>Plan End Date:</Title>
          <Text>{subscriptionInfoData.subscription_info.plan_end_date}</Text>
        </Col>
        <Col span={12}>
          <Title level={5}>Price per Month:</Title>
          <Text>{subscriptionInfoData.subscription_info.price_per_month}</Text>
        </Col>
        <Col span={12}>
          <Title level={5}>Payment Method:</Title>
          <Text>{subscriptionInfoData.subscription_info.payment_method}</Text>
        </Col>
      </Row>
    </Card>
  );
};

export default SubscriptionInfo;

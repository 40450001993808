import React from 'react';
import { Col, Divider } from 'antd';
import MyInput from '../../../../components/MyInput';
import { z } from 'zod';
import dayjs from 'dayjs';
import MySelect from '../../../../components/MySelect';
import { checkTcNum } from '../../../../helpers/helpers';
import ChangeModal from '../ChangeModal/ChangeModal';

const schema = z.object({
    birth_date: z.coerce
        .date()
        .optional()
        .refine(
            (date) => {
                if (dayjs(date).isAfter(dayjs())) {
                    return false;
                }
                return true;
            },
            {
                message: 'Doğum tarihi bugünden ileri olamaz'
            }
        ),
    gender: z.string().optional().nullable(),
    level_of_disability: z.number().int().optional().nullable(),
    nationality: z.string().optional().nullable(),
    citizen_id: z
        .string()
        .optional()
        .refine((value) => checkTcNum(value), {
            message: 'Geçerli bir TC Kimlik Numarası giriniz'
        })
});

const CitizenshipModal = ({ isOpen, setIsOpen, data }) => {
    return (
        <ChangeModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            data={{
                ...data,
                birth_date: data?.birth_date
                    ? dayjs(data?.birth_date)
                    : undefined
            }}
            schema={schema}
            success="Vatandaşlık bilgileriniz başarıyla güncellendi"
            error="Vatandaşlık bilgileriniz güncellenirken bir hata oluştu"
            title="Vatandaşlık Bilgileri"
        >
            <Col span={12}>
                <MyInput name="birth_date" label="Doğum Tarihi" type="date" />
            </Col>
            <Col span={12}>
                <MySelect
                    name="gender"
                    label="Cinsiyet"
                    options={[
                        { label: 'Erkek', value: 'man' },
                        { label: 'Kadın', value: 'woman' }
                    ]}
                />
            </Col>
            <Col span={12}>
                <MySelect
                    label="Engel Derecesi"
                    name="level_of_disability"
                    options={[
                        { label: 'Yok', value: 0 },
                        { label: '1. Derece', value: 1 },
                        { label: '2. Derece', value: 2 },
                        { label: '3. Derece', value: 3 }
                    ]}
                />
            </Col>
            <Divider />
            <Col span={12}>
                <MySelect
                    label="Uyruğu"
                    name="nationality"
                    options={[
                        { label: 'Türkiye', value: 'TR' },
                        { label: 'Almanya', value: 'DE' },
                        { label: 'Fransa', value: 'FR' }
                    ]}
                />
            </Col>
            <Col span={12}>
                <MyInput name="citizen_id" label="Kimlik Numarası" />
            </Col>
        </ChangeModal>
    );
};

export default CitizenshipModal;

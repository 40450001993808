import { DeleteOutlined, EditOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Card, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import DepartmentModal from '../../components/DepartmentModal';
import MyTable from '../../components/MyTable';
import useRefreshTable from '../../components/MyTable/useRefreshTable';
import UserAssignModal from '../../components/UserAssignModal/UserAssingModal';

import { useSelector } from 'react-redux';
import styles from './Departments.module.css';

const Departments = () => {
  const { screen_permissions } = useSelector((state) => state.auth);
  const userPermissions =
    screen_permissions.find((screen) => screen.screen_name === 'department_screen')?.permissions || [];

  const { t } = useTranslation();
  const { refreshTable, handleRefreshTable } = useRefreshTable();

  const [isOpen, setIsOpen] = useState(false);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [status, setStatus] = useState('new');
  const [departmentId, setDepartmentId] = useState(null);
  const initialColumns = [
    {
      title: <Trans i18nKey="Departments.id" />,
      dataIndex: 'department_id',
      key: 'department_id',
      sorter: true,
    },
    {
      title: <Trans i18nKey="Departments.department_name" />,
      dataIndex: 'department_name',
      key: 'department_name',
    },
    {
      title: <Trans i18nKey="Departments.manager" />,
      key: 'manager',
      render: (_, data) => {
        const manager = data.department_manager;
        return (
          <div>
            {manager?.firstname} {manager?.lastname}
            <br />
            {manager?.email}
            <br />
            {manager?.phone_number}
          </div>
        );
      },
    },
    ...(userPermissions.includes('edit') || userPermissions.includes('delete')
      ? [
          {
            title: <Trans i18nKey="Tables.action" />,
            key: 'action',
            render: (_, data) => (
              <Space size="middle">
               <Button type="primary" onClick={() => handleAssignUsers(data.department_id)}>
                    <UsergroupAddOutlined />
                  </Button>
                {userPermissions.includes('edit') && data.business_id !== null && (
                  <Button type="primary" onClick={() => handleEdit(data)}>
                    <EditOutlined />
                  </Button>
                )}
                {userPermissions.includes('delete') && data.business_id !== null && (
                  <Button type="primary" danger onClick={() => handleDelete(data)}>
                    <DeleteOutlined className={styles.deleteIcon} />
                  </Button>
                )}
                 
              </Space>
            ),
          },
        ]
      : []),
  ];

  const handleEdit = useCallback((data) => {
    setModalData(data);
    setStatus('edit');
    setIsOpen(true);
  }, []);

  const handleDelete = useCallback((data) => {
    setModalData(data);
    setStatus('delete');
    setIsOpen(true);
  }, []);

  const handleCreate = useCallback(() => {
    setStatus('new');
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setModalData({});
    }
  }, [isOpen]);
  const handleAssignUsers = useCallback((department_id) => {
    setDepartmentId(department_id);
    setAssignModalOpen(true);
  }, []);
  return (
    <>
      {/* TODO HEADER WILL BE REMOVED */}
      {/* <Header
        enableCreate={userPermissions.includes('create') ? true : false}
        enableCreateTitle={t('Departments.createDepartment')}
        handleCreate={() => handleCreate()}
      /> */}
      <title>{t('Departments.title')}</title>
      {/* TODO add Card component and wrap all codes below after here, card Title must be has 'extra' props and it will create new departments */}
      <div>
        <DepartmentModal
          open={isOpen}
          setIsOpen={setIsOpen}
          modalData={modalData}
          status={status}
          handleRefreshTable={handleRefreshTable}
        />
        <UserAssignModal
          isOpen={assignModalOpen}
          setIsOpen={setAssignModalOpen}
          id={departmentId}
          handleRefreshTable={handleRefreshTable}
          type="department"
          assignEndpoint="/user/department/assign"
        />
      <Card
          title={t('Departments.title')}
          extra={
              userPermissions.includes('create') && (
                  <Button onClick={() => handleCreate()}>
                      {t('Departments.createDepartment')}
                  </Button>
              )
          }
      >
          <MyTable
            initialColumns={initialColumns}
            url="/global/departments"
            refreshTable={refreshTable}
            pagination={{ position: ['bottomCenter'] }}
            searchEnabled={true}
          />
        </Card>
      </div>
    </>
  );
};

export default Departments;

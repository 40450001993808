import {
  CheckSquareFilled,
  EnvironmentFilled,
  GiftFilled,
  ReloadOutlined,
  SnippetsFilled,
  StarFilled,
} from '@ant-design/icons';
import { Avatar, Card, Col, List, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header';
import WithAgreementCheck from '../../components/WithAgreementCheck';
import { useLazyQuery } from '../../hooks/useAxios';
import './ProfileCard.css';

const { Title, Text } = Typography;

const Dashboard = () => {
  const { userId } = useSelector((state) => state.auth);

  const [anniversary, setAnniversary] = useState([]);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [upcomingBirthdays, setUpcomingBirthdays] = useState([]);
  const [publicHolidays, setPublicHolidays] = useState([]);
  const [users, setUsers] = useState([]);
  const [leaveRequestsApproved, setLeaveRequestsApproved] = useState([]);
  const [leaveRequestsPending, setLeaveRequestsPending] = useState([]);

  const [showMore, setShowMore] = useState({
    anniversaries: false,
    leaveRequests: false,
    upcomingBirthdays: false,
    publicHolidays: false,
  });
  const start_date = moment().format('MM-DD');
  const end_date = '10-09';
  const token = localStorage.getItem('token');
  const { data: tokenData, refetch: checkTokenRefetch } = useLazyQuery('/user/check-token');
  const { data: holidaysData, refetch: fetchHolidays } = useLazyQuery('/user/upcoming/holidays');
  const { data: anniversariesData, refetch: fetchAnniversaries } = useLazyQuery(
    `/user/upcoming/anniversary?start_date=${start_date}&end_date=${end_date}`
  );
  const { data: birthdaysData, refetch: fetchBirthdays } = useLazyQuery(
    `/user/upcoming/birthdays?start_date=${start_date}&end_date=${end_date}`
  );
  const { data: userData, refetch: fetchUser } = useLazyQuery(`/user/${userId}`);

  const { data: leaveRequestsPendingData, refetch: fetchLeaveRequestsPending } = useLazyQuery('/leave/list?status=2');
  const { data: leaveRequestsApprovedData, refetch: fetchLeaveRequestApproved } = useLazyQuery('/leave/list?status=1');

  const navigate = useNavigate();

  const checkToken = async (token) => {
    const response = await checkTokenRefetch({ tokenData: token });
    if (response?.result !== 'success') {
      // TODO: Enable the next line with delete token from DB
      // return navigate('/sign-in');
    }
  };
  const colors = ['#f56a00', '#87d068', '#2db7f5', '#108ee9', '#ffbf00'];
  const getColor = (index) => colors[index % colors.length];
  useEffect(() => {
    if (!tokenData) {
      checkToken(token);
    }
  }, [tokenData]);

  useEffect(() => {
    const today = moment();
    const thirtyDaysLater = today.clone().add(30, 'days');
    fetchHolidays()
      .then((response) => {
        if (response?.result === 'success') {
          const today = moment();
          const ninetyDaysLater = today.clone().add(90, 'days');

          const upcomingHolidays = response.data.filter((holiday) => {
            const holidayDate = moment(holiday.date);
            return holidayDate.isAfter(today) && holidayDate.isBefore(ninetyDaysLater);
          });

          setPublicHolidays(upcomingHolidays);
        }
      })
      .catch((error) => console.error('Error fetching holidays:', error));
    fetchUser()
      .then((response) => {
        console.log('User response:', response);

        if (response?.result === 'success') {
          setUsers(response.data.results[0]);
        }
      })
      .catch((error) => console.error('Error fetching holidays:', error));

    fetchLeaveRequestsPending()
      .then((response) => {
        if (response?.result === 'success') {
          // const upcomingLeaveRequests = response.data.filter((request) => {
          //   const startDate = moment(request.start_date);
          //   return startDate.isBetween(today, thirtyDaysLater, 'day', '[]');
          // });
          setLeaveRequestsPending(response.data);
        //  setLeaveRequestsPending(upcomingLeaveRequests.data);
        }
      })
      .catch((error) => console.error('Error fetching leave requests:', error));

    fetchLeaveRequestApproved()
      .then((response) => {
        if (response?.result === 'success') {
          // const upcomingLeaveRequestsApproved = response.data.filter((request) => {
          //   const startDate = moment(request.start_date);
          //   return startDate.isBetween(today, thirtyDaysLater, 'day', '[]');
          // });
        //  setLeaveRequestsApproved(upcomingLeaveRequestsApproved);
          setLeaveRequestsApproved(response.data);
        }
      })
      .catch((error) => console.error('Error fetching approved leave requests:', error));

    fetchAnniversaries()
      .then((response) => {
        if (response?.result === 'success') {
          setAnniversary(response.data.data);
        }
      })
      .catch((error) => console.error('Error fetching anniversaries:', error));

    fetchBirthdays()
      .then((response) => {
        if (response?.result === 'success') {
          const today = moment();
          const ninetyDaysLater = today.clone().add(90, 'days');

          const upcomingBirthdays = response.data.data.filter((birthday) => {
            const birthdayDate = moment(birthday.birth_date, 'YYYY-MM-DD');
            birthdayDate.year(today.year());

            return birthdayDate.isAfter(today) && birthdayDate.isBefore(ninetyDaysLater);
          });

          setUpcomingBirthdays(upcomingBirthdays);
        }
      })
      .catch((error) => console.error('Error fetching birthdays:', error));
  }, [fetchHolidays, fetchAnniversaries, fetchBirthdays, fetchLeaveRequestsPending]);

  const { t } = useTranslation();

  const calculateDaysLeft = (date) => {
    const today = moment();
    const eventDate = moment(date, 'YYYY-MM-DD');
    eventDate.year(today.year());

    if (eventDate.isBefore(today, 'day')) {
      eventDate.add(1, 'year');
    }

    const daysLeft = eventDate.diff(today, 'days');
    return `${daysLeft + 1}g`;
  };
  const handleShowMore = (key) => {
    setShowMore((prevState) => ({ ...prevState, [key]: !prevState[key] }));
  };

  const cardsData = [
    {
      id: 'leaveRequestsPending',
      title: t('Dashboard.leaveRequests'),
      icon: <CheckSquareFilled />,
      data: leaveRequestsPending,
    },
    {
      id: 'leaveRequestsApproved',
      title: t('Dashboard.leaveRequestsApproved'),
      icon: <SnippetsFilled />,
      data: leaveRequestsApproved,
    },
    { id: 'anniversaries', title: t('Dashboard.eventCalendar'), icon: <StarFilled />, data: anniversary },
    { id: 'publicHolidays', title: t('Dashboard.publicHolidays'), data: publicHolidays, icon: <EnvironmentFilled /> },
    {
      id: 'upcomingBirthdays',
      title: t('Dashboard.upcomingBirthdays'),
      icon: <GiftFilled />,
      data: upcomingBirthdays,
    },
  ];
  const getInitials = (firstname = '', lastname = '') => {
    const firstInitial = firstname ? firstname.charAt(0).toUpperCase() : '';
    const lastInitial = lastname ? lastname.charAt(0).toUpperCase() : '';
    return `${firstInitial}${lastInitial}`;
  };

  const initials = users ? getInitials(users.firstname, users.lastname) : '';
  const refreshCardData = async (cardId) => {
    switch (cardId) {
      case 'leaveRequestsPending':
        await fetchLeaveRequestsPending();
        break;
      case 'leaveRequestsApproved':
        await fetchLeaveRequestApproved();
        break;
      case 'anniversaries':
        await fetchAnniversaries();
        break;
      case 'publicHolidays':
        await fetchHolidays();
        break;
      case 'upcomingBirthdays':
        await fetchBirthdays();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Header />
      <title>{t('Dashboard.title')}</title>
      <h1>{t('Dashboard.welcome')}</h1>

      <Row gutter={[8, 8]} wrap={true}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <Card hoverable bordered={false}>
            <div style={{ display: 'flex' }}>
              <p style={{ width: '100px' }}>Deneme Sürümündesiniz</p>
              <a style={{ position: 'absolute', right: '35px', fontSize: '18px' }} href="#">
                Abone Ol
              </a>
            </div>
            <div>
              <p style={{ fontSize: '28px', marginBottom: '8px', lineHeight: '36px', color: '#f9a623' }}>
                13 Gün Kaldı
              </p>
            </div>
          </Card>

          <Card style={{ marginTop: '10px' }} bordered={false} className="profile-card">
            <div className="profile-card-header">
              <Title level={4}>{users.firstname + ' ' + users.lastname}</Title>
              <Avatar size={64} style={{ backgroundColor: '#bae7ff', color: '#000' }}>
                {initials}
              </Avatar>
            </div>
            <Text>{users.title_tr_name}</Text>
            <br />
            <Text type="secondary">{users.department_tr_name}</Text>
            <hr className="profile-card-divider" />
            <Link to="/profile" className="profile-link">
              Hesabım
            </Link>
          </Card>
        </Col>

        {cardsData.map((card) => (
          <Col xs={24} sm={24} md={12} lg={8} key={card.id}>
            <Card
              hoverable
              title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <span>{card.icon}</span>
                    <span style={{ fontSize: '15px', fontWeight: 'inherit', paddingLeft: '10px' }}>{card.title}</span>
                  </div>
                  <ReloadOutlined onClick={() => refreshCardData(card.id)} />
                </div>
              }
              bordered={false}
            >
              <List
                itemLayout="horizontal"
                dataSource={showMore[card.id] ? card.data : card.data.slice(0, 5)}
                renderItem={(item, index) => (
                  // <HomeCards date={item.entry_date} />
                  <List.Item
                    actions={
                      item.birth_date
                        ? [
                            <Text type="secondary" style={{ color: getColor(index) }}>
                              {calculateDaysLeft(item.birth_date)}
                            </Text>,
                          ]
                        : item.entry_date
                          ? [
                              <Text type="secondary" style={{ color: getColor(index) }}>
                                {calculateDaysLeft(item.entry_date)}
                              </Text>,
                            ]
                          : item.date
                            ? [
                                <Text type="secondary" style={{ color: getColor(index) }}>
                                  {calculateDaysLeft(item.date)}
                                </Text>,
                              ]
                            : item.request_date
                              ? [
                                  <Text type="secondary" style={{ color: getColor(index) }}>
                                    {calculateDaysLeft(item.start_date)} {moment(item.start_date).format('D MMM')}
                                  </Text>,
                                ]
                              : []
                    }
                  >
                    <List.Item.Meta
                      avatar={
                        card.id === 'publicHolidays' ? null : (
                          <Avatar style={{ backgroundColor: getColor(index), color: '#fff' }}>
                            {getInitials(item.firstname, item.lastname)}
                          </Avatar>
                        )
                      }
                      title={
                        <Link to={item.firstname ? `/worker/${item.user_id}` : ''}>
                          <Text style={{ fontSize: '15px', fontWeight: 'bold' }}>
                            {item.firstname ? item.firstname + ' ' + item.lastname : item.title}
                          </Text>
                        </Link>
                      }
                    />
                  </List.Item>
                )}
              />
              {card.data.length > 5 && (
                <Link
                  to="#"
                  style={{ display: 'block', textAlign: 'center', marginTop: '10px' }}
                  onClick={() => handleShowMore(card.id)}
                >
                  {showMore[card.id] ? 'Daha Az Göster' : 'Hepsini Gör'}
                </Link>
              )}
            </Card>
          </Col>
        ))}
        {/* <Col xs={24} sm={24} md={12} lg={24}>
          <EmployeeDistribution />
        </Col> */}
      </Row>
    </>
  );
};

export default WithAgreementCheck(Dashboard);

import { Checkbox, ConfigProvider, DatePicker, Form, Input, InputNumber, Switch, TimePicker } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import trTR from 'antd/es/locale/tr_TR';
import dayjs from 'dayjs';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const MyInput = ({ name, rules, label, type = 'text', onChange, ...rest }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const formatCardDate = (value) => {
    const cleanValue = value.replace(/\D/g, '');
    const month = cleanValue.slice(0, 2);
    const year = cleanValue.slice(2, 4);

    return `${month}${year ? `/${year}` : ''}`;
  };
  const customLocale = {
    ...trTR,
    weekStart: 1, // 0 means Sunday
  };
  return (
    <Form.Item
      label={label}
      validateStatus={errors[name] && 'error'}
      help={errors[name]?.message}
      name={name}
      labelCol={{
        span: type === 'switch' ? 0 : 24,
        style: { paddingBottom: 0, display: 'grid' },
      }}
      wrapperCol={{ span: 24 }}
      style={{ marginBottom: 0 }}
    >
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          if (type === 'number') {
            return <InputNumber style={{ width: '100%' }} {...field} id={name} {...rest} />;
          } else if (type === 'dateMonth') {
            return (
              <Input
                style={{ width: '100%', }}
                {...field}
                id={name}
                {...rest}
                placeholder="MM/YY"
                onChange={(e) => {
                  const value = e.target.value;
                  const formattedDate = formatCardDate(value);
                  field.onChange(formattedDate);
                  onChange && onChange(formattedDate);
                }}
                value={field.value || ''}
              />
            );
          } else if (type === 'date') {
            return (
          <ConfigProvider locale={customLocale}>
              <DatePicker
                tim
                style={{ width: '100%' }}
                {...field}
                id={name}
                {...rest}
                onChange={(date, dateString) => {
                  const newDate = dayjs(date).add(3, 'hour');
                  field.onChange(newDate);
                  onChange && onChange(newDate);
                }}
              />
                  </ConfigProvider>

            );
          } else if (type === 'time') {
            return <TimePicker style={{ width: '100%' }} {...field} id={name} {...rest} />;
          } else if (type === 'textarea') {
            return <TextArea style={{ width: '100%' }} {...field} id={name} {...rest} />;
          } else if (type === 'password') {
            return (
              <Input.Password
                style={{ width: '100%' }}
                {...field}
                id={name}
                {...rest}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  onChange && onChange(e.target.value);
                }}
              />
            );
          } else if (type === 'switch') {
            return (
              <Switch
                {...field}
                id={name}
                {...rest}
                onChange={(e) => {
                  field.onChange(!field.value);
                  onChange && onChange(!field.value);
                }}
              />
            );
          } else if (type === 'checkbox') {
            return (
              <Checkbox
                {...field}
                id={name}
                {...rest}
                onChange={(e) => {
                  field.onChange(!field.value);
                  onChange && onChange(!field.value);
                }}
              />
            );
          } else {
            return (
              <Input
                style={{ width: '100%', }}
                {...field}
                id={name}
                {...rest}
                onChange={(e) => {
                  field.onChange(e.target.value || '');
                  onChange && onChange(e.target.value || '');
                }}
              />
            );
          }
        }}
      />
    </Form.Item>
  );
};

export default MyInput;

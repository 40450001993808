import { Flex, Tooltip, Typography } from 'antd'
import Paragraph from 'antd/es/skeleton/Paragraph'
import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

const OptionRender = (option) => {
    const { t } = useTranslation()
    return (
        <Flex justify="space-between">
            <Text style={{ marginBottom: 0 }}>
                {option.label}
            </Text>
            <Flex>
                {option?.data?.remaining_limit ? (
                    <Text style={{
                        marginRight: 8
                    }} type="secondary">
                        {t('LeaveModal.remaining_day', { day: option.data.remaining_limit })}
                    </Text>
                ) : null}
                {option?.data.desc && <Tooltip
                    title={
                        <>
                            <Text type="warning">
                                {option.data.type}
                            </Text>
                            <Text style={{ color: 'white' }}>
                                {option.data.remaining_limit}
                            </Text>
                            <br />
                            <Text type="success">
                                {option.data.desc}{' '}
                            </Text>
                        </>
                    }
                >
                    <InfoCircleOutlined />
                </Tooltip>}
            </Flex>
        </Flex>
    )
}

export default OptionRender
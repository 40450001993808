import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AlignCenterOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Modal, Row } from 'antd';
import MyModal from '../MyModal';
import Typography from 'antd/es/typography/Typography';

const { Text, Title } = Typography;

const pages = [
    {
        page: 'Dashboard',
        path: '/',
        icon: <AlignCenterOutlined />,
        description: 'Burada işlerinizi yönetebilirsiniz.',
        title: 'Dashboard'
    },

    {
        page: 'Workers',
        path: '/workers',
        icon: <AlignCenterOutlined />,
        description: 'Çalışanlarınızı buradan görebilirsiniz.',
        title: 'Workers'
    },
    {
        page: 'Leaves',
        path: '/leaves',
        icon: <AlignCenterOutlined />,
        description: 'İzinlerinizi buradan yönetebilirsiniz.',
        title: 'Leaves'
    },
    {
        page: 'Expenses',
        path: '/expenses',
        icon: <AlignCenterOutlined />,
        description: 'Giderlerinizi buradan yönetebilirsiniz.',
        title: 'Expenses'
    }
];

const TutorialHelper = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const pathname = location.pathname;
        const pageIndex = pages.findIndex((page) => page.path === pathname);
        setCurrentPage(pageIndex);
    }, [location]);

    const handlePrevious = () => {
        if (currentPage > 0) {
            navigate(pages[currentPage - 1].path);
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < pages.length - 1) {
            navigate(pages[currentPage + 1].path);
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <MyModal
            centered
            open={true}
            title={pages[currentPage].page}
            onCancel={handlePrevious}
            onOk={handleNext}
        >

            {pages[currentPage].icon}
            <Row>
                <Col span={24}>
                    <Title level={3}>{pages[currentPage].title}</Title>
                </Col>
                <Col span={24}>
                    <Text>{pages[currentPage].description}</Text>
                </Col>
            </Row>

        </MyModal>
    );
};

export default TutorialHelper;

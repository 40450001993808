import { Col } from 'antd';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek'; // Import the isoWeek plugin
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '../../hooks/useAxios';
import MyInput from '../MyInput';
import MyModal from '../MyModal/MyModal';
import MySelect from '../MySelect';
import OptionRender from './OptionRender';
import useLeaveModal from './useLeaveModal';

dayjs.extend(isoWeek);

const LeaveModal = ({
    initialValues = {
        reason_id: 4,
        startDate: dayjs(),
        endDate: dayjs(),
    },
    userId,
    setOpen,
    open,
    id,
    leaves,
    ...props
}) => {
    const { t } = useTranslation();
    const [selectedStartDate, setSelectedStartDate] = useState(initialValues.startDate);
    const [selectedEndDate, setSelectedEndDate] = useState(initialValues.endDate);
    const [totalDays, setTotalDays] = useState(0);
    const [disabledDates, setDisabledDates] = useState([]);
    const [holidays, setHolidays] = useState([]);
    const [selectedUser,setSelectedUser] = useState(null);

    const {
        form,
        handleSubmit,
        handleDateChange,
        options,
        selectProps,
        numberInputProps,
        datePickerProps,
        createLeaveLoading,
        checkLoading,
        onSubmit,
    } = useLeaveModal({ initialValues, userId, setOpen ,id,selectedUser});

    const calculateTotalDays = (startDate, endDate) => {
        if (startDate && endDate) {
            const days = [];
            let current = dayjs(startDate);
            while (current <= dayjs(endDate)) {
                if (
                    current.day() !== 5 && //  Sunday
                    current.day() !== 6 && //  Saturday
                    !holidays.some(holiday => dayjs(holiday.date).isSame(current, 'day'))
                ) {
                    days.push(current);
                }
                current = current.add(1, 'day');
            }
            setTotalDays(days.length);
        } else {
            setTotalDays(0);
        }
    };
    const { data: publicHolidays, error: holidaysError, refetch: fetchPublicHolidays } = useLazyQuery('global/public-holiday', {
        method: 'get',
        params: {
            year: dayjs().year(), 
        },
    });

    const {data:getUsers,refetch:fetchGetusers} = useLazyQuery('/user/list',
        {method:'GET'}
    );
console.log(publicHolidays,"publicHolidays")
const fetchHolidays = async () => {
    try {
        const currentYearHolidaysResponse = await fetchPublicHolidays({ year: dayjs().year() });
        const nextYearHolidaysResponse = await fetchPublicHolidays({ year: dayjs().year() + 1 });

        const currentYearHolidays = currentYearHolidaysResponse?.data || [];
        const nextYearHolidays = nextYearHolidaysResponse?.data || [];

        const allHolidays = [...currentYearHolidays, ...nextYearHolidays];
        setHolidays(allHolidays);
        setDisabledDates(allHolidays.map(holiday => dayjs(holiday.date).startOf('day').toDate()));
    } catch (error) {
        console.error('Error fetching holidays:', error);
    }
}
console.log(getUsers,"getUsers")
    useEffect(() => {
        fetchHolidays();
        fetchGetusers();
    }, []);
    useEffect(() => {
        if (open) {
            setSelectedStartDate(initialValues.startDate);
            setSelectedEndDate(initialValues.endDate);
            setSelectedUser(null);
            setTotalDays(1);
        }
    }, [open, initialValues.startDate, initialValues.endDate]);

    useEffect(() => {
        calculateTotalDays(selectedStartDate, selectedEndDate);
    }, [selectedStartDate, selectedEndDate]);

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
        handleDateChange(date);
    };

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
        handleDateChange(date);
    };
    const isDisabledDate = (current) => {
        return (
            current &&
            (current < dayjs().startOf('day') ||
            disabledDates.some(date => dayjs(date).isSame(current, 'day')))
        );
    };
    const handleUserSelect = (value) => {
        const selected = getUsers?.data?.results.find(user => user.user_id === value);
        setSelectedUser(selected);
    };

    return (
        <MyModal
            isOpen={open}
            setIsOpen={setOpen}
            title={t('LeaveModal.title')}
            handleSubmit={handleSubmit(onSubmit)}
            confirmLoading={createLeaveLoading || checkLoading}
            formMethods={form}
        > 
             {leaves && (
                <Col span={24}>
                    <MySelect
                        showSearch
                        name="user_id"
                        label={t('LeaveModal.user')}
                        options={getUsers?.data?.results?.map(user => ({
                            value: user.user_id,
                            label: `${user.firstname} ${user.lastname}`,
                            ...user
                        }))}
                        placeholder="Select User"
                        onChange={handleUserSelect}
                        value={selectedUser?.user_id}
                    />
                </Col>
                )}
            <Col span={16}>
                <MySelect
                    showSearch
                    name="reason_id"
                    label={t('LeaveModal.leaveType')}
                    options={options}
                    placeholder="Select Leave Type"
                    optionRender={(option) => <OptionRender {...option} />}
                    onChange={handleDateChange}
                    {...selectProps}
                />
            </Col>
            <Col span={8}>
            <MyInput
                    name="total"
                    label={t('LeaveModal.total')}
                    type="number"
                    value={totalDays}
                    disabled
                />            </Col>

            <Col span={12}>
                <MyInput
                    type="date"
                    name="startDate"
                    label={t('LeaveModal.startDate')}
                   onChange={handleStartDateChange}
                    {...datePickerProps}
                    disabledDate={isDisabledDate}
                    />
            </Col>

            <Col span={12}>
                <MyInput
                    type="date"
                    name="endDate"
                    label={t('LeaveModal.endDate')}
                    onChange={handleEndDateChange}
                    {...datePickerProps}
                    disabled={!selectedStartDate}
                    disabledDate={(current) =>
                        current && (current < selectedStartDate?.startOf('day') || 
                        disabledDates.some(date => dayjs(date).isSame(current, 'day'))
                        )
                    }                />
            </Col>

            <Col span={24}>
                <MyInput
                    name="description"
                    label={t('LeaveModal.description')}
                    // type="textarea"
                    rows={4}
                />
            </Col>
        </MyModal>
    );
};

LeaveModal.defaultProps = {
    initialValues: {
        reason_id: null,
        startDate: dayjs(),
        endDate: dayjs(),
        description: '',
        replacement: '',
    },
};

export default LeaveModal;

import React from 'react';
import { Col, Typography } from 'antd';
import FamilyModal from './FamilyModal';
import DataCard from '../DataCard/DataCard';

const { Text } = Typography;

const Family = ({ data }) => {
    return (
        <DataCard title="Aile" Extra={true} data={data} Modal={FamilyModal}>
            <Col span={12}>
                <Text strong>Medeni Durumu:</Text> {data?.marital_status || '—'}
            </Col>
            <Col span={12}>
                <Text strong>Çocuk Sayısı:</Text>{' '}
                {data?.number_of_children || '—'}
            </Col>
        </DataCard>
    );
};

export default Family;

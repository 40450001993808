import { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '../../hooks/useAxios';
import { useTree } from 'react-drag-hierarchy-tree';
import { on } from 'events';

const types = new Map([
    [0, 'business'],
    [1, 'department'],
    [2, 'manager'],
    [3, 'users'],
]);

const getId = (type, id) => {
    return id ? Number(id.replace(types.get(type), '')) : null;
};

const draggableType = ['users'];

const convertToInitialData = (data, depth = 0) => {
    const initialData = {
        id: types.get(data.type) + data.id,
        label: data.label,
        type: data.type,
        disabled: !draggableType.includes(types.get(data.type)),
        depth: depth,
        department_id: data?.department_id,
        department_name: data?.department_name,
        children: [],
    };

    if (data.manager) {
        initialData.children.push({
            id: types.get(data.manager.type) + data.manager.id,
            label: data.manager.label,
            type: data.manager.type,
            disabled: !draggableType.includes(data.manager.type),
            depth: depth + 1,
            department_id: data?.manager?.department_id,
            department_name: data?.manager?.department_name,
            children: [
                ...(data?.children?.find((e) => e.id === data.manager.id)?.children?.map((e) => convertToInitialData(e, depth + 2)) || []),
                ...(data?.children?.filter((e) => e.id !== data.manager.id).map((e) => convertToInitialData(e, depth + 2)) || []),
            ],
        });
    } else if (data.children) {
        data.children.forEach((e) => {
            initialData.children.push(convertToInitialData(e, depth + 1));
        });
    }

    return initialData;
};

const useHierarchy = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { treeRef } = useTree();
    const [modalText, setModalText] = useState('');
    const onClick = () => {
        treeRef.current?.onExpandNodes();
    };
    const [draggedItem, setDraggedItem] = useState({});
    const [holderItem, setHolderItem] = useState({});

    const [gData, setGData] = useState({});
    console.log(gData, 'gData')
    const { data, loading, refetch } = useQuery('/business/hierarchy', {
        onCompleted: (data) => {
            setGData(convertToInitialData(data, 0));
        },
    });

    const {
        data: hierarchyCreateData,
        loading: hierarchyCreateLoading,
        refetch: refetchHierarchyCreate,
    } = useLazyQuery('/user/hierarchy/create', {
        method: 'POST',
        onCompleted: () => {
            setGData({});
            refetch();
        },
    });

    const {
        data: hierarchyUpdateData,
        loading: hierarchyUpdateLoading,
        refetch: refetchHierarchyUpdate,
    } = useLazyQuery('/user/hierarchy/update/with-child', {
        method: 'POST',
        onCompleted: () => {
            setGData({});
            refetch();
        },
    });

    const {
        data: departmantAssignData,
        loading: departmantAssignLoading,
        refetch: refetchDepartmantAssign
    } = useLazyQuery('/user/department/assign', {
        method: 'PUT',

    });

    const {
        data: checkData,
        loading: checkLoading,
        refetch: refetchCheck
    } = useLazyQuery('/user/hierarchy/check/' + getId(draggedItem.type, draggedItem.id)
        , {
            method: 'GET',
        });



    const onDrop = (draggedItem, holderItem) => {
        const holderType = types.get(holderItem.type);
        if (draggedItem?.children?.length > 0) {
            setDraggedItem(draggedItem);
            setHolderItem(holderItem);
            setModalText(
                `${draggedItem.label} isimli kişi ${holderItem.label} isimli ${holderType === 'manager' ? 'kişinin' : holderType === 'department' ? 'departmanın' : 'şirketin'
                } altına taşınacak. Altındaki kişilerle birlikte taşımak ister misiniz?`
            );
            setIsOpen(true);
        } else {
            handleMove(draggedItem, holderItem);
        }
    };

    const handleMove = async (draggedItem, holderItem, isWithChild) => {
        const draggedItemType = types.get(draggedItem.type);
        const holderItemType = types.get(holderItem.type);
        const manager_id = getId(holderItem.type, holderItem.id);
        const employee_id = getId(draggedItem.type, draggedItem.id);

        const userToUser = draggedItemType === 'users' && (holderItemType === 'users' || holderItemType === 'manager');
        if (userToUser) {
            // #todo burada bi de departman değiştiriyor mu diye bak sonra ona göre işlem yap
            if (holderItem.department_id === draggedItem.department_id) {
                // aynı departman içinde taşıma işlemi yapılacak
                if (isWithChild) {
                    // move user to user with children
                    refetchHierarchyUpdate({
                        data: {
                            manager_id,
                            employee_id,
                        },
                    });
                } else {
                    // move user to user without children
                    refetchHierarchyCreate({
                        data: {
                            manager_id: getId(holderItem.type, holderItem.id),
                            employee_id: getId(draggedItem.type, draggedItem.id),
                        },
                    });
                }
            } else {
                // farklı departmanın içindeki bir kullanıcıya taşıma işlemi yapılacak
                if (isWithChild) {
                    // move user to user with children
                    // önce check sonra departman assign ardından devam
                    const data = await refetchCheck();
                    if (data) {
                        await refetchDepartmantAssign({
                            data: {
                                department_id: holderItem.department_id,
                                users: data?.users
                            },
                        });
                        await refetchHierarchyUpdate({
                            data: {
                                manager_id,
                                employee_id,
                            },
                        });

                    }
                } else {
                    // move user to user without children
                    // önce departman assign ardından devam
                    await refetchDepartmantAssign({
                        data: {
                            department_id: holderItem.department_id,
                            users: [getId(draggedItem.type, draggedItem.id)]
                        },
                    });
                    refetchHierarchyCreate({
                        data: {
                            manager_id: getId(holderItem.type, holderItem.id),
                            employee_id: getId(draggedItem.type, draggedItem.id),
                        },
                    });
                }
            }
            // move user to user
        } else if (draggedItemType === 'users' && holderItemType === 'department') {
            // move user to department or business
            if (isWithChild) {
                // move user to user with children
                // önce check sonra departman assign ardından devam
                const data = await refetchCheck();
                if (data) {
                    await refetchDepartmantAssign({
                        data: {
                            department_id: getId(holderItem.type, holderItem.id),
                            users: data?.users
                        },
                    });
                    refetch()

                }
            } else {
                // move user to user without children
                // önce departman assign ardından devam
                await refetchDepartmantAssign({
                    data: {
                        department_id: getId(holderItem.type, holderItem.id),
                        users: [getId(draggedItem.type, draggedItem.id)]
                    },
                });
                refetch()
            }
        } else {
            console.log('else durumu')
        }
    };

    const onCancel = () => {
        handleMove(draggedItem, holderItem, false);
        setIsOpen(false);
    };

    const onOk = () => {
        handleMove(draggedItem, holderItem, true);
        setIsOpen(false);
    };

    return {
        gData,
        loading: loading || hierarchyCreateLoading,
        onClick,
        onDrop,
        treeRef,
        isOpen,
        setIsOpen,
        modalText,
        onCancel,
        onOk,
    };
};

export default useHierarchy;

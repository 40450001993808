import React, { useEffect } from 'react';
import { Col, notification } from 'antd';
import { useForm } from 'react-hook-form';
import {  useTranslation } from 'react-i18next';
import MyModal from '../MyModal';
import MyInput from '../MyInput';
import { useLazyQuery } from '../../hooks/useAxios';
import DeleteConfirm from '../DeleteConfirm';

export default function BranchModal({
    open,
    setIsOpen,
    modalData,
    handleRefreshTable,
    status,
}) {
    const { t } = useTranslation();
    const form = useForm({
        defaultValues: {
            name: modalData?.name || '',
            address: modalData?.address || '',
            phone_number: modalData?.phone_number || '',
            email: modalData?.email || ''
        }
    });

    const {
        handleSubmit,
        reset,
       
    } = form;
    console.log("branch",modalData)

    const apiConfig = {
        url:
            status === 'edit'
                ? `/global/branches/edit/${modalData.branch_id}`
                : status === 'delete'
                  ? `/global/branches/delete/${modalData.branch_id}`
                  : `/global/branches/create`,
        method:
            status === 'edit' ? 'put' : status === 'delete' ? 'delete' : 'post'
    };

    const {  loading, refetch } = useLazyQuery(apiConfig.url, {
        method: apiConfig.method
    });

    useEffect(() => {
        if (open) {
            reset({
                name: modalData?.name || '',
                address: modalData?.address || '',
                phone_number: modalData?.phone_number || '',
                email: modalData?.email || ''
            });
        }
    }, [open, modalData, reset]);
    const onSubmit = async (data) => {
        try {
            const dataToSend =
                status === 'new' ? { branches: [data] } : data;
            const response = await refetch({ data: dataToSend });

            if (response?.result === 'success') {
                let successMessage;
                if (apiConfig.method === 'put') {
                    successMessage = t('Branches.branch_edit_success');
                } else if (apiConfig.method === 'delete') {
                    successMessage = t('Branches.branch_delete_success');
                } else {
                    successMessage = t('Branches.branch_create_success');
                }

                notification.success({
                    message: successMessage
                });
                handleRefreshTable();
                setIsOpen(false);
            } else {
                notification.error({
                    message: response?.result?.message || t('An error occurred!')
                });
            }
        } catch (error) {
            notification.error({
                message: error.message || t('An error occurred!')
            });
        }
    };

   

    return (
        <MyModal
            title={status === 'delete' ? t('Deletion Branches') : t('Branch Modal')}
            isOpen={open}
            setIsOpen={setIsOpen}
            handleSubmit={handleSubmit(onSubmit)}
            confirmLoading={loading}
            rowGap={16}
            formMethods={form}
        >
            {status !== 'delete' ? (
                <>
                    <Col span={24}>
                        <MyInput name="name" label={t('Branches.name')} />
                    </Col>
                    <Col span={24}>
                        <MyInput name="address" label={t('Branches.address')} />
                    </Col>
                    <Col span={24}>
                        <MyInput name="phone_number" label={t('Branches.phone_number')} />
                    </Col>
                    <Col span={24}>
                        <MyInput name="email" label={t('Branches.email')} />
                    </Col>
                </>
            ) : (
                <p>{modalData.name} şubeyi silmek istediğinize emin misiniz ?</p>
            )}
        </MyModal>
    );
}

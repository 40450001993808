import React, { useEffect, useState } from 'react';
import { Col, notification, Select, Row } from 'antd';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import MyModal from '../MyModal';
import MyInput from '../MyInput';
import { useLazyQuery } from '../../hooks/useAxios';
import DeleteConfirm from '../DeleteConfirm';

import styles from './RoleModal.module.css';

const { Option } = Select;

const PERMISSION_MAP = {
  1: 'create',
  2: 'edit',
  3: 'delete',
  4: 'view',
};

export default function RoleModal({ isOpen, setIsOpen, modalData, handleRefreshTable, status }) {
  const { t } = useTranslation();

  const [screens, setScreens] = useState([]);
  const [loadingScreens, setLoadingScreens] = useState(false);
  const [editTr, setEditTr] = useState('');
  const [editEn, setEditEn] = useState('');
  const [editScreens, setEditScreens] = useState([]);

  const { refetch: fetchScreens, loading: screensLoading } = useLazyQuery('/global/screens', { method: 'GET' });

  const { data: getRoleData, refetch: getRole } = useLazyQuery(`/role/${modalData.role_id}`);

  const form = useForm({
    defaultValues: {
      role_tr_name: '',
      role_en_name: '',
      business_id: '',
      role_id: '',
      access_screen: {},
    },
  });

  const { handleSubmit, reset, setValue, watch } = form;

  const getData = async () => {
    const test = await getRole();
    if (test?.result === 'success') {
      const { role_tr_name, role_en_name, access_screen } = test.data;
      setEditTr(role_tr_name);
      setEditEn(role_en_name);
      console.log(access_screen,"accessss");
      setEditScreens(access_screen);
      resetForm(role_tr_name, role_en_name, editScreens);
    }
  };

  const resetForm = (roleTr, roleEn, editScreens = {}) => {
    const accessScreens = Object.keys(editScreens).reduce((acc, screen) => {
      acc[screen] = editScreens[screen].map((permission) => {
        console.log(permission, 'permission');
        return String(permission);
      });
      return acc;
    }, {});

    reset({
      role_tr_name: roleTr || '',
      role_en_name: roleEn || '',
      business_id: modalData?.business_id || '',
      role_id: modalData?.role_id || '',
      access_screen: accessScreens,
    });
  };

  const fetchScreenData = async () => {
    setLoadingScreens(true);
    try {
      const response = await fetchScreens();
      if (response?.result === 'success') {
        console.log(response, 'response123');
        setScreens(response.data);
        const initialAccessScreen = response.data.reduce((acc, screen) => {
          console.log(editScreens, 'editts');
          acc[screen.screen_name] = editScreens?.[screen.screen_name] || [];
          return acc;
        }, {});
        resetForm(editTr, editEn, initialAccessScreen);
      }
    } catch (error) {
      console.error('Error fetching screens:', error);
    } finally {
      setLoadingScreens(false);
    }
  };

  useEffect(() => {
    if (modalData.role_id && status === 'edit') {
      getData();
    } else {
      setEditEn('');
      setEditTr('');
      setEditScreens([]);
    }
  }, [modalData.role_id, status]);

  useEffect(() => {
    if (isOpen) {
      fetchScreenData();
    }
  }, [isOpen, modalData, reset, fetchScreens, editTr, editEn, editScreens]);

  const apiConfig = {
    url: status === 'edit' ? `/role/edit` : status === 'delete' ? `/role/delete/${modalData.role_id}` : `/role/create`,
    method: status === 'edit' ? 'PUT' : status === 'delete' ? 'DELETE' : 'POST',
  };

  const { loading, refetch } = useLazyQuery(apiConfig.url, {
    method: apiConfig.method,
  });

  const onSubmit = async (data) => {
    console.log(data, 'data12345');
    // return null;
    try {
      const roleData = {
        role_tr_name: data.role_tr_name,
        role_en_name: data.role_en_name,
        business_id: modalData.business_id,
        role_id: modalData.role_id,
        access_screen: Object.keys(data.access_screen).map((screen) => ({
          [screen]: data.access_screen[screen].map(Number),
        })),
      };
      const response = await refetch({ data: roleData });
      if (response.result === 'success') {
        let successMessage;
        if (apiConfig.method === 'PUT') {
          successMessage = <Trans i18nKey="Roles.role_edit_success" />;
        } else if (apiConfig.method === 'DELETE') {
          successMessage = <Trans i18nKey="Roles.role_delete_success" />;
        } else {
          successMessage = <Trans i18nKey="Roles.role_create_success" />;
        }
        notification.success({ message: successMessage });
        handleRefreshTable();
        setIsOpen(false);
      } else {
        notification.error({ message: response.data.message || 'An error occurred!' });
      }
    } catch (error) {
      console.error('Error:', error);
      notification.error({ message: 'An error occurred!' });
    }
  };

  const handleSelectChange = (value, screenType) => {
    setValue(`access_screen.${screenType}`, value);
    const updatedAccessScreen = { ...form.getValues('access_screen'), [screenType]: value };
    console.log(updatedAccessScreen, 'updatedAccessScreen');
    // setValue('access_screen', updatedAccessScreen);
  };

  return (
    <MyModal
      title={status === 'delete' ? t('Roles.role_delete_title') : t('Roles.role_modal_title')}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleSubmit={handleSubmit(onSubmit)}
      confirmLoading={loading || screensLoading}
      rowGap={16}
      formMethods={form}
    >
      {status !== 'delete' ? (
        <>
          <Row gutter={[16, 16]} className={styles.modalContainer}>
            <Col span={24}>
              <MyInput name="role_tr_name" label={t('Roles.role_tr_name')} />
            </Col>
            <Col span={24}>
              <MyInput name="role_en_name" label={t('Roles.role_en_name')} />
            </Col>
            {screens.map((screen) => {
              const selectedPermissions = watch(`access_screen.${screen.screen_name}`) || [];
              const filteredOptions = Object.keys(PERMISSION_MAP).filter(
                (permission) => !selectedPermissions.includes(PERMISSION_MAP[permission])
              );

              return (
                <Col span={24} key={screen.screen_name}>
                  <label>{t(`Roles.${screen.screen_name}`)}</label>
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={t(`Roles.select_${screen.screen_name}`)}
                    value={selectedPermissions}
                    onChange={(value) => handleSelectChange(value, screen.screen_name)}
                  >
                    {Object.keys(PERMISSION_MAP).map((permission) => (
                      <Option key={permission} value={permission}>
                        {t(`Roles.permission_${PERMISSION_MAP[permission]}`)}
                      </Option>
                    ))}
                  </Select>
                </Col>
              );
            })}
          </Row>
        </>
      ) : (
        <p>
          <DeleteConfirm itemName={modalData?.role_tr_name} itemType="role" />
        </p>
      )}
    </MyModal>
  );
}

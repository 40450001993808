import React from 'react';
import { useForm } from 'react-hook-form';
import MyInput from '../MyInput';
import MyModal from '../MyModal';

const WorkersModal = ({ open }) => {
    const form = useForm();
    return (
        <MyModal
            title="Add Employee"
            isOpen={open}
            setIsOpen={() => { }}
            onSubmit={() => { }}
            handleSubmit={() => { }}
            formMethods={form}
        >
            <MyInput name="firstName" label="First Name" />
            <MyInput name="lastName" label="Last Name" />
            <MyInput name="maidenName" label="Maiden Name" />
        </MyModal>
    );
};

export default WorkersModal;

import { Button, Result } from 'antd';
import { Link, createBrowserRouter, Navigate } from 'react-router-dom';
import MainLayout from '../components/MainLayout';

import PrivateRoute from './privateRoute';
import store from '../store/store';
import GeneralRoute from './generalRoute';
import routes from './routess';

const { screen_permissions } = store.getState().auth;
// get token at redux store
// const canView = (page) => token.page.find(p => p.name === page && p.access.includes('view'))
const canView = (route_name) => {
    // const token = store.getState().auth.token;
    // if (!token) {
    //     return false;
    // }
    // const decoded = jwtDecode(token);
    // const screen_permissions = decoded?.access_screen;
    // if (screen_permissions?.find(e => e.screen_name === route_name + '_screen' && e.permissions.includes('preview'))) {
    //     return true;
    // } else {
    //     return false;
    // }
    return true
}
const router = createBrowserRouter(
    routes.map((route) => {
        let element;
        if (route?.auth !== false) {
            if (canView(route.name)) {
                element = (
                    <MainLayout>
                        <PrivateRoute>{route.element}</PrivateRoute>
                    </MainLayout>
                );
            } else {
                element = (
                    <Result
                        status="403"
                        title="403"
                        subTitle="Sorry, you are not authorized to access this page."
                        extra={
                            <Link to="/">
                                <Button component type="primary">
                                    Back Home
                                </Button>
                            </Link>
                        }
                    />
                );
            }
        } else {
            element = <GeneralRoute>{route.element}</GeneralRoute>;
        }
        return {
            ...route,
            element
        };
    })
)

export default router;

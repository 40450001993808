import React, { useContext, useEffect } from 'react';
import MyModal from '../../../../components/MyModal';
import { App } from 'antd';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import WorkerContext from '../../WorkerContext';

const ChangeModal = ({
    isOpen,
    setIsOpen,
    data,
    schema,
    success,
    error,
    title,
    children
}) => {
    const { notification } = App.useApp();
    const { editQuery, editLoading } = useContext(WorkerContext);
    const form = useForm({
        resolver: zodResolver(schema),
        defaultValues: data
    });
    const { handleSubmit } = form;

    useEffect(() => {
        form.reset(data);
    }, [data])

    const onSubmit = async (data) => {
        try {
            const result = await editQuery({ data: data });
            if (result?.result === 'success') {
                notification.success({
                    message: 'Başarılı',
                    description: success
                });
                setIsOpen(false);
            } else {
                notification.error({
                    message: 'Hata',
                    description: error
                });
            }
        } catch (err) {
            console.error('Form submission error:', err);
            notification.error({
                message: 'Hata',
                description: error
            });
        }
    };

    return (
        <MyModal
            isOpen={isOpen}
            handleSubmit={handleSubmit(onSubmit)}
            formMethods={form}
            setIsOpen={setIsOpen}
            title={title}
            confirmLoading={editLoading}
        >
            {children}
        </MyModal>
    );
};

export default ChangeModal;

import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { App, Button, Col, Tooltip } from 'antd';
import { useLazyQuery } from '../../../../hooks/useAxios';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import MyInput from '../../../../components/MyInput'
import MyModal from '../../../../components/MyModal'
import MySelect from '../../../../components/MySelect';
import MyCheckbox from '../../../../components/MyCheckbox';
import { InfoCircleOutlined } from '@ant-design/icons'
import MyRadioButton from '../../../../components/MyRadioButton';

const BonusUpdateStatusModal = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(null);
    const { notification } = App.useApp();
    const form = useForm();
    const { handleSubmit, watch, reset } = form;

    const approvalStatus = watch("approval_status");

    const { refetch: updateAdvanceStatus, loading: createAdvanceLoading } = useLazyQuery('/user/advance/approve/' + id, { method: 'patch' });

    useImperativeHandle(ref, () => ({
        openModal: (rowItem) => {
            setOpen(true);
            reset(rowItem);
            setId(rowItem?.id);
        },
        closeModal: () => {
            setOpen(false);
        }
    }));

    const onSubmit = async (data) => {
			console.log(data);
			const formValues = {
				status: data?.approval_status === true ? 2 : 3,
				reject_reason: data?.approval_status === false ? data.reject_reason: null
			}
        try {
            await updateAdvanceStatus({
                data: {
                   ...formValues
                }
            });
            notification.success({
                message: t('notificationSuccess.AdvanceUpdate.successTitle')
            });
            props.handleRefreshTable();
        } catch (error) {
            console.error('Form submission error:', error);
            notification.error({
                message: 'Error',
                description: t('advanceCreateModal.errorDescription')
            });
        } finally {
            setOpen(false);
        }
    };

    return (
        <MyModal
            isOpen={open}
            setIsOpen={setOpen}
            title={t('AdvanceCreateModal.titleUpdate')}
            handleSubmit={handleSubmit(onSubmit)}
            confirmLoading={createAdvanceLoading}
            formMethods={form}
        >
            <Col span={24}>
                <MyRadioButton
                    label={t('AdvanceCreateModal.approvalStatus')}
                    name="approval_status"
                    options={[
                        {
                            value: true,
                            label: 'Avans talebini onayla'
                        },
                        {
                            value: false,
                            label: 'Avans talebini reddet'
                        }
                    ]}
                />
            </Col>
            {approvalStatus === false && (
                <Col span={24}>
                    <MyInput
                        name="reject_reason"
                        label={t('AdvanceCreateModal.rejectReason')}
                        type="textarea"
                    />
                </Col>
            )}
        </MyModal>
    )
});

export default BonusUpdateStatusModal;

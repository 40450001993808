import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Card,
    Col,
    Form,
    Row,
    Steps,
    Select,
    notification,
    InputNumber,
    Checkbox,
    theme
} from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import MyInput from '../../components/MyInput';
import MyPhoneInput from '../../components/MyPhoneInput';
import MySelect from '../../components/MySelect';
import { useLazyQuery } from '../../hooks/useAxios';

import styles from './register.module.css';
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const { t } = useTranslation();
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState();
    const [recurrenceCount, setRecurrenceCount] = useState(3);
    const [personalCount, setPersonalCount] = useState(1);
    const [price, setPrice] = useState(0);
    const [registerConsumerCard, setRegisterConsumerCard] = useState(true);

    const form = useForm();

    const {
        handleSubmit,
        formState: { errors }
    } = form;

    const { data, loading, refetch } = useLazyQuery('/plan/list', {});

    useEffect(() => {
        if (plans?.length > 0) {
            function gtag() {
                window.dataLayer.push(arguments);
            }
            gtag('event', 'view_item_list', {
                item_list_name: 'Plans',
                item_list_id: 'plan_list',
                items: plans.map((plan) => {
                    return {
                        item_id: plan.plan_id,
                        item_name: plan.name,
                        item_category: 'Plans',
                        price: plan.price
                    };
                })
            });
            plans.forEach((plan) => {
                console.log(plan)
                gtag('event', 'view_item', {
                    currency: 'TRY',
                    value: plan.price,
                    items: [
                        {
                            item_id: plan.plan_id,
                            item_name: plan.name,
                            item_category: 'Plans',
                            price: plan.price,

                        }
                    ]
                });
            });
        }
    }, [plans]);

    useEffect(() => {
        const fetchData = async () => {
            if (!loading && !data) {
                try {
                    const result = await refetch();
                    setPlans(result.data);
                    setSelectedPlan(result?.data[1]?.plan_id)
                } catch (error) {
                    console.error('Error refetching data:', error);
                }
            }
        };
        fetchData();
    }, [loading, data]);

    useEffect(() => {
        if (selectedPlan) {
            const plan = plans.find((p) => p.plan_id === selectedPlan);
            if (plan) {
                setPrice(personalCount * plan.price);
            }
        }
    }, [selectedPlan, personalCount, plans]);

    const { refetch: registerUser } = useLazyQuery('/plan/business/create', {
        method: 'post'
    });
    const navigate = useNavigate();
    let selectedPlanRef;
    if(selectedPlan === 9){
        selectedPlanRef = plans[0]?.reference_code;
    }
    else if(selectedPlan === 10){
        selectedPlanRef = plans[1]?.reference_code;

    }
    else{
        selectedPlanRef = plans[2]?.reference_code;

    }
    const onSubmit = async (formData) => {
    try {
        const paymentCardData = {
            cardHolderName: formData.paymentCard.cardHolderName,
            cardNumber: formData.paymentCard.cardNumber,
            expireYear: formData.paymentCard.cardDate.slice(3, 5),
            expireMonth: formData.paymentCard.cardDate.slice(0, 2),
            cvc: formData.paymentCard.cvc,
            registerConsumerCard: registerConsumerCard,
            recurrenceCount: formData.recurrence_count
        };

        const formattedCustomerData = {
            ...formData.customer,
            billingAddress: {
                ...formData.customer.billingAddress,
                contactName: `${formData.customer.name} ${formData.customer.surname}`,
                country: 'Türkiye',
                city: formData.customer.billingAddress.city,
                address: formData.customer.billingAddress.address
            },
            shippingAddress: {
                ...formData.customer.shippingAddress,
                contactName: `${formData.customer.name} ${formData.customer.surname}`,
                country: 'Türkiye',
                city: formData.customer.billingAddress.city,
                address: formData.customer.billingAddress.address
            }
        };

        const payload = {
            business_name: formData.business_name,
            plan_id: selectedPlan,
            price: price,
            reference_code: selectedPlanRef,
            recurrence_count: formData.recurrence_count,
            personel_count: parseInt(formData.personal_count, 10),
            tax_number: formData.tax_number,
            tax_office: formData.tax_office,
            paymentCard: paymentCardData,
            customer: formattedCustomerData
        };

        console.log(payload, "Formatted Payload");

        const result = await registerUser({ data: payload });

        if (result.result === 'success') {
            notification.success({
                message: t('Register.successTitle'),
                description: t('Register.successMessage')
            });
            setTimeout(() => {
                navigate('/sign-in');
            }, 1000);
        } else {
            console.error('Unexpected result format:', result);
            notification.error({
                message: t('Register.errorTitle'),
                description: t('Register.errorMessage')
            });
        }
    } catch (e) {
        console.error('Registration error:', e.response?.data || e.message);
        notification.error({
            message: t('Register.errorTitle'),
            description: t('Register.errorMessage')
        });
    }
};

    
    const steps = [
        {
            title: t('Register.PlanInformation'),
            content: (
                <>
                    <div className={styles.plansContainer}>
                        {plans &&
                            plans?.map((plan) => (
                                <Card
                                    hoverable={
                                        selectedPlan !== plan.plan_id
                                            ? true
                                            : false
                                    }
                                    className={
                                        selectedPlan === plan.plan_id
                                            ? styles.selectedCard
                                            : ''
                                    }
                                    key={plan.plan_id}
                                    onClick={() =>
                                        setSelectedPlan(plan.plan_id)
                                    }
                                    title={plan.name + ' ' + t('Register.Plan')}
                                    extra={
                                        t('Register.PerPerson') +
                                        ': ₺' +
                                        plan.price
                                    }
                                >
                                    <p>{plan.description}</p>
                                    {plan?.modules?.map((module) => (
                                        <div key={module.name}>
                                            <p className={styles.moduleBorder}>
                                                {module.name}
                                            </p>
                                            <p>{module.description}</p>
                                        </div>
                                    ))}
                                </Card>
                            ))}
                    </div>
                    <div className={styles.formContainer}>
                        <Row gutter={[16, 16]}>
                            {/* <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput
                                    name="reference_code"
                                    label="Reference Code"
                                    hidden
                                />
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput name="plan" label="Plan" hidden />
                            </Col> */}
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput
                                    name="personal_count"
                                    label={t('Register.PersonalCount')}
                                    placeholder={t(
                                        'Reegister.PersonalCountisrequired'
                                    )}
                                    defaultValue={1}
                                    value={personalCount}
                                    onChange={(value) =>
                                        setPersonalCount(value)
                                    }
                                />
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MySelect
                                    name="recurrence_count"
                                    label={t('Register.RecurrenceCount')}
                                    value={recurrenceCount}
                                    onChange={(value) =>
                                        setRecurrenceCount(value)
                                    }
                                    options={[
                                        { value: 3, label: '3 Ay' },
                                        { value: 6, label: '6 Ay' },
                                        { value: 12, label: '12 Ay' }
                                    ]}
                                />
                            </Col>
                            {/* <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput name="price" label="Price" hidden />
                            </Col> */}
                        </Row>
                        <div className={styles.priceDisplay}>
                           Toplam Fiyat: ₺{price}/Aylık
                        </div>
                    </div>
                </>
            )
        },
        {
            title: t('Register.CompanyInformation'),
            content: (
                <>
                    <div className={styles.formContainer}>
                        <Row gutter={[16, 16]}>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput
                                    name="business_name"
                                    label={t('Register.BusinessName')}
                                    placeholder={t(
                                        'Register.BusinessNameisrequired'
                                    )}
                                />
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput
                                    name="tax_number"
                                    label={t('Register.TaxNumber')}
                                    placeholder={t(
                                        'Register.TaxNumberisrequired'
                                    )}
                                />
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput
                                    name="tax_office"
                                    label={t('Register.TaxOffice')}
                                    placeholder={t(
                                        'Register.TaxOfficeisrequired'
                                    )}
                                />
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput
                                    name="customer.name"
                                    label={t('Register.Firstname')}
                                    placeholder={t(
                                        'Register.Firstnameisrequired'
                                    )}
                                />
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput
                                    name="customer.surname"
                                    label={t('Register.Surname')}
                                    placeholder={t(
                                        'Register.Surnameisrequired'
                                    )}
                                />
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput
                                    name="customer.email"
                                    label={t('Register.Email')}
                                    placeholder={t('Register.Emailisrequired')}
                                />
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyPhoneInput
                                    name="customer.gsmNumber"
                                    label={t('Register.PhoneNumber')}
                                    placeholder={t(
                                        'Register.PhoneNumberisrequired'
                                    )}
                                />
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput
                                    name="customer.identityNumber"
                                    label={t('Register.IdentityNumber')}
                                    placeholder={t(
                                        'Register.IdentityNumberisrequired'
                                    )}
                                />
                            </Col>
                        </Row>
                    </div>
                </>
            )
        },
        {
            title: t('Register.Payment'),
            content: (
                <>
                    <div className={styles.formContainer}>
                        <Row gutter={[16, 16]}>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput
                                    name="customer.billingAddress.address"
                                    label={t('Register.Address')}
                                    placeholder={t(
                                        'Register.Addressisrequired'
                                    )}
                                />
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput
                                    name="customer.billingAddress.zipCode"
                                    label={t('Register.ZipCode')}
                                    placeholder={t(
                                        'Register.ZipCodeisrequired'
                                    )}
                                />
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput
                                    name="customer.billingAddress.city"
                                    label={t('Register.City')}
                                    placeholder={t('Register.Cityisrequired')}
                                />
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput
                                    name="customer.billingAddress.district"
                                    label={t('Register.District')}
                                    placeholder={t(
                                        'Register.Districtisrequired'
                                    )}
                                />
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput
                                    name="paymentCard.cardHolderName"
                                    label={t('Register.CardHolderName')}
                                    placeholder={t(
                                        'Register.CardHolderNameisrequired'
                                    )}
                                />
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput
                                    name="paymentCard.cardNumber"
                                    label={t('Register.CardNumber')}
                                    placeholder={t(
                                        'Register.CardNumberisrequired'
                                    )}
                                />
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput
                                    name="paymentCard.cardDate"
                                    label={t('Register.ExpireDate')}
                                    placeholder="MM/YY"
                                    type="dateMonth"
                                />
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput
                                    name="paymentCard.cvc"
                                    label={t('Register.CVCNumber')}
                                    placeholder={t(
                                        'Register.CVCNumberisrequired'
                                    )}
                                />
                            </Col>
                            <Checkbox
                                checked={registerConsumerCard}
                                onChange={(e) =>
                                    setRegisterConsumerCard(e.target.checked)
                                }
                            >
                                {t('Register.RegisterConsumerCard')}
                            </Checkbox>
                        </Row>
                    </div>
                </>
            )
        }
    ];

    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title
    }));

    const contentStyle = {
        // lineHeight: '260px',
        textAlign: 'center',
        color: token.colorTextTertiary,
        backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: `1px solid ${token.colorBorder}`,
        marginTop: 16
    };

    return (
        <>
            <title>{t('Register.title')}</title>
            <div>
                <div className={styles.header}>HR Skript</div>
                <div className={styles.planTitle}>
                    Size uygun planı seçin, şirket bilgilerinizi girin ve hemen
                    İK yönetimin avantajlarından faydalanın!
                </div>
                <div className={styles.steps}>
                    <FormProvider {...form}>
                        <Form
                            onFinish={handleSubmit(onSubmit)}
                            className={styles.form}
                        >
                            <Steps
                                labelPlacement="vertical"
                                current={current}
                                items={items}
                            />
                            <div style={contentStyle}>
                                {steps[current].content}
                            </div>
                            <div
                                style={{
                                    marginTop: 24,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                {current > 0 && (
                                    <Button
                                        style={{
                                            margin: '0 8px'
                                        }}
                                        onClick={() => prev()}
                                    >
                                        {t('Register.Previous')}
                                    </Button>
                                )}
                                {current < steps.length - 1 && (
                                    <Button
                                        type="primary"
                                        onClick={() => next()}
                                    >
                                        {t('Register.Next')}
                                    </Button>
                                )}
                                {current === steps.length - 1 && (
                                    <Button
                                        htmlType="submit"
                                        loading={loading}
                                        type="primary"
                                    >
                                        {t('Register.Submit')}
                                    </Button>
                                )}
                            </div>
                        </Form>
                    </FormProvider>
                </div>
            </div>
        </>
    );
};

export default Register;

import { zodResolver } from '@hookform/resolvers/zod';
import { App, Button, Form } from 'antd';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { z } from 'zod';
import MyInput from '../../components/MyInput';
import { useLazyQuery } from '../../hooks/useAxios';
import styles from './ResetPassword.module.css';
import { useParams, useNavigate } from 'react-router-dom';


const schema = z.object({
    password: z
        .string()
        .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/, {
            message: <Trans i18nKey="SignIn.passwordError" />
        }),
    confirmPassword: z
        .string()
        .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/, {
            message: <Trans i18nKey="SignIn.passwordError" />
        })
}).refine(data => data.password === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword']
});


const ResetPassword = () => {
    const { notification } = App.useApp();
    const { token } = useParams()
    console.log(token)

    const { t } = useTranslation();
    const form = useForm({ resolver: zodResolver(schema) });
    const { handleSubmit } = form;
    const { data, loading, error, refetch } = useLazyQuery('/auth/reset-password', {
        method: 'post'
    });
    const navigate = useNavigate();


    const onSubmit = async (data) => {
        const response = await refetch({
            data: {
                token: token,
                newPassword: data.password
            }
        });
        console.log(response)
        if (response.result == 'success') {
            notification.success({
                message: t('ResetPassword.success')
            });
            navigate('/sign-in')
        } else {
            notification.error({
                message: response.message
            });
        }
    };



    return (
        <FormProvider {...form}>
            <Form onFinish={handleSubmit(onSubmit)} className={styles.form}>
                <MyInput
                    name="password"
                    label={t('ResetPassword.password')}
                    type='password'
                />
                <MyInput
                    name="confirmPassword"
                    label={t('ResetPassword.confirmPassword')}
                    type='password'
                />
                <Button type="primary" htmlType="submit" loading={loading}>
                    {t('ResetPassword.submit')}
                </Button>
            </Form>
        </FormProvider>
    );
};

export default ResetPassword;

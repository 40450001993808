import React from 'react';
import { List, Button, Tooltip, notification, Col } from 'antd';

import { Link } from 'react-router-dom';
import {
    CopyOutlined,
    MailOutlined,
    PhoneOutlined,
    EyeInvisibleOutlined
} from '@ant-design/icons';

import ContactModal from './ContactModal';
import DataCard from '../DataCard/DataCard';

const Contact = ({ data }) => {
    return (
        <>
            <DataCard
                title="İletişim"
                Extra={true}
                data={data}
                Modal={ContactModal}
            >
                <Col span={24}>
                    <List>
                        <List.Item
                            actions={[
                                data.workEmail && (
                                    <Tooltip title="Kopyala">
                                        <Button
                                            type="link"
                                            icon={<CopyOutlined />}
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    data.work_email
                                                );
                                            }}
                                        />
                                    </Tooltip>
                                )
                            ]}
                        >
                            <List.Item.Meta
                                style={{
                                    alignItems: 'center'
                                }}
                                avatar={<MailOutlined />}
                                title="E-Posta (İş)"
                                description={
                                    <Link to={`mailto:${data.work_email}`}>
                                        {data.work_email || '—'}
                                    </Link>
                                }
                            />
                        </List.Item>

                        <List.Item
                            actions={[
                                data.workPhone && (
                                    <Tooltip title="Kopyala">
                                        <Button
                                            type="link"
                                            icon={<CopyOutlined />}
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    data.work_phonenumber
                                                );
                                                notification.success({
                                                    message: 'Kopyalandı',
                                                    description:
                                                        'Telefon numarası kopyalandı'
                                                });
                                            }}
                                        />
                                    </Tooltip>
                                )
                            ]}
                        >
                            <List.Item.Meta
                                style={{
                                    alignItems: 'center'
                                }}
                                avatar={<PhoneOutlined />}
                                title="Telefon (İş)"
                                description={
                                    <Link
                                        to={
                                            data.work_phonenumber &&
                                            `tel:${data.work_phonenumber}`
                                        }
                                    >
                                        {data.work_phonenumber || '—'}
                                    </Link>
                                }
                            />
                        </List.Item>
                        <List.Item
                            actions={[
                                data.email && (
                                    <Tooltip title="Kopyala">
                                        <Button
                                            type="link"
                                            icon={<CopyOutlined />}
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    data.email
                                                );
                                                notification.success({
                                                    message: 'Kopyalandı',
                                                    description:
                                                        'E-posta adresi kopyalandı'
                                                });
                                            }}
                                        />
                                    </Tooltip>
                                )
                            ]}
                        >
                            <List.Item.Meta
                                style={{
                                    alignItems: 'center'
                                }}
                                avatar={<EyeInvisibleOutlined />}
                                title="E-Posta (Kişisel)"
                                description={
                                    <Link to={`mailto:${data.email}`}>
                                        {data.email || '—'}
                                    </Link>
                                }
                            />
                        </List.Item>
                        <List.Item
                            actions={[
                                data.mobilePhone && (
                                    <Tooltip title="Kopyala">
                                        <Button
                                            type="link"
                                            icon={<CopyOutlined />}
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    data.phone_number
                                                );
                                            }}
                                        />
                                    </Tooltip>
                                )
                            ]}
                        >
                            <List.Item.Meta
                                style={{
                                    alignItems: 'center'
                                }}
                                avatar={<EyeInvisibleOutlined />}
                                title="Telefon (Kişisel)"
                                description={
                                    <Link to={`tel:${data.phone_number}`}>
                                        {data.phone_number || '—'}
                                    </Link>
                                }
                            />
                        </List.Item>
                    </List>
                </Col>
            </DataCard>
        </>
    );
};

export default Contact;

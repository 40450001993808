import React, { useRef } from 'react'

const useRefreshTable = () => {
    const refreshTableRef = useRef()

    const handleRefreshTable = () => {
        refreshTableRef.current()
    }

    return {
        refreshTable: e => refreshTableRef.current = e,
        handleRefreshTable
    }
}

export default useRefreshTable
import { Card, Col, Row, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import MyTable from '../../../../components/MyTable';

const Career = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('Branches.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('Departments.department_name'),
      dataIndex: 'department_name',
      key: 'department_name',
    },
    {
      title: t('Titles.title_name'),
      dataIndex: 'title_name',
      key: 'title_name',
    },
    {
      title: t('Workers.career.salary'),
      key: 'salary_price',
      render: (_, data) => {
        return <span>{data.salary_price + ' ' + data.salary_currency}</span>;
      },
    },
    {
      title: t('Workers.career.salary_aggreement_date'),
      dataIndex: 'salary_agreement_date',
      key: 'salary_agreement_date',
      render: (text) => formatDate(text),
    },
    {
      title: t('Workers.career.salary_status'),
      key: 'status',
      render: (data) => {
        return (
          <Tag color={data.status === 'true' ? 'success' : 'error'}>{data.status === 'true' ? 'Aktif' : 'Deaktif'}</Tag>
        );
      },
    },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('tr-TR', {
      dateStyle: 'long',
      timeStyle: 'short',
    }).format(date);
  };

  return (
    <>
      <title>{t('Workers.career.title')}</title>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title={t('Workers.career.salary_info')}>
            <MyTable initialColumns={columns} url="/user/salary/10" pagination={{ position: ['bottomCenter'] }} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Career;

import { Avatar, Badge, Col, Flex, Input, notification, Radio, Row, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import MyModal from '../../components/MyModal';
import apiLang from '../../helpers/apiLang';

const { Text } = Typography;

const LeaveDetailModal = ({ open, setOpen, data, onSubmit }) => {
    const form = useForm();
    const [approvalStatus, setApprovalStatus] = useState(null);
    const [rejectReason, setRejectReason] = useState('');


    const handleSubmit = async () => {
        if(data?.status === 1){
            if (approvalStatus === null) {
                notification.error({
                    message:'Lütfen bir seçim yapınız: Onayla veya Reddet.'
                });
                return;
            }
        }
       
        if (onSubmit) {
            await onSubmit(approvalStatus,rejectReason);
            
        }
        setApprovalStatus(null);
        setRejectReason('');
        setOpen(false);
      
    };
        const getStatusBadge = (status) => {
            switch (status) {
                case 2:
                    return <Badge status="success" text="Bu talep onaylandı." />;
                case 3:
                    return <Badge status="error" text="Bu talep reddedildi." />;
                default:
                    return <Badge color="#faad14" status="processing" text="Bu talep onay bekliyor." />;
            }
        };

    return (
        <MyModal formMethods={form} isOpen={open} setIsOpen={setOpen} title={'İzin Talebi'} handleSubmit={handleSubmit}>
            <Col span={24}>
                <Row justify={'center'}>
                    <Col>
                        <Flex gap={'middle'}>
                            <Avatar shape="square" size={40}>
                                {data?.firstname?.charAt(0) + data?.lastname?.charAt(0)}
                            </Avatar>
                            {data && (
                                <Text
                                    style={{
                                        backgroundColor: '#f0f2f5',
                                        padding: '8px 12px',
                                        fontSize: 18,
                                    }}
                                >
                                    {data?.firstname} {data?.lastname}, {data[apiLang('reason')]} için 4 gün izin talep ediyor.
                                </Text>
                            )}
                        </Flex>
                        <div
                            style={{
                                backgroundColor: '#f0f2f5',
                                width: 'fit-content',
                                margin: '8px auto',
                                padding: '6px 15px',
                                borderRadius: '999px',
                            }}
                        >
                            {data?.status === 2
                                ? "Bu talep onaylandı."
                                : data?.status === 3
                                ? "Bu talep reddedildi."
                                : "Bu talep onay bekliyor."}
                        </div>
                        <Row>
                            <Col
                                span={24}
                                style={{
                                    borderBottom: '1px solid #f0f2f5',
                                    paddingBottom: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <Flex justify={'space-between'}>
                                    <Text>Durum</Text>
                                    {getStatusBadge(data?.status)}

                                </Flex>
                            </Col>
                            <Col
                                span={24}
                                style={{
                                    borderBottom: '1px solid #f0f2f5',
                                    paddingBottom: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <Flex justify={'space-between'}>
                                    <Text>İzin Türü</Text>
                                    <Text>{data && data[apiLang('reason')]}</Text>
                                </Flex>
                            </Col>
                            <Col
                                span={24}
                                style={{
                                    borderBottom: '1px solid #f0f2f5',
                                    paddingBottom: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <Flex justify={'space-between'}>
                                    <Text>Toplam İzin</Text>
                                    <Text>4</Text>
                                </Flex>
                            </Col>
                            <Col
                                span={24}
                                style={{
                                    borderBottom: '1px solid #f0f2f5',
                                    paddingBottom: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <Flex justify={'space-between'}>
                                    <Text>İzin Başlangıç</Text>
                                    <Text>{data?.start_date}</Text>
                                </Flex>
                            </Col>
                            <Col
                                span={24}
                                style={{
                                    borderBottom: '1px solid #f0f2f5',
                                    paddingBottom: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <Flex justify={'space-between'}>
                                    <Text>İzin Bitiş</Text>
                                    <Text>{data?.end_date}</Text>
                                </Flex>
                            </Col>
                            {data?.status === 1 ? <Col
                                span={24}
                                style={{
                                    borderBottom: '1px solid #f0f2f5',
                                    paddingBottom: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <Flex justify={'space-between'}>
                                    <Text>İzin Onaylama</Text>
                                    <Radio.Group onChange={(e) => setApprovalStatus(e.target.value)} defaultValue={1}   value={approvalStatus}>
                                    <Space direction="horizontal" style={{ display: 'flex' }}>
                                    <Radio value={2}>Onayla</Radio>
                                            <Radio value={3}>Reddet</Radio>
                                         </Space>
                                    </Radio.Group>
                                </Flex>
                                {approvalStatus === 3 && (
                                        <Input
                                            placeholder="Reddetme nedeni giriniz"
                                            value={rejectReason}
                                            onChange={(e) => setRejectReason(e.target.value)}
                                            style={{ marginTop: 10 }}
                                        />
                                    )}
                            </Col>: '' }
                            
                            
                                                        {/* <Col span={24} >
                                <Flex justify={'space-between'}>
                                    <Text >
                                        Mesai Başlangıç
                                    </Text>
                                    <Text >
                                        {data?.start_time + ' ' + data?.start_time}
                                    </Text>
                                </Flex>
                            </Col> */}
                            {/* <Col span={24} style={{
                            borderBottom : '1px solid #f0f2f5',
                            paddingBottom : 10,
                            marginBottom : 10}}>
                                <Flex justify={'space-between'}>
                                    <Text >
                                        Oluşturulma Tarihi
                                    </Text>
                                    <Text >
                                        25 Haziran 2024 00:39
                                    </Text>
                                </Flex>
                            </Col> */}
                        </Row>
                    </Col>
                </Row>
            </Col>
        </MyModal>
    );
};

export default LeaveDetailModal;

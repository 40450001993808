import { DownOutlined } from '@ant-design/icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dropdown, notification, Space, Tabs, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import DeleteConfirm from '../../components/DeleteConfirm/DeleteConfirm';
import { Header } from '../../components/Header';
import LeaveModal from '../../components/LeaveModal/LeaveModal';
import { useLazyQuery, useQuery } from '../../hooks/useAxios';
import ChangeRoleModal from './ChangeRoleModal';
import Career from './components/Career/Career';
import FireEmployeeModal from './FireEmployeeModal';
import Leave from './Leave';
import PersonalInformation from './PersonalInformation';
import Profile from './Profile';
import WorkerContext from './WorkerContext';

const { Text } = Typography;

const Worker = () => {
    const { userId } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [isLeaveOpen, setIsLeaveOpen] = useState(false);

    const { data: result, error, loading } = useQuery(`/user/${id}`);
    const { data: userRoleData } = useQuery(`/user/${userId}`);
    const currentUserRoleId = userRoleData?.results[0]?.role_id;
    const { data: roleData } = useQuery(`/role/${currentUserRoleId}`);

    const {
        data: editData,
        loading: editLoading,
        refetch: editQuery,
    } = useLazyQuery('/user/edit/' + id, { method: 'put' });

    const { loading: deleteLoading, refetch: deleteQuery } = useLazyQuery(`/user/delete/${id}`, { method: 'delete' });

    const handleDelete = async () => {
        try {
            const response = await deleteQuery();
            if (response.result === 'success') {
                notification.success({
                    message: 'Success',
                    description: 'User has been successfully deleted.',
                });
                setIsDeleteConfirmOpen(false);
            } else {
                throw new Error(response.data.message || 'Failed to delete user.');
            }
        } catch (error) {
            console.error('Delete error:', error);
            notification.error({
                message: 'Error',
                description: 'An error occurred while deleting the user.',
            });
        }
    };

    const isHR = roleData?.access_screen?.users_screen[3];
    //isHR true ise Delete yetkisi
    const items = [
        {
            label: (
                <Text type="text" onClick={(e) => setIsOpen(true)}>
                    Erişim tipini değiştir
                </Text>
            ),
            key: '1',
        },
        isHR && {
            label: (
                <Text type="text" onClick={() => setIsDeleteConfirmOpen(true)}>
                    Çalışanı İşten Çıkart
                </Text>
            ),
            key: '3',
        },
        {
            label: (
                <Text
                    type="text"
                    onClick={() => {
                        setIsLeaveOpen(true);
                    }}
                >
                    İzin Ekle
                </Text>
            ),
            key: '4',
        },
    ];

    const menuProps = {
        items,
    };

    const onSubmit = async (data) => {
        try {
            await editQuery({ data });
            setIsOpen(false);
        } catch (error) {
            console.error('Form submission error:', error);
            notification.error({
                message: 'Error',
                description: 'An error occurred while updating the user role',
            });
        }
    };

    const schema = z.object({
        role_id: z.number().int().positive(),
    });

    const form = useForm({
        resolver: zodResolver(schema),
        values: {
            role_id: result?.results?.[0]?.role_id,
        },
    });

    const { handleSubmit } = form;

    const data = useMemo(() => editData?.data || result?.results?.[0], [editData, result]);

    if (loading) {
        return <p>Loading...</p>;
    } else if (error) {
        return <p>Error: {error.message}</p>;
    }

    if (!data) {
        return <p>Worker not found</p>;
    }

    const tabItems = [
        {
            key: 'profile',
            label: 'Profil',
            children: <Profile data={data} />,
        },
        {
            key: 'personal',
            label: 'Kişisel Bilgiler',
            children: <PersonalInformation data={data} />,
        },
        {
            key: 'career',
            label: 'Kariyer',
            children: <Career />,
        },
        {
            key: 'leave',
            label: 'İzinler',
            children: <Leave userId={id} />,
        },
    ];

    return (
        <>
            <Header />

            <WorkerContext.Provider
                value={{
                    editQuery,
                    editLoading,
                }}
            >
                <FireEmployeeModal isOpen={false} />
                <Tabs
                    defaultActiveKey="profile"
                    items={tabItems}
                    tabBarExtraContent={
                        <Dropdown menu={menuProps} trigger={['click']}>
                            <Button>
                                <Space>
                                    Button
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    }
                />
                <ChangeRoleModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    form={form}
                />
                <DeleteConfirm
                    itemName={`${result?.results[0]?.firstname} ${result?.results[0]?.lastname}`}
                    itemType="user"
                    visible={isDeleteConfirmOpen}
                    onCancel={() => setIsDeleteConfirmOpen(false)}
                    onConfirm={() => setIsDeleteConfirmOpen(false)}
                    id={id}
                />
                <LeaveModal open={isLeaveOpen} setOpen={setIsLeaveOpen} userId={id} id={userId} />
            </WorkerContext.Provider>
        </>
    );
};

export default Worker;
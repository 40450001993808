import React from 'react';
import { Col } from 'antd';
import MyInput from '../../../../components/MyInput';
import { z } from 'zod';
import MySelect from '../../../../components/MySelect';

import { useTranslation } from 'react-i18next';
import ChangeModal from '../ChangeModal/ChangeModal';

const schema = z.object({
    bank_name: z.string().min(3).max(255).optional().nullable(),
    bank_account_type: z.string().min(3).max(255).optional().nullable(),
    bank_account_number: z.string().min(3).max(255).optional().nullable(),
    iban: z.string().min(3).max(255).optional().nullable()
});

const Bank = ({ isOpen, setIsOpen, data }) => {
    const { t } = useTranslation();

    return (
        <ChangeModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            data={data}
            schema={schema}
            success="Banka bilgileriniz başarıyla güncellendi"
            error="Banka bilgileriniz güncellenirken bir hata oluştu"
            title={t('bank_information')}
        >
            <Col span={12}>
                <MyInput
                    name="bank_name"
                    label={t('bank_name')}
                    placeholder="Banka adını giriniz"
                />
            </Col>
            <Col span={12}>
                <MySelect
                    label={t('bank_account_type')}
                    name="bank_account_type"
                    options={[
                        { label: 'Vadesiz', value: 'Vadesiz' },
                        { label: 'Vadeli', value: 'Vadeli' }
                    ]}
                />
            </Col>
            <Col span={12}>
                <MyInput
                    name="bank_account_number"
                    label={t('bank_account_number')}
                    placeholder="Banka hesap numarasını giriniz"
                />
            </Col>
            <Col span={12}>
                <MyInput
                    name="iban"
                    label={t('iban')}
                    placeholder="IBAN numarasını giriniz"
                />
            </Col>


        </ChangeModal>
    );
};

export default Bank;

import React from 'react';
import { Col, Typography } from 'antd';
import BankModal from './BankModal';
import DataCard from '../DataCard/DataCard';

const { Text } = Typography;

const Bank = ({ data }) => {

    return (
        <DataCard
            title="Banka Hesabı"
            Extra={true}
            data={data}
            Modal={BankModal}
        >
            <Col span={12}>
                <Text strong>Banka Adı:</Text> {data?.bank_name || '—'}
            </Col>
            <Col span={12}>
                <Text strong>Hesap Tipi:</Text> {data?.bank_account_type || '—'}
            </Col>
            <Col span={12}>
                <Text strong>Hesap Numarası</Text>
                {data?.bank_account_number || '—'}
            </Col>
            <Col span={12}>
                <Text strong>İban</Text>
                {data?.iban || '—'}
            </Col>
        </DataCard>
    );
};

export default Bank;

import React, { useEffect, useState } from 'react';
import { Col, notification } from 'antd';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import MyModal from '../MyModal';
import MyInput from '../MyInput';
import { useLazyQuery } from '../../hooks/useAxios';
import DeleteConfirm from '../DeleteConfirm';

export default function TitleModal({
    open,
    setIsOpen,
    modalData,
    handleRefreshTable,
    status
}) {
    const [modData, setModData] = useState(modalData);
    const { data: getModalData, refetch: getModal } = useLazyQuery(
        `/global/titles/${modalData.user_title_id}`
    );

    const getData = async () => {
        const test = await getModal();
        if (test?.result === "success") {
            const title_tr_name = test.data.title_tr_name || '';
            const title_en_name = test.data.title_en_name || '';
            setModData({ title_tr_name, title_en_name });
        }
    };

    useEffect(() => {
        if (modalData.user_title_id && status === 'edit') {
            getData();
        }
    }, [modalData.user_title_id, status]);

    const { t } = useTranslation();
    const form = useForm({
        defaultValues: {
            title_tr_name: '',
            title_en_name: ''
        }
    });

    const { handleSubmit, reset } = form;

    useEffect(() => {
        if (status === 'edit' && modData) {
            reset({
                title_tr_name: modData.title_tr_name || '',
                title_en_name: modData.title_en_name || ''
            });
        } else if (status === 'new') {
            reset({
                title_tr_name: '',
                title_en_name: ''
            });
        }
    }, [open, modData, reset, status]);

    const apiConfig = {
        url:
            status === 'edit'
                ? `/global/titles/edit/${modalData.user_title_id}`
                : status === 'delete'
                    ? `/global/titles/delete/${modalData.user_title_id}`
                    : `/global/titles/create`,
        method:
            status === 'edit' ? 'put' : status === 'delete' ? 'delete' : 'post'
    };

    const { loading, refetch } = useLazyQuery(apiConfig.url, {
        method: apiConfig.method
    });

    const onSubmit = async (data) => {
        try {
            const dataToSend = status === 'new' ? { titles: [data] } : data;
            const response = await refetch({ data: dataToSend });
            if (response.result === 'success') {
                let successMessage;
                if (apiConfig.method === 'put') {
                    successMessage = (
                        <Trans i18nKey="Titles.title_edit_success" />
                    );
                } else if (apiConfig.method === 'delete') {
                    successMessage = (
                        <Trans i18nKey="Titles.title_delete_success" />
                    );
                } else {
                    successMessage = (
                        <Trans i18nKey="Titles.title_create_success" />
                    );
                }
                notification.success({
                    message: successMessage
                });
                handleRefreshTable();
                setIsOpen(false);
            } else {
                notification.error({
                    message: response.result.message
                });
            }
        } catch (error) {
            notification.error({
                message: 'An error occurred!'
            });
        }
    };

    return (
        <MyModal
            title="Title Modal"
            isOpen={open}
            setIsOpen={setIsOpen}
            handleSubmit={handleSubmit(onSubmit)}
            confirmLoading={loading}
            rowGap={16}
            formMethods={form}
        >
            {status !== 'delete' ? (
                <>
                    <Col span={24}>
                        <MyInput name="title_tr_name"                             
                        label={t('Titles.title_tr')}
 />
                    </Col>
                    <Col span={24}>
                        <MyInput name="title_en_name" 
                        label={t('Titles.title_en')}                                                    
                    />
                    </Col>
                </>
            ) : (
                <p>{modalData.title_name} ünvanı silmek istediğinize emin misiniz ?</p>

            )}
        </MyModal>
    );
}

import React, { useImperativeHandle, useRef } from 'react';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { Input, Select, Form } from 'antd';
import flags from 'react-phone-number-input/flags';
import { Controller, useFormContext } from 'react-hook-form';
import defaultClasses from './MyPhoneInput.module.css';

const { Option } = Select;

const TryInput = React.forwardRef((props, ref) => {
    const inputRef = useRef(null);

    useImperativeHandle(
        ref,
        () => {
            return inputRef.current.input;
        },
        []
    );

    return (
        <Input
            {...props}
            onChange={e => {
                props?.onChange(e.target.value || undefined);
            }}
            ref={inputRef}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, }}
        />
    );
});

const CountrySelect = ({ labels, options, ...rest }) => (
    <Select showSearch {...rest} className={defaultClasses.countrySelect}>
        {options.map((option) => {
            const Flag = flags[option.value];
            return (
                <Option key={option.value} value={option.value}>
                    {Flag ? (
                        <Flag
                            style={{
                                width: '1.5rem',
                                height: '1.5rem',
                                display: 'flex'
                            }}
                        />
                    ) : (
                        `${option.value} Ülkesiz`
                    )}
                </Option>
            );
        })}
    </Select>
);

const MyPhoneInput = ({ value, name, label }) => {
    const {
        control,
        formState: { errors }
    } = useFormContext();
    return (
        <Form.Item
            label={label}
            validateStatus={errors[name] && 'error'}
            help={errors[name]?.message}
            name={name}
            labelCol={{
                span: 24,
                style: { paddingBottom: 0, display: 'grid' }
            }}
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: 0 }}
        >
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value, ...field } }) => {
                    if (value && !value.startsWith('+')) {
                        onChange(`+${value}`);
                    }
                    return (
                    <PhoneInputWithCountry
                        international
                        limitMaxLength={true}
                        value={value}
                        onChange={onChange}
                        defaultCountry="TR"
                        id={name}
                        countrySelectComponent={CountrySelect}
                        inputComponent={TryInput}
                        numberInputProps={{
                            name: 'phone',
                            type: 'tel'
                        }}
                        countryCallingCodeEditable={false}
                        {...field}
                    />
                    )
                }}
            />
        </Form.Item>
    );
};

export default MyPhoneInput;

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Flex, List } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MyInput from '../../../components/MyInput';
import MyModal from '../../../components/MyModal';
import useRefreshTable from '../../../components/MyTable/useRefreshTable';
import { useLazyQuery } from '../../../hooks/useAxios';

const DepartureReasons = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [status, setStatus] = useState('');
  const { refreshTable, handleRefreshTable } = useRefreshTable();
  const { t } = useTranslation();

  const form = useForm({
    defaultValues: {
      reason_tr: '',
      reason_en: '',
    },
  });

  const { handleSubmit, reset, getValues } = form;

  const {
    data: getDepartureReasonsData,
    loading: getDepartureReasonsLoading,
    refetch: getDepartureReasons,
  } = useLazyQuery('/global/departure-reasons');
  const {
    data: createDepartureReasonsData,
    loading: createDepartureReasonsLoading,
    refetch: createDepartureReasons,
  } = useLazyQuery('/global/departure-reasons/create', { method: 'POST' });
  const {
    data: editDepartureReasonsData,
    loading: editDepartureReasonsLoading,
    refetch: editDepartureReasons,
  } = useLazyQuery(`/global/departure-reasons/edit/${modalData?.id}`, { method: 'PUT' });
  const {
    data: deleteDepartureReasonsData,
    loading: deleteDepartureReasonsLoading,
    refetch: deleteDepartureReasons,
  } = useLazyQuery(`/global/departure-reasons/delete/${modalData?.id}`, { method: 'DELETE' });
  const {
    data: getDepartureReason2Data,
    loading: getDepartureReason2Loading,
    refetch: getDepartureReason2,
  } = useLazyQuery(`/global/departure-reason/${modalData?.id}`, { method: 'GET' });

  useEffect(() => {
    const getData = async () => {
      const response = await getDepartureReasons();
    };
    getData();
  }, [getDepartureReasons]);
  useEffect(() => {
    const getDataReasons = async () => {
      const response = await getDepartureReason2();
    };
    getDataReasons();
  }, [getDepartureReason2]);

  useEffect(() => {
    if (modalData && isOpen && status === 'edit') {
      const fetchDataAndResetForm = async () => {
        try {
          const response = await getDepartureReason2();
          if (response?.result === 'success') {
            reset({
              reason_tr: response.data[0]?.reason_tr || '',
              reason_en: response.data[0]?.reason_en || '',
            });
          }
        } catch (error) {
          console.error('Error fetching departure reason data:', error);
        }
      };
      fetchDataAndResetForm();
    } else if (status === 'new') {
      reset({
        reason_tr: '',
        reason_en: '',
      });
    }
  }, [modalData, isOpen, reset, status, getDepartureReason2]);

  const onSubmit = async (data) => {
    try {
      if (status === 'new') {
        const formattedData = {
          departures: [
            {
              reason_tr: data.reason_tr,
              reason_en: data.reason_en,
            },
          ],
        };
        const response = await createDepartureReasons({ data: formattedData });
      } else if (status === 'edit') {
        const formattedData = {
          reason_tr: data.reason_tr,
          reason_en: data.reason_en,
        };

        const responseEdit = await editDepartureReasons({ data: formattedData });
      } else if (status === 'delete') {
        const responseDelete = await deleteDepartureReasons();
      }
    } catch (error) {
      console.error('Error handling submission:', error);
    }
    setIsOpen(false);
    getDepartureReasons();
  };

  const handleCreate = () => {
    setStatus('new');
    setModalData(null);
    setIsOpen(true);
  };

  const handleEdit = (data) => {
    setStatus('edit');
    setModalData(data);
    setIsOpen(true);
  };

  const handleDelete = (data) => {
    setStatus('delete');
    setModalData(data);
    setIsOpen(true);
  };

  if (getDepartureReasonsLoading || !getDepartureReasonsData) {
    return <div>Loading...</div>;
  }

  return (
    <FormProvider {...form}>
      <>
        <MyModal
          title={
            status === 'delete' ? t('DepartureReason.DeleteDepartureReason') : t('DepartureReason.AddDepartureReason')
          }
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleSubmit={handleSubmit(onSubmit)}
          confirmLoading={
            createDepartureReasonsLoading ||
            editDepartureReasonsLoading ||
            deleteDepartureReasonsLoading ||
            getDepartureReason2Loading
          }
          rowGap={16}
          formMethods={form}
          handleRefreshTable={handleRefreshTable}
        >
          {status !== 'delete' ? (
            <>
              <Col span={24}>
                <MyInput name="reason_tr" label={t('DepartureReason.DepartureReasonTR')} />
              </Col>
              <Col span={24}>
                <MyInput name="reason_en" label={t('DepartureReason.DepartureReasonEN')} />
              </Col>
            </>
          ) : (
            <>{t('DepartureReason.areyousure')}</>
          )}
        </MyModal>
        <Card
          title={t('DepartureReason.DepartureReasonsSettings')}
          extra={<Button onClick={handleCreate}>{t('DepartureReason.Add')}</Button>}
          handleRefreshTable={handleRefreshTable}
        >
          <p
            style={{
              fontSize: '16px',
              color: '#333',
              padding: '10px',
              borderRadius: '5px',
              backgroundColor: '#f9f9f9',
            }}
          >
            {t('DepartureReason.listofDeparture')}
          </p>
          <List
            bordered
            dataSource={getDepartureReasonsData.data}
            renderItem={(item, index) => (
              <List.Item>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                  <div>
                    <span style={{ fontSize: 'larger', fontWeight: 'bold', paddingRight: '10px' }}>{index + 1}</span>
                    <span style={{ fontSize: 'larger', fontWeight: '500' }}>{item.departure_reason}</span>
                  </div>
                  <Flex gap={8}>
                    <div>
                      <Button type="primary" onClick={() => handleEdit(item)}>
                        <EditOutlined />
                      </Button>
                    </div>
                    <div>
                      <Button type="primary" danger onClick={() => handleDelete(item)}>
                        <DeleteOutlined />
                      </Button>
                    </div>
                  </Flex>
                </div>
              </List.Item>
            )}
          />
        </Card>
      </>
    </FormProvider>
  );
};

export default DepartureReasons;

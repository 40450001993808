import React from 'react';
import { Col } from 'antd';
import MyInput from '../../../../components/MyInput';
import MyPhoneInput from '../../../../components/MyPhoneInput';
import { z } from 'zod';
import { isValidPhoneNumber } from 'react-phone-number-input';
import ChangeModal from '../ChangeModal/ChangeModal';

const schema = z.object({
    work_email: z.union([z.string().email(), z.literal('')]).nullable(),
    work_phonenumber: z
        .string()
        .refine(isValidPhoneNumber, {
            message: 'Geçersiz telefon numarası'
        })
        .nullable(),
    email: z.union([z.string().email(), z.literal('')]).nullable(),
    phone_number: z
        .string()
        .refine(isValidPhoneNumber, {
            message: 'Geçersiz telefon numarası'
        })
        .nullable()
});

const ContactModal = ({ isOpen, setIsOpen, data }) => {
    return (
        <ChangeModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            schema={schema}
            title="İletişim Bilgileri"
            data={data}
            success="İletişim bilgileriniz başarıyla güncellendi"
        >
            <Col span={12}>
                <MyInput name="work_email" label="E-Posta (İş)" />
            </Col>
            <Col span={12}>
                <MyPhoneInput name="work_phonenumber" label={'Telefon (İş)'} />
            </Col>
            <Col span={12}>
                <MyInput name="email" label="E-Posta (Kişisel)" />
            </Col>
            <Col span={12}>
                <MyPhoneInput name="phone_number" label={'Telefon (Kişisel)'} />
            </Col>
        </ChangeModal>
    );
};

export default ContactModal;

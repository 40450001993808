import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Button, Flex, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './ExpenseModal.module.css';
import { useLazyQuery } from '../../hooks/useAxios';
import MyModal from '../MyModal';
import MyInput from '../MyInput';
import MySelect from '../MySelect';

const ExpenseModal = ({
  isOpen,
  setIsOpen,
  modalData,
  handleRefreshTable,
  status,
  expenseItem,
  setExpenseItem,
  userPermissions,
}) => {
  console.log(modalData,"modalData");
  const { t } = useTranslation();
  const [expenseTypes, setExpenseTypes] = useState([]);
  const {
    data: expenseTypesData,
    loading: expenseTypesLoading,
    refetch: expenseTypesRefetch,
  } = useLazyQuery('/expense/expense-types');
  const { data, loading, refetch } = useLazyQuery('/expense/create', { method: 'POST' });
  const {
    data: expenseApproveData,
    loading: expenseApproveLoading,
    refetch: approveExpense,
  } = useLazyQuery('/expense/approve', { method: 'PATCH' });

  const {
    data: expenseDeleteData,
    loading: expenseDeleteLoading,
    refetch: deleteExpense,
  } = useLazyQuery(`/expense/delete/${modalData?.id}`, { method: 'DELETE' });

  const expenseTypeOptions = async () => {
    const expenseType = await expenseTypesRefetch();
    if (expenseType?.result === 'success') {
      setExpenseTypes(expenseType?.data);
    }
  };

  useEffect(() => {
    expenseTypeOptions();
  }, []);

  useEffect(() => {
    if (modalData?.id) {
      console.log(modalData, 'modalData');
    }
  }, [isOpen, modalData]);

  const form = useForm({
    defaultValues: {
      name: modalData?.name || '',
      description: modalData?.description || '',
    },
  });

  const { handleSubmit, reset, watch } = form;

  const onSubmit = async (data) => {
    if (status === 'approve') {
      const updatedExpenseItems = data.expense_items.map((item, index) => ({
        expense_items_id: item.expense_items_id,
        approval_status: item.approval_status ? 2 : 3,
        ...(item.approval_status === false && { reject_reason: item.reject_reason }),
      }));
      console.log(updatedExpenseItems, 'approveItemsAAA');
      // return true;
      const response = await approveExpense({ data: { expense_items: updatedExpenseItems } });
      console.log(response, 'response');
    } else {
      const response = await refetch({ data });
    }

    setIsOpen(false);
    handleRefreshTable();
  };

  useEffect(() => {
    if (modalData && status === 'edit') {
      setExpenseItem(modalData.items.length);
      const expenseItems = modalData.items.map((item) => ({
        type_id: item.type_id,
        amount: item.amount,
        currency: item.currency,
      }));
      reset({
        name: modalData?.name || '',
        description: modalData?.description || '',
        expense_items: expenseItems,
      });
    } else if (status === 'create') {
      reset({
        name: '',
        description: '',
        expense_items: [],
      });
    } else {
      // APPROVE
      reset({
        expense_items: modalData?.items.map((item, index) => ({
          expense_items_id: item.id,
          approval_status: false,
          reject_reason: '',
        })),
      });
    }
  }, [isOpen, modalData, reset, status, setExpenseItem]);

  const [array, setArray] = useState([]);
  const [toggle, setToggle] = useState(false);

  const handleOnChange = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    if (isOpen && modalData) {
      const expenseItems = watch('expense_items');
      setArray(expenseItems);
    } else {
      setArray([]);
    }
  }, [isOpen, toggle, watch, modalData]);

  return (
    <>
      <MyModal
        title="Masraf Talebi Oluştur"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleSubmit={handleSubmit(onSubmit)}
        confirmLoading={loading}
        rowGap={16}
        formMethods={form}
      >
        {status !== 'approve' ? (
          <div className={styles.expenseModalContainer}>
            <Col span={24}>
              <MyInput name="name" label={t('Expenses.name')} placeholder={t('Expenses.expense_name_placeholder')} />
            </Col>
            <Col span={24}>
              <MyInput
                name="description"
                label={t('Expenses.description')}
                placeholder={t('Expenses.expense_description_placeholder')}
              />
            </Col>
            {(userPermissions.includes('edit') && status === 'edit') ||
            (userPermissions.includes('create') && status === 'new') ? (
              <Col span={24}>
                <Flex justify="space-between" align="center">
                  <Button onClick={() => setExpenseItem(expenseItem - 1)} disabled={expenseItem === 0}>
                    {t('Expenses.decrease_expense_item')}
                  </Button>
                  {expenseItem}
                  <Button onClick={() => setExpenseItem(expenseItem + 1)}>{t('Expenses.increase_expense_item')}</Button>
                </Flex>
              </Col>
            ) : null}

            {Array.from({ length: expenseItem }).map((_, index) => (
              <div className={styles.expenseItems} key={index}>
                <Col span={24}>
                  <MySelect
                    name={`expense_items[${index}[type_id]]`}
                    label={t('Expenses.expense_type')}
                    options={expenseTypes.map((expenseType) => ({
                      value: expenseType.id,
                      label: expenseType.name,
                    }))}
                    placeholder={t('Expenses.expense_type_placeholder')}
                  />
                </Col>
                <Col span={24}>
                  <MyInput
                    name={`expense_items[${index}[amount]]`}
                    label={t('Expenses.expense_amount')}
                    placeholder={t('Expenses.expense_amount_placeholder')}
                  />
                </Col>
                <Col span={24}>
                  <MyInput
                    name={`expense_items[${index}[currency]]`}
                    label={t('Expenses.expense_currency')}
                    placeholder={t('Expenses.expense_currency_placeholder')}
                  />
                </Col>
              </div>
            ))}
          </div>
        ) : (
          <>
            <p>Harcamalarınızı inceleyin, onaylayın veya masraf kalemlerini red edin.</p>
            {modalData?.items.map((item, index) => {
              return (
                <>
                  <div className={styles.approveContainer}>
                    <Col span={12}>
                      <MyInput
                        value={item.id}
                        defaultValues={item.id}
                        name={`expense_items[${index}].expense_items_id`}
                      />
                    </Col>
                    <Col span={12}>
                    <Flex align='center' gap={12}>
                      <MyInput
                        type="checkbox"
                        name={`expense_items[${index}].approval_status`}
                        onChange={() => handleOnChange()}
                      />
                      <span>{array && array[index]?.approval_status===true ? "Reddet" : "Onayla" }</span>
                      </Flex>
                    </Col>
                    {array && array[index]?.approval_status !== true ? (
                      <Col span={24}>
                        <MyInput label={`${index+1}. masraf kalemini red etme sebeniz`} name={`expense_items[${index}].reject_reason`} />
                      </Col>
                    ) : null}
                  </div>
                </>
              );
            })}
          </>
        )}
      </MyModal>
    </>
  );
};

export default ExpenseModal;

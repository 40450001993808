export const resources = {
  en: {
    translation: {
      errorCode: {
        ERROR_ADVANCE_NOT_EDITABLE: {
          title: 'This advance has been processed',
          message: 'The processed advance cannot be deleted',
        },
      },
      notificationSuccess: {
        advanceDelete: {
          message: 'Delete Successful',
          description: 'The advance has been successfully deleted.',
        },
        AdvanceCreate: {
          successTitle: 'Advance Request Created Successfully',
        },
        AdvanceUpdate: {
          successTitle: 'Advance Request Updated Successfully',
        },
        success:"Successful",
        error:"Unsuccessful"
      },
      SignIn: {
        title: 'Sign In',
        heading: 'Welcome',
        username: 'Username',
        password: 'Password',
        passwordError:
          'Password must contain at least one uppercase letter, one lowercase letter, one number and be at least 8 characters long',
      },
      ForgotPassword: {
        title: 'Forgot Password',
        email: 'Email',
      },
      Dashboard: {
        title: 'Dashboard',
        welcome: 'Welcome!',
        //TODO check missing values
      },
      Workers: {
        createPersonal: 'Create Personal',
        title: 'Workers',
        status:"Status",
        department:"Departmant",
        title_name:"Title",
        branch:"Branch",
        copyPhone:"Copy Phone",
        showAllTitle:"Show All Title",
        showAllDepartmant:"Show All Departmant",
        showAllBranch:"Show All Branch",
        columns: {
          id: 'Id',
          fullName: 'Full Name',
          title: 'Title',
          email: 'Email',
          phone: 'Phone',
        },
        createPersonalModal: {
          first_name: 'First Name',
          last_name: 'Last Name',
          email: 'Email',
          phone: 'Phone',
          hire_date: 'Hire Date',
          department_id: 'Department Id',
          job_id: 'Job Id',
        },
        career: {
          title: 'Career',
          salary_info: 'Salary Information',
          salary: 'Salary',
          salary_aggreement_date: 'Salary Aggrement Date',
          salary_status: 'Salary Status',
        },
      },
      LeaveModal: {
        title: 'Request Leave',
        leaveType: 'Leave Type',
        total: 'Total',
        startDate: 'Start Date',
        startTime: ' ',
        endDate: 'End Date',
        endTime: ' ',
        description: 'Description',
        replacement: 'Replacement',
        returnDate: 'Return Date',
        returnTime: 'Return Time',
        remaining_day: 'Remaining: {{day}} day',
        user:'Select User'
      },

      AdvanceCreateModal: {
        title: 'Request Advance',
        titleUpdate: 'Update Advance',
        currency: 'Currency Unit',
        amount: 'Amount',
        rejectReason: 'Reject Reason',
        approvalStatus: 'Approval Status',
      },
      Reports: {
        title: 'Reports',
      },
      Departments: {
        id: 'ID',
        title: 'Departments',
        department_name: 'Department',
        department_tr: 'Department Name Turkish',
        department_en: 'Department Name English',
        department_create_success: 'Department created.',
        department_edit_success: 'Department updated.',
        department_delete_success: 'Department deleted.',
        department_create_fail: `Department couldn't create!`,
        department_edit_fail: `Department couldn't update!`,
        department_delete_fail: `Department couldn't delete!`,
        createDepartment: 'Create Department',
          },
         department:{
        assignUsers: "Assign Users to Department",
        assign: "Assign",
        cancel: "Cancel",
        selectUser: "Select Users",
        assignSuccess: "Users successfully assigned to the department.",
        assignFailed: "Failed to assign users to the department."
         }, 
          branch: {
        assignUsers: "Assign Users to Branch",
        assign: "Assign",
        cancel: "Cancel",
        selectUsers: "Select Users",
        assignSuccess: "Users successfully assigned to the branch.",
        assignFailed: "Failed to assign users to the branch."
          },
          title: {
        assignUsers: "Assign Users to Title",
        assign: "Assign",
        cancel: "Cancel",
        selectUsers: "Select Users",
        assignSuccess: "Users successfully assigned to the title.",
        assignFailed: "Failed to assign users to the title."
          },
      Titles: {
        id: 'ID',
        title: 'Titles',
        title_name: 'Title',
        title_tr: 'Turkish Title',
        title_en: 'English Title',
        title_create_success: 'Title created.',
        title_edit_success: 'Title updated.',
        title_delete_success: 'Title deleted.',
        title_create_fail: `Title couldn't create!`,
        title_edit_fail: `Title couldn't update!`,
        title_delete_fail: `Title couldn't delete!`,
        createTitle: 'Create Title',
      },

      Leaves: {
        title: 'Leaves',
      },
      Expenses: {
        title: 'Expenses',
      },
      Salaries: {
        title: 'Salaries',
      },
      Settings: {
        title: 'Settings',
      },

      Events: {
        title: 'Events',
      },
      Roles: {
        role_name: 'Role Name',
      },
      Tables: {
        action: 'Action',
      },
      gender: {
        man: 'man',
        woman: 'woman',
      },
      addEventButton: 'Add Event',
      addEventModalTitle: 'Add Event',
      titleRequiredMessage: 'Title is required',
      titleLabel: 'Title',
      dateLabel: 'Date',
      dateRangeRequiredMessage: 'Date range is required',
      addButton: 'Add',
      holidaySelectionWarning: 'Holiday selection is disabled for previous years',
      todayButton: 'Today',
      backButton: 'Back',
      nextButton: 'Next',
      monthButton: 'Month',
      weekButton: 'Week',
      dayButton: 'Day',

      Register: {
        title: 'Register',
        PlanInformation: 'Plan Information',
        CompanyInformation: 'Company Information',
        Payment: 'Payment',
        Previous: 'Previous',
        Next: 'Next',
        Submit: 'Submit',
        PersonalCount: 'Personal Count',
        RecurrenceCount: 'Recurrence Count',
        BusinessName: 'Business Name',
        TaxNumber: 'Tax Number',
        TaxOffice: 'Tax Office',
        Firstname: 'First Name',
        Surname: 'Surname',
        Email: 'Email',
        PhoneNumber: 'Phone Number',
        IdentityNumber: 'Identity Number',
        Address: 'Address',
        ZipCode: 'Zip Code',
        City: 'City',
        District: 'District',
        CardHolderName: 'Card Holder Name',
        CardNumber: 'Card Number',
        ExpireDate: 'Expire Date',
        CVCNumber: 'CVC Number',
        RegisterConsumerCard: 'Register Card',
        PersonalCountisrequired: 'Personal count is required',
        BusinessNameisrequired: 'Business name is required',
        TaxNumberisrequired: 'Tax number is required',
        TaxOfficeisrequired: 'Tax office is required',
        Firstnameisrequired: 'First name is required',
        Surnameisrequired: 'Surname is required',
        Emailisrequired: 'Email is required',
        PhoneNumberisrequired: 'Phone number is required',
        IdentityNumberisrequired: 'Identity number is required',
        Addressisrequired: 'Address is required',
        ZipCodeisrequired: 'Zip code is required',
        Cityisrequired: 'City is required',
        Districtisrequired: 'District is required',
        CardHolderNameisrequired: 'Card holder name is required',
        CardNumberisrequired: 'Card number is required',
        CVCNumberisrequired: 'CVC number is required',
        successTitle: 'Successful',
        successMessage: 'You have successfully registered.',
        errorTitle: 'Unsuccessful',
        errorMessage: 'You could not register',
      },
      CompanyAggreement: {
        modalTitle: 'Company Aggreement',
      },
      Leave: {
        days: '{{days}} days',
      },

      Confirm: {
        itemName: 'Employee Name and Surname',
        terminationReason: 'Termination Reason:',
        departure_date: 'Departure Date:',
        terminationExplanation: 'Termination Explanation:',
        uploadFile: 'Upload File',
        user_delete: 'User Delete',
      },
      DepartureReason: {
        DeleteDepartureReason: 'Delete Departure Reason',
        AddDepartureReason: 'Add Departure Reason',
        DepartureReasonTR: 'Departure Reason (TR)',
        DepartureReasonEN: 'Departure Reason (EN)',
        areyousure: 'Are you sure you want to delete this item?',
        DepartureReasonsSettings: 'Departure Reasons Settings',
        Add: 'Add',
        listofDeparture:
          'List of departure reason options for your company. You can add new departure reason options to the list.',
      },
    },
  },
  tr: {
    translation: {
      errorCode: {
        ERROR_ADVANCE_NOT_EDITABLE: {
          title: 'Bu avans işleme alınmıştır',
          message: 'İşleme alınan avans silinemez ',
        },
      },
      notificationSuccess: {
        advanceDelete: {
          message: 'Silme Başarılı',
          description: 'Avans başarıyla silindi.',
        },
        AdvanceCreate: {
          successTitle: 'Avans Talebi Başarıyla Oluşturuldu',
        },
        AdvanceUpdate: {
          successTitle: 'Avans Talebi Başarıyla Güncellendi',
        },
        success:"Başarılı",
        error:"Başarısız"
      },
      global: {
        created_at: 'Oluşturulma Tarihi',
      },
      SignIn: {
        title: 'Giriş Yap',
        heading: 'Hoşgeldiniz',
        username: 'Kullanıcı Adı',
        password: 'Şifre',
        passwordError:
          'Şifre en az bir büyük harf, bir küçük harf, bir rakam içermeli ve en az 8 karakter uzunluğunda olmalıdır',
      },
      ForgotPassword: {
        title: 'Şifremi Unuttum',
        email: 'Email',
      },
      Dashboard: {
        title: 'Anasayfa',
        welcome: 'Hoşgeldiniz!',
        eventCalendar: 'Şirket Yıl Dönümleri',
        publicHolidays: 'Tatil Günleri',
        upcomingBirthdays: 'Yaklaşan Doğum Günleri',
        leaveRequests: 'Onaylanan İzinler',
        leaveRequestsApproved: 'Onay Bekleyen İzinler',
      },
      Workers: {
        createPersonal: 'Çalışan Ekle',
        showAll: 'Tüm Kullanıcıları Göster',
        showActive: 'Aktif Kullanıcıları Göster',
        showInactive: 'Aktif Olmayan Kullanıcıları Göster',
        title: 'Çalışanlar',
        status:"Durumu",
        department:"Departman",
        title_name:"Unvanı",
        branch:"Şube",
        copyPhone:"Telefonu Kopyala",
        showAllTitle:"Tüm Unvanları Göster",
        showAllDepartmant:"Tüm Departmanları Göster",
        showAllBranch:"Tüm Şubeleri Göster",
        columns: {
          id: 'Id',
          fullName: 'Ad Soyad',
          title: 'Ünvan',
          email: 'Email',
          phone: 'Telefon',
        },
        createPersonalModal: {
          first_name: 'Ad',
          last_name: 'Soyad',
          email: 'Email',
          phone: 'Telefon',
          hire_date: 'İşe Giriş Tarihi',
          department_id: 'Departman Id',
          job_id: 'İş Id',
          cancel: 'İptal',
          create: 'Oluştur',
        },
        career: {
          title: 'Kariyer',
          salary_info: 'Maaş Bilgileri',
          salary: 'Maaş',
          salary_aggreement_date: 'Maaş Anlaşma Tarihi',
          salary_status: 'Maaş Durumu',
        },
      },
      LeaveModal: {
        title: 'İzin Talep Et',
        leaveType: 'İzin Türü',
        total: 'Toplam',
        startDate: 'Başlangıç Tarihi',
        startTime: ' ',
        endDate: 'Bitiş Tarihi',
        endTime: ' ',
        description: 'Açıklama',
        replacement: 'Yerine bakacak kişi',
        returnDate: 'Dönüş Tarihi',
        returnTime: 'Dönüş Saati',
        remaining_day: 'Kalan: {{day}} gün',
        user:'Kullanıcı Seçin'
      },
      AdvanceCreateModal: {
        titleCreate: 'Avans Talep Et',
        titleUpdate: 'Talebi Güncelle',
        currency: 'Param Birimi',
        amount: 'Tutar',
        rejectReason: 'Reddetme Nedeniniz',
        approvalStatus: 'Onay Durumu',
      },
      Reports: {
        title: 'Raporlar',
      },
      Departments: {
        id: 'ID',
        title: 'Departmanlar',
        department_name: 'Departman Adı',
        department_tr: 'Departman Adı Türkçe',
        department_en: 'Departman Adı İngilizce',
        manager: 'Yönetici',
        manager_first_name: 'Yönetici Adı',
        manager_last_name: 'Yönetici Soyadı',
        manager_email: 'Yönetici Email',
        manager_phone_number: 'Yönetici Telefon Numarası',
        manager_work_phonenumber: 'Yönetici İş Telefon Numarası',
        manager_work_email: 'Yönetici İş Email',
        department_create_success: 'Departman oluşturuldu.',
        department_edit_success: 'Departman güncellendi.',
        department_delete_success: 'Departman silindi.',
        department_create_fail: 'Departman oluşturulamadı!',
        department_edit_fail: 'Departman güncellenemedi!',
        department_delete_fail: 'Departman silinemedi!',
        createDepartment: 'Departman Ekle',
        assignUsers:"Departmana Çalışan Ata",
        assign:"Ata",
        cancel:"İptal",
        selectUsers:"Seçilen Kullanıcılar",
        assignSuccess:"Çalışanlar departmana başarıyla atandı.",
        assignFailed:"Çalışanlar departmana atanamadı."

      },
      department:{
        assignUsers:"Departmana Çalışan Ata",
        assign:"Ata",
        cancel:"İptal",
        selectUsers:"Seçilen Kullanıcılar",
        assignSuccess:"Çalışanlar departmana başarıyla atandı.",
        assignFailed:"Çalışanlar departmana atanamadı."
      },
      branch:{
        assignUsers:"Şubeye Çalışan Ata",
        assign:"Ata",
        cancel:"İptal",
        selectUsers:"Seçilen Kullanıcılar",
        assignSuccess:"Çalışanlar şubeye başarıyla atandı.",
        assignFailed:"Çalışanlar şubeye atanamadı."
      },
      title:{
        assignUsers:"Unvana Çalışan Ata",
        assign:"Ata",
        cancel:"İptal",
        selectUsers:"Seçilen Kullanıcılar",
        assignSuccess:"Çalışanlar unvana başarıyla atandı.",
        assignFailed:"Çalışanlar unvana atanamadı."
      },
      Branches: {
        id: 'ID',
        title: 'Şubeler',
        name: 'Şube',
        address: 'Şube Adresi',
        phone_number: 'Şube Numarası',
        email: 'Şube emaili',
        branch_create_success: 'Şube oluşturuldu.',
        branch_edit_success: 'Şube güncellendi.',
        branch_delete_success: 'Şube silindi.',
        branch_create_fail: 'Şube oluşturulamadı!',
        branch_edit_fail: 'Şube güncellenemedi!',
        branch_delete_fail: 'Şube silinemedi!',
        createBranch: 'Şube Oluştur',
        assignUsers:"Şubeye Çalışan Ata",
        assign:"Ata",
        cancel:"İptal",
        selectUsers:"Seçilen Kullanıcılar",
        assignSuccess:"Çalışanlar şubeye başarıyla atandı.",
        assignFailed:"Çalışanlar şubeye atanamadı."
      },
      Titles: {
        id: 'ID',
        title: 'Ünvanlar',
        title_name: 'Ünvan Adı',
        title_tr: 'Türkçe Ünvan',
        title_en: 'İngilizce Ünvan',
        title_create_success: 'Ünvan oluşturuldu.',
        title_edit_success: 'Ünvan güncellendi.',
        title_delete_success: 'Ünvan silindi.',
        title_create_fail: 'Ünvan oluşturulamadı!',
        title_edit_fail: 'Ünvan güncellenemedi!',
        title_delete_fail: 'Ünvan silinemedi!',
        createTitle: 'Ünvan Oluştur',
      },
      Leaves: {
        title: 'İzinler',
      },
      Expenses: {
        title: 'Masraflar',
        name: 'Masraf Adı',
        description: 'Masraf Açıklaması',
        status: 'Durum',
        decrease_expense_item: 'Masraf Kalemi Çıkar',
        increase_expense_item: 'Masraf Kalemi Ekle',
        expense_type: 'Masraf Tipi',
        expense_amount: 'Masraf Miktarı',
        expense_currency: 'Masraf Para Birimi',
        expense_name_placeholder: 'Masraf Adı Giriniz',
        expense_description_placeholder: 'Masraf Açıklaması Giriniz',
        expense_type_placeholder: 'Masraf Tipi Seçiniz',
        expense_amount_placeholder: 'Masraf Miktarı Giriniz',
        expense_currency_placeholder: 'Masraf Para Birmi Seçiniz',
      },
      Settings: {
        title: 'Ayarlar',
        create_departure_reason: 'Ayrılma Nedeni Oluşturma',
        reason_tr: 'Ayrılma Nedeni(TR)',
        reason_en: 'Ayrılma Nedeni(EN)',
        edit_departure_reason: 'Ayrılma Nedeni Düzenleme',
        confirm_delete: 'Silmek İstiyor musunuz ?',
      },
      DepartureReasons: {
        title: 'Ayrılma Nedeni',
        id: 'ID',
        departure_reason: 'Ayrılma Nedenleri',
        createDepartureReason: 'Ayrılma Nedeni Oluştur',
      },

      Events: {
        title: 'Eventler',
      },
      Roles: {
        role_modal_title: 'Rol Oluşturma',
        createRole: 'Rol Oluştur',
        title: 'Roller',
        role_id: 'ID',
        role_name: 'Rol Adı',
        role_tr_name: 'Rol TR Adı',
        role_en_name: 'Rol EN Adı',
        users_screen: 'Kullanıcı Ekranı',
        roles_screen: 'Roller Ekranı',
        leaves_screen: 'İzinler Ekranı',
        select_users_screens: 'Kullanıcı ekranlarını seçin',
        select_roles_screens: 'Roller ekranlarını seçin',
        select_leaves_screens: 'İzinler ekranlarını seçin',
        role_edit_success: 'Rol başarıyla düzenlendi.',
        role_delete_success: 'Rol başarıyla silindi.',
        role_create_success: 'Rol başarıyla oluşturuldu.',
        role_delete_confirm: 'silinecek. Emin misiniz?',
        permission_view: 'Görüntüleme',
        permission_edit: 'Editleme',
        permission_delete: 'Silme',
        permission_create: 'Oluşturma',
        salary_screen: 'Maaş Ekranı',
        department_screen: 'Departman Ekranı',
        setting_screen: 'Ayarlar Ekranı',
        title_screen: 'Unvan Ekranı',
        branch_screen: 'Şube Ekranı',
        expense_screen: 'Masraf Ekranı',
        advance_screen: 'Avans Ekranı',
        select_salary_screen: 'Maaş Ekranı izni giriniz...',
        select_department_screen: 'Departman Ekranı izni giriniz...',
        select_setting_screen: 'Ayarlar Ekranı izni giriniz..',
        select_title_screen: 'Unvan Ekranı izni giriniz..',
        select_branch_screen: 'Şube Ekranı izni giriniz..',
        select_expense_screen: 'Masraf Ekranı izni giriniz..',
        select_users_screen: 'Kullanıcı Ekranı izni giriniz..',
        select_roles_screen: 'Role Ekranı izni giriniz..',
        select_leaves_screen: 'İzin Ekranı izni giriniz..',
        select_advance_screen: 'Avans Ekranı izni giriniz..',
      },
      users_screen: 'Kullanıcı Ekranı',
      roles_screen: 'Roller Ekranı',
      leaves_screen: 'İzinler Ekranı',
      salary_screen: 'Maaşlar Ekranı',
      department_screen: 'Departman Ekranı',
      setting_screen: 'Ayarlar Ekranı',
      title_screen: 'Unvan Ekranı',
      branch_screen: 'Şube Ekranı',
      expense_screen: 'Masraf Ekranı',
      advance_screen: 'Avans Ekranı',
      Tables: {
        action: 'İşlem',
      },

      gender: {
        man: 'Erkek',
        woman: 'Kadın',
      },
      ResetPassword: {
        password: 'Şifre',
        confirmPassword: 'Şifreyi Tekrarla',
        submit: 'Şifreyi Sıfırla',
      },
      addEventButton: 'Etkinlik Ekle',
      addEventModalTitle: 'Etkinlik Ekle',
      titleRequiredMessage: 'Başlık zorunlu',
      titleLabel: 'Başlık',
      dateLabel: 'Tarih',
      dateRangeRequiredMessage: 'Tarih aralığı zorunlu',
      addButton: 'Ekle',
      holidaySelectionWarning: 'Tatil günü event ekleyemezsiniz',
      backButton: 'Önceki',
      todayButton: 'Bugün',
      nextButton: 'Sonraki',
      monthButton: 'Ay',
      weekButton: 'Hafta',
      dayButton: 'Gün',

      Register: {
        Plan: 'Plan',
        title: 'Kayıt',
        PlanInformation: 'Plan Bilgisi',
        CompanyInformation: 'Şirket Bilgisi',
        Payment: 'Ödeme',
        Previous: 'Önceki',
        Next: 'Sonraki',
        Submit: 'Gönder',
        PersonalCount: 'Çalışan Sayısı',
        RecurrenceCount: 'Abonelik Süresi',
        BusinessName: 'Şirket Adı',
        TaxNumber: 'Vergi Numarası',
        TaxOffice: 'Vergi Dairesi',
        Firstname: 'İsim',
        Surname: 'Soyisim',
        Email: 'E-posta',
        PhoneNumber: 'Telefon Numarası',
        IdentityNumber: 'Kimlik Numarası',
        Address: 'Adres',
        ZipCode: 'Posta Kodu',
        City: 'Şehir',
        District: 'İlçe',
        CardHolderName: 'Kart Sahibi Adı',
        CardNumber: 'Kart Numarası',
        ExpireDate: 'Son Kullanma Tarihi',
        CVCNumber: 'CVC Numarası',
        RegisterConsumerCard: 'Kartı Kaydet',
        PersonalCountisrequired: 'Kişi sayısı gerekli',
        BusinessNameisrequired: 'Şirket Adını giriniz..',
        TaxNumberisrequired: 'Vergi Numarasını giriniz..',
        TaxOfficeisrequired: 'Vergi Dairesini giriniz..',
        Firstnameisrequired: 'İsminizi giriniz..',
        Surnameisrequired: 'Soyismini giriniz..',
        Emailisrequired: 'example@gmail.com',
        IdentityNumberisrequired: "Tc No'nuzu giriniz..",
        Addressisrequired: 'Adresinizi giriniz..',
        ZipCodeisrequired: 'Posta Kodunu giriniz..',
        Cityisrequired: 'Şehir giriniz..',
        Districtisrequired: 'İlçe giriniz..',
        CardHolderNameisrequired: 'Kart Sahibinin Adı Soyadı giriniz..',
        CardNumberisrequired: 'Kart Numarasını giriniz..',
        CVCNumberisrequired: 'CVC numaranızı giriniz..',
        successTitle: 'Başarılı',
        successMessage: 'Başarıyla Kayıt Oldunuz.',
        errorTitle: 'Başarısız',
        errorMessage: 'Kayıt Olamadınız!',
        PerPerson: 'Kişi Başına',
      },
      CompanyAggreement: {
        modalTitle: 'Şirket Anlaşması',
        confirmButton: 'Onayla',
        aggreementText: 'Lütfen aşağıdaki KVKK metnini okuyun ve onaylayın.',
        aggreementConfirmText: 'KVKK metnini okudum ve kabul ediyorum.',
        uploadText: 'Lütfen şirket sözleşmemizi indirip imza atın ve buraya yükleyin.',
        uploadDescription: 'Buraya tıklayın ya da dosyayı sürükleyip bırakın.',
      },
      Confirm: {
        itemName: 'Çalışan Adı ve Soyadı ',
        terminationReason: 'Fesih nedeni : ',
        departure_date: 'Ayrılış tarihi : ',
        terminationExplanation: 'Fesih Açıklama : ',
        uploadFile: 'Yüklenecek Dosya',
        user_delete: 'İşten Çıkartma',
        confirmationTitle: 'Emin Misiniz ?',
        confirmationMessage: 'Çalışanı işten çıkarmak istediğinize emin misiniz ?',
        confirm: 'Tamam',
      },
      DepartureReason: {
        DeleteDepartureReason: 'Ayrılma Nedene Silme',
        AddDepartureReason: 'Ayrılma Nedeni Ekleme',
        DepartureReasonTR: 'Ayrılma Nedeni(TR)',
        DepartureReasonEN: 'Ayrılma Nedeni(EN)',
        areyousure: 'Silmek istediğinize emin misiniz ?',
        DepartureReasonsSettings: 'Ayrılma Nedenleri',
        Add: 'Ekle',
        listofDeparture:
          'Şirketinize ait masraf talebi seçenekleri listesi. Listeye yeni masraf talebi seçenekleri ekleyebilirsiniz.',
      },

      Leave: {
        days: '{{days}} gün',
        duration: 'Süre',
        requestDate: 'Talep Tarihi',
        status: 'Durum',
      },
      Salaries: {
        title: 'Maaşlar',
        name: 'Ad Soyad',
        salary_price: 'Maaş',
        salary_currency: 'Para Birimi',
        salary_agreement_date: 'Başlama Tarihi',
        department_name: 'Departman Adı',
        title_name: 'Unvan Adı',
        effective_date: 'Bitiş Tarihi',
        edit_salary: 'Maaş Güncellemesi',
        required: 'Bu Alan Zorunludur!',
        currency: 'Para Birimi',
        delete_success:"Kullanıcı Başarıyla İşten Çıkarıldı."
      },
      User:{
        assign:'Çalışanları Ekle'
    }
    },
  },
};

import React, { useState } from 'react';
import { Card, Col, Button, Typography, Divider, Tooltip, Flex } from 'antd';
import { CopyOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import CitizenshipModal from './CitizenshipModal';
import { useTranslation } from 'react-i18next';
import DataCard from '../DataCard/DataCard';

const { Text } = Typography;

const Citizenship = ({ data }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <DataCard
            title="Vatandaşlık"
            Extra={true}
            data={data}
            Modal={CitizenshipModal}
        >
            <Col span={12}>
                <Text strong>Doğum Tarihi:</Text>{' '}
                {data?.birth_date &&
                    moment(data.birth_date).format('DD.MM.YYYY')}
            </Col>
            <Col span={12}>
            <Text strong>Cinsiyet:</Text>{' '}
            {data?.gender ? t(`gender.${data.gender}`) : '—'}
        </Col>

            <Col
                span={12}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Text strong>Uyruğu:{data?.nationality || '—'}</Text>
            </Col>
            <Col
                span={12}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Text strong>Kimlik Numarası:{data?.citizen_id || '—'}</Text>{' '}
                {data?.citizen_id && (
                    <Tooltip title="Kopyala">
                        <Button
                            type="link"
                            icon={<CopyOutlined />}
                            onClick={() => {
                                navigator.clipboard.writeText(data.citizen_id);
                            }}
                        />
                    </Tooltip>
                )}
            </Col>
        </DataCard>
    );
};

export default Citizenship;

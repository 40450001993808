import React, { useContext } from 'react';
import { DownOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Dropdown, Flex, Space, theme } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../../store/auth';
import MainContext from '../MainLayout/MainContext';

const MainHeader = ({ children, enableCreate, enableCreateTitle, handleCreate }) => {
    const userId = useSelector((state) => state.auth.userId);
    const dispatch = useDispatch();
    const handleSignOut = () => {
        // #todo signOut fonksiyonunu buraya ekleyin
        dispatch(signOut());
    };
    const items = [
        {
            label: <Link to={`/worker/${userId}`}>Profilimi Görüntüle</Link>,
            key: 'my-profile'
        },
        {
            label: (
                <Link onClick={handleSignOut} to="/sign-in">
                    Sign Out
                </Link>
            ),
            key: 'signout'
        }
    ];
    const {
        token: { colorBgContainer }
    } = theme.useToken();

    const { setCollapsed, collapsed } = useContext(MainContext);
    return (
        <Header
            style={{
                padding: 0,
                background: colorBgContainer,
                margin: -20,
                marginBottom: 20
            }}
        >
            <Flex justify="space-between" align="center">
                <Flex>
                    <Button
                        type="text"
                        icon={
                            <LeftOutlined
                                className={`arrow ${collapsed ? 'collapsedButton' : ''}`}
                            />
                        }
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64
                        }}
                    />
                    {children}
                </Flex>
                <Flex gap={16}>
                    {enableCreate ? (<Button onClick={handleCreate}>{enableCreateTitle}</Button>): null}
                <Dropdown
                    menu={{ items }}
                    trigger={['click']}
                    placement="bottomRight"
                >
                    <Button
                        type="primary"
                        style={{ marginRight: '15px' }}
                        onClick={(e) => e.preventDefault()}
                    >
                        <Space>Hesabım</Space>
                    </Button>
                </Dropdown>
                </Flex>
            </Flex>
        </Header>
    );
};

export default MainHeader;

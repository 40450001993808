import React, { useEffect } from 'react';
import { Button, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import Sider from 'antd/es/layout/Sider';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../../store/auth';
import routes from '../../router/routess';
import { useLazyQuery } from '../../hooks/useAxios';

const Sidebar = ({ collapsed, setCollapsed, location }) => {
  const { data, loading, refetch } = useLazyQuery('/business/settings');
  useEffect(() => {
    const refetchSettings = async () => {
      try {
        const response = await refetch();
        console.log(response, 'data1234');
      } catch (error) {
        console.error('Error fetching screens:', error);
      }
    };
    refetchSettings();
  }, [refetch]);
  const dispatch = useDispatch();
  const handleSignOut = () => {
    // #todo signOut fonksiyonunu buraya ekleyin
    dispatch(signOut());
  };
  const { screen_permissions } = useSelector((state) => state.auth);
  console.log(screen_permissions, 'screens');

  const menuItems = routes
    .filter(
      (route) => route.sidebar

      // #todo backende screenler eklenince burası yorumdan cıkacak
      // && screen_permissions?.find(e => e.screen_name === route.name + '_screen' && e.permissions.includes('preview'))
    )
    .map((route) => {
      return {
        key: route.path,
        icon: location.pathname === route.path && route.activeIcon ? route.activeIcon : route.icon || <UserOutlined />,
        label: <Link to={route.path}>{route.title}</Link>,
      };
    });

  const items = [
    ...menuItems,
    {
      type: 'divider',
      style: { margin: 0, border: 0, flexGrow: 1, visibility: 'hidden' },
    },
    // {
    //     key: '/logout', label: <Link onClick={
    //         handleSignOut
    //     }>
    //         Sign Out
    //     </Link>, icon: <UserOutlined />
    // }
  ];

  return (
    <Sider
      theme="light"
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
      }}
      breakpoint="md"
      collapsed={collapsed}
      onBreakpoint={(e) => setCollapsed(e)}
      trigger={
        <Button
          type="primary"
          icon={<LogoutOutlined />}
          style={{
            width: '100%',
            borderRadius: 0,
            marginTop: 'auto',
          }}
          // Sign out işlevini buraya ekleyin
          onClick={handleSignOut}
        >
          {!collapsed && 'Sign Out'}
        </Button>
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'inherit',
        }}
      >
        {data?.data?.logo ? (
          <div style={{ margin: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Link to={'/'}>
              <img src={`https://node207217-hr-skript.de1.mageteknik.com/${data?.data?.logo}`} width="70px" />
            </Link>
          </div>
        ) : (
          <div
            style={{
              height: 32,
              background: 'rgb(255 0 0 / 50%)',
              margin: '16px',
              borderRadius: '4px',
              width: 'calc(100% - 32px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Logo
          </div>
        )}

        <Menu
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            paddingBlockEnd: '1rem',
          }}
          theme="light"
          mode="inline"
          defaultSelectedKeys={location.pathname}
          items={items}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;

import React, { useEffect, useState } from 'react';
import { Button, Modal, Tree } from 'antd';
import { useQuery } from '../../hooks/useAxios';
import { TreeNode } from 'antd/es/tree-select';
import OrgTreeComponent, { useTree } from 'react-drag-hierarchy-tree';
import MyModal from '../../components/MyModal/MyModal';
import useHierarchy from './useHierarchy';

const Hierarchy = () => {
    const { gData, loading, onClick, onDrop, treeRef, isOpen, setIsOpen, modalText, onCancel, onOk } = useHierarchy();

    return (
        <>
            <div>
                <button onClick={onClick}>close/open</button>
                {gData?.children && !loading && <OrgTreeComponent horizontal data={gData} onDrop={onDrop} ref={treeRef} />}
                <MyModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    title="Uyarı"
                    okText="Evet"
                    cancelText="Hayır"
                    footer={(_, { OkBtn }) => (
                        <>
                            <Button
                                onClick={() => {
                                    onCancel();
                                }}
                            >Hayır</Button>
                            <OkBtn></OkBtn>
                        </>
                    )}
                    handleSubmit={onOk}
                >
                    <p>{modalText}</p>
                </MyModal>
            </div>
        </>
    );
};
export default Hierarchy;

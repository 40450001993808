import React from 'react';
import MyTable from '../../components/MyTable';
import style from './ReportDetail.module.css';

const ReportDetail = ({ id }) => {
    const data = {
        '7f8b7aee556bd9d2ab0441087a8a50d5': {
            index: 1,
            firstName: 'Ali',
            lastName: 'Akan',
            idNumber: '83791199635',
            gender: '-',
            age: 50,
            status: 'Aktif',
            personCardUrl:
                'https://app.kolayik.com/employee/7f8b7aee556bd9d2ab0441087a8a50d5'
        },
        f14585e2e67c110e3954b8fa105021f6: {
            index: 2,
            firstName: 'Barlas',
            lastName: 'Sinanoğlu',
            idNumber: '00933986479',
            gender: '-',
            age: 41,
            status: 'Aktif',
            personCardUrl:
                'https://app.kolayik.com/employee/f14585e2e67c110e3954b8fa105021f6'
        },
        '57b742028ac2021fbcc3598f78b2241b': {
            index: 3,
            firstName: 'Berkay',
            lastName: 'Balcı',
            idNumber: '74886021947',
            gender: '-',
            age: 29,
            status: 'Aktif',
            personCardUrl:
                'https://app.kolayik.com/employee/57b742028ac2021fbcc3598f78b2241b'
        },
        '168ed4edcb7389ec0f9917f0c1465dcc': {
            index: 4,
            firstName: 'Ebru',
            lastName: 'Biçer',
            idNumber: '04837980593',
            gender: '-',
            age: 32,
            status: 'Aktif',
            personCardUrl:
                'https://app.kolayik.com/employee/168ed4edcb7389ec0f9917f0c1465dcc'
        },
        '7a1f236bfa2e1a309565c6371a3d6754': {
            index: 5,
            firstName: 'Ebru',
            lastName: 'Sözeri',
            idNumber: '16571646323',
            gender: '-',
            age: 51,
            status: 'Aktif',
            personCardUrl:
                'https://app.kolayik.com/employee/7a1f236bfa2e1a309565c6371a3d6754'
        },
        '079df9457bd0db3d8755b1293a08cd79': {
            index: 6,
            firstName: 'Kerem',
            lastName: 'Erginsoy',
            idNumber: '97341706047',
            gender: '-',
            age: 58,
            status: 'Aktif',
            personCardUrl:
                'https://app.kolayik.com/employee/079df9457bd0db3d8755b1293a08cd79'
        },
        da8c4ae5830ce5f581d29b20af71f9c1: {
            index: 7,
            firstName: 'Rüzgar',
            lastName: 'Körmükçü',
            idNumber: '32859705093',
            gender: '-',
            age: 53,
            status: 'Aktif',
            personCardUrl:
                'https://app.kolayik.com/employee/da8c4ae5830ce5f581d29b20af71f9c1'
        },
        '1d6155ef60a79004839bf73b81644609': {
            index: 8,
            firstName: 'Şahnur',
            lastName: 'Süleymanoğlu',
            idNumber: '42042003375',
            gender: '-',
            age: 55,
            status: 'Aktif',
            personCardUrl:
                'https://app.kolayik.com/employee/1d6155ef60a79004839bf73b81644609'
        },
        e2994dcc8aa9d63c70b6036274700a8e: {
            index: 9,
            firstName: 'Savaş',
            lastName: 'Çalışkan',
            idNumber: '-',
            gender: '-',
            age: '-',
            status: 'Aktif',
            personCardUrl:
                'https://app.kolayik.com/employee/e2994dcc8aa9d63c70b6036274700a8e'
        },
        '403764e2dce0912b7c2e07ea47e44856': {
            index: 10,
            firstName: 'Türker',
            lastName: 'Akbulut',
            idNumber: '95772207412',
            gender: '-',
            age: 58,
            status: 'Aktif',
            personCardUrl:
                'https://app.kolayik.com/employee/403764e2dce0912b7c2e07ea47e44856'
        },
        '8779e550c249029adb54a981285c72f0': {
            index: 11,
            firstName: 'Ümran',
            lastName: 'Tokgöz',
            idNumber: '31547778557',
            gender: '-',
            age: 61,
            status: 'Aktif',
            personCardUrl:
                'https://app.kolayik.com/employee/8779e550c249029adb54a981285c72f0'
        }
    };

    const tableHeader = {
        index: 'Sıra',
        firstName: 'Ad',
        lastName: 'Soyad',
        idNumber: 'Kimlik Numarası',
        gender: 'Cinsiyet',
        age: 'Yaş',
        status: 'Çalışma Durumu'
    };

    return (
        <MyTable
            enableColumnFilter={false}
            enablePagination={false}
            isOffline={true}
            searchEnabled={false}
            initialData={Object.keys(data).map((key, index) => ({ ...data[key], key: index }))}
            initialColumns={Object.keys(tableHeader).map((key) => ({
                title: tableHeader[key],
                dataIndex: key,
                key: key
            }))}
            className={style.table}
        />
    );
};

export default ReportDetail;

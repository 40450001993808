import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const GeneralRoute = ({ children }) => {
    const isAuthenticated = useSelector((state) => state.auth.isSigned)
    if (isAuthenticated) {
        return <Navigate to="/" />
    }
    return <>{children}</>
}

export default GeneralRoute
import { LeftOutlined } from '@ant-design/icons';
import { Button, Layout, theme } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './MainLayout.css';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';
import MainContext from './MainContext';

const MainLayout = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const {
        token: { colorBgContainer }
    } = theme.useToken();

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sidebar
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                location={location}
            />
            <Layout
                className="site-layout"
                style={{
                    marginLeft: collapsed ? 80 : 200,
                    transition: 'all 0.2s'
                }}
            >
                <MainContext.Provider value={{ collapsed, setCollapsed }}>
                    <Content
                        style={{
                            padding: 20
                        }}
                    >
                        {children}
                    </Content>
                </MainContext.Provider>
                <Footer />
            </Layout>
        </Layout>
    );
};

export default MainLayout;

import React from 'react';
import { Col } from 'antd';
import MyInput from '../../../../components/MyInput';
import { z } from 'zod';
import MySelect from '../../../../components/MySelect';
import ChangeModal from '../ChangeModal/ChangeModal';

const schema = z.object({
    education_status: z.string().optional().nullable(),
    education_level: z.number().int().optional().nullable(),
    educational_institution: z.string().optional().nullable()
});

const EducationModal = ({ isOpen, setIsOpen, data }) => {
    return (
        <ChangeModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            data={data}
            schema={schema}
            success="Eğitim bilgileriniz başarıyla güncellendi"
            error="Eğitim bilgileriniz güncellenirken bir hata oluştu"
            title="Eğitim"
        >
            <Col span={12}>
                <MySelect
                    name="education_status"
                    label="Eğitim Durumu"
                    options={[
                        { label: 'Lise', value: 'Lise' },
                        { label: 'Üniversite', value: 'Üniversite' },
                        {label:'Ortaöğretim', value:'Ortaöğretim'}
                    ]}
                />
            </Col>
            <Col span={12}>
                <MySelect
                    label="Tamamlanan En Yüksek Eğitim Seviyesi"
                    name="education_level"
                    options={[
                        { label: 'Yok', value: 0 },
                        { label: '1. Derece', value: 1 },
                        { label: '2. Derece', value: 2 },
                        { label: '3. Derece', value: 3 }
                    ]}
                />
            </Col>

            <Col span={12}>
                <MyInput
                    name="educational_institution"
                    label="Tamamlanan Son Eğitim Kurumu"
                />
            </Col>
        </ChangeModal>
    );
};

export default EducationModal;

import React, { useState } from 'react';
import MyModal from '../MyModal';
import MySelect from '../MySelect';
import { Col } from 'antd';
import { useForm } from 'react-hook-form';
import FilterUnitModal from '../FilterUnitModal/FilterUnitModal';
import MyInput from '../MyInput';
import { FilterOutlined } from '@ant-design/icons';

const CreateReportModal = ({ data, type, open, setOpen }) => {
    const [filterUnitModalOpen, setFilterUnitModalOpen] = useState(false);
    const form = useForm();
    const { watch, reset } = form;

    return (
        <>
            <FilterUnitModal
                open={filterUnitModalOpen}
                setOpen={setFilterUnitModalOpen}
            />
            <MyModal
                title="Rapor Oluştur"
                isOpen={open}
                setIsOpen={setOpen}
                handleSubmit={() => { }}
                confirmLoading={false}
                width={640}
                afterOpenChange={(bool) => !bool && reset()}
                formMethods={form}
            >
                <Col span={12}>
                    <MySelect
                        placeholder="Rapor Seçiniz"
                        name="reportType"
                        options={data.map((item) => ({
                            value: item.id,
                            label: item.tag
                        }))}
                    />
                </Col>
                <Col span={12}>
                    <MyInput
                        placeholder="Birim Seçiniz"
                        name="filterUnits"
                        onFocus={(e) => {
                            e.target.blur();
                            setFilterUnitModalOpen(true);
                        }}
                        suffix={
                            <FilterOutlined
                                style={{ color: 'rgba(0,0,0,.45)' }}
                            />
                        }
                    />
                </Col>
                {type !== 'staffReports' &&
                    !!watch('reportType') &&
                    data.find((item) => item.id === watch('reportType'))
                        ?.filterByEmploymentStartDate && (
                        <>
                            <Col span={12}>
                                <MySelect
                                    placeholder="Ay"
                                    name="month"
                                    options={[]}
                                />
                            </Col>
                            <Col span={12}>
                                <MySelect
                                    placeholder="Yıl"
                                    name="year"
                                    options={[]}
                                />
                            </Col>
                        </>
                    )}
            </MyModal>
        </>
    );
};

export default CreateReportModal;

var checkTcNum = function (value) {
    if (!value) return true;
    value = value.toString();

    // 11 haneli olup olmadığını kontrol et
    var isEleven = /^[0-9]{11}$/.test(value);

    // İlk 10 basamağın toplamını hesapla
    var totalX = 0;
    for (var i = 0; i < 10; i++) {
        totalX += Number(value.substr(i, 1));
    }

    // 10. basamağın kontrolünü yap
    var isRuleX = totalX % 10 === Number(value.substr(10, 1));

    var totalY1 = 0;
    var totalY2 = 0;

    // 1, 3, 5, 7 ve 9. basamakları topla
    for (var i = 0; i < 9; i += 2) {
        totalY1 += Number(value.substr(i, 1));
    }

    // 2, 4, 6, 8 ve 10. basamakları topla
    for (var i = 1; i < 9; i += 2) {
        totalY2 += Number(value.substr(i, 1));
    }

    // 9. basamağın kontrolünü yap
    var isRuleY = ((totalY1 * 7) - totalY2) % 10 === Number(value.substr(9, 1));

    console.log(isEleven, isRuleX, isRuleY);

    return isEleven && isRuleX && isRuleY;
};

export { checkTcNum };
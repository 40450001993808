import { EditOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Header } from '../../components/Header';
import MyTable from '../../components/MyTable';
import useRefreshTable from '../../components/MyTable/useRefreshTable';
import SalariesModal from '../../components/SalariesModal';


const Salaries = () => {
    const { t } = useTranslation();
    const { refreshTable, handleRefreshTable } = useRefreshTable();
    const [isOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [status, setStatus] = useState('new');


    const initialColumns = [
        {
            title: <Trans i18nKey="Salaries.name" />,
            dataIndex: 'name',
            key: 'name',
            render: (_, data) => `${data.firstname} ${data.lastname}`,
        },
        {
            title: <Trans i18nKey="Salaries.salary_price" />,
            dataIndex: 'salary_price',
            key: 'salary_price'
        },
        {
            title: <Trans i18nKey="Salaries.salary_currency" />,
            dataIndex: 'salary_currency',
            key: 'salary_currency'
        },
        {
            title: <Trans i18nKey="Salaries.salary_agreement_date" />,
            dataIndex: 'salary_agreement_date',
            key: 'salary_agreement_date'
        },
        {
            title: <Trans i18nKey="Salaries.department_name" />,
            dataIndex: 'department_name',
            key: 'department_name'
        },
        {
            title: <Trans i18nKey="Salaries.title_name" />,
            dataIndex: 'title_name',
            key: 'title_name'
        },
        {
            title: <Trans i18nKey="Tables.action" />,
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                        <Button type="primary" onClick={() => handleEdit(data)}>
                            <EditOutlined />
                        </Button>
                    
                </Space>
            )
        }
    ];

    const handleEdit = useCallback((data) => {
        setModalData(data);
        setStatus('edit');
        setIsOpen(true);
    }, []);


    // const handleCreate = useCallback(() => {
    //     setStatus('new');
    //     setIsOpen(true);
    // }, []);

    useEffect(() => {
        if (!isOpen) {
            setModalData({});
        }
    }, [isOpen]);

    return (
        <>
            <Header enableCreate={true} enableCreateTitle={"Maaş Ekle"} />
            <title>{t('Salaries.title')}</title>
            <div>
                <SalariesModal
                    open={isOpen}
                    setIsOpen={setIsOpen}
                    modalData={modalData}
                    status={status}
                    handleRefreshTable={handleRefreshTable}
                />
                    {/* <Button type="primary" onClick={handleCreate}>
                        {t('Salaries.createSalary')}
                    </Button> */}
                
                <MyTable
                    initialColumns={initialColumns}
                    url="/user/salary/business/latest"
                    refreshTable={refreshTable}
                    pagination={{ position: ['bottomCenter'] }}
                />
            </div>
        </>
    );
};

export default Salaries;

import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { Button, Flex, Radio, Tabs, Space, notification, Tag } from 'antd';
import MyTable from '../../components/MyTable';
import { Header } from '../../components/Header';
import BonusCreateModal from './modal/BonusCreateModal';
import useRefreshTable from '../../components/MyTable/useRefreshTable';
import { DeleteOutlined, EditOutlined, CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useLazyDelete } from '../../hooks/useAxios';
import { t } from 'i18next';
import BonusUpdateModal from './modal/BonusUpdateModal';
import { useSelector } from 'react-redux';
import BonusUpdateStatusModal from './modal/UpdateBonusStatusModal/BonusUpdateStatusModal';

const getFilter = (status) => {
	switch (status) {
		case 'upcoming':
			return {
				status: '?sort_by=start_date_desc'
			};
		case 'waiting':
			return {
				status: '?status=1'
			};
		case 'approved':
			return {
				status: '?status=2'
			};
		case 'rejected':
			return {
				status: '?status=3'
			};
		default:
			return {
				status: ''
			};
	}
}

const Bonus = () => {
	const [open, setOpen] = useState(false);
	const [selectedRow, setSelectedRow] = useState(null);
	const [status, setStatus] = useState('upcoming')
	const filterTabs = getFilter(status);
	const updateAdvanceModalRef = useRef();
	const updateBonusStatusModalRef = useRef();
	const { refreshTable, handleRefreshTable } = useRefreshTable();
	const { screen_permissions } = useSelector((state) => state.auth);
	const userPermissions =
		screen_permissions.find(
			(screen) => screen.screen_name === 'advance_screen'
		)?.permissions || [];

	const { refetch: deleteAdvance, loading: deleteAdvanceLoading } = useLazyDelete('/user/advance/delete', {
		notificationOptions: {
			successMessage: t(`notificationSuccess.advanceDelete.message`),
			successDescription: t(`notificationSuccess.advanceDelete.description`)
		}
	});

	const deleteAdvanceSubmit = async (advanceId) => {
		try {
			const result = await deleteAdvance(advanceId);
			if (result?.result === 'success') {
				handleRefreshTable();
			}
		} catch (error) {
			console.log(error)
			console.error('Form delete error:', error);
			notification.error({
				message: 'Error',
				description: 'An error occurred while deleting'
			});
		}
	};

	const columns = [
		{
			title: 'Durum',
			dataIndex: 'status',
			key: 'status',
			render: (text) => <span>{{ 1:  <Tag color={'warning'}>{'Bekliyor'}</Tag>, 2: <Tag color={'success'}>{'Onaylandı'}</Tag>, 3:<Tag color={'error'}>{'Reddedildi'}</Tag> }[text]}</span>
		},
		{
			title: 'Ad - Soyad',
			dataIndex: 'firstname',
			key: 'firstname',
			render: (text, record) => (
				<span>
					{record.firstname} {record.lastname}
				</span>
			)
		},
		{
			title: 'Email',
			dataIndex: 'work_email',
			key: 'work_email',
			render: (text) => (<span> {text} </span>)
		},
		{
			title: 'Tutar',
			dataIndex: 'amount',
			key: 'amount',
			render: (text) => <span>{text}</span>
		},
		{
			title: 'Para Birimi',
			dataIndex: 'currency',
			key: 'currency',
			render: (text) => <span>{text}</span>
		},
		{
			title: 'IBAN',
			dataIndex: 'iban',
			key: 'iban',
			render: (text) => <span>{text}</span>
		},
		...(status === 'rejected' || status === "upcoming"
			? [
				{
				  title: 'Reddetme Nedeni',
				  dataIndex: 'reject_reason',
				  key: 'reject_reason',
				  render: (text) => <span>{text}</span>,
				},
			  ]
			: []),
		...((userPermissions.includes('edit') || userPermissions.includes('delete')) &&
			(status !== 'approved' && status !== 'rejected')
			? [
				{
				title: <Trans i18nKey="Tables.action" />,
				key: 'action',
				render: (_, record) => {
					return (
						record?.status === 1 &&
						<Flex gap="middle">
							{userPermissions.includes('delete')&&
								<Space size="middle">
								<Button danger type='primary' onClick={() => deleteAdvanceSubmit(record?.id)}>
									<DeleteOutlined />
								</Button>
							</Space>
							}
						
						<React.Fragment>
						<Space size="middle">
								<Button type='primary' onClick={() => updateAdvanceModalRef.current.openModal(record)}>
									<EditOutlined />
								</Button>
							</Space>
							{userPermissions.includes('edit')&&
							<React.Fragment>
									<Space size="middle">
								<Button  type='primary' onClick={() => updateBonusStatusModalRef.current.openModal(record)}>
									<CheckCircleOutlined />
								</Button>
							</Space>
						
							</React.Fragment>
						}
						</React.Fragment>
						</Flex>

					);
				}
			}] : [])
	];


	const tabItems = [
		{
			key: 'advance',
			label: 'Avans',
			children: (
				<>
					<Radio.Group buttonStyle='solid' value={status} onChange={(e) => setStatus(e.target.value)}>
						<Radio.Button value="upcoming">
							Yaklaşanlar
						</Radio.Button>
						<Radio.Button value="waiting">
							Onay Bekleyenler
						</Radio.Button>
						<Radio.Button value="approved">
							Onaylananlar
						</Radio.Button>
						<Radio.Button value="rejected">
							Reddedilenler
						</Radio.Button>
					</Radio.Group>
					<Flex wrap justify="space-between">
						<h1>Avanslar</h1>
					</Flex>
					<BonusCreateModal open={open} setOpen={setOpen} handleRefreshTable={handleRefreshTable} />
					<BonusUpdateModal ref={updateAdvanceModalRef} handleRefreshTable={handleRefreshTable} />
					<BonusUpdateStatusModal ref={updateBonusStatusModalRef} handleRefreshTable={handleRefreshTable} />
					<MyTable
						url={'user/advance/list' + filterTabs.status}
						initialColumns={columns}
						refreshTable={refreshTable}
						onRow={(record) => {
							return {
								onClick: () => {
									setSelectedRow(record);
								}
							};
						}}
					/>
				</>
			)
		},
		{
			key: 'status',
			title: 'Durum'
		}
	];
	return (
		<>
			<Header enableCreate={userPermissions.includes('create')? true : false} enableCreateTitle="Avans Talebi Oluştur" handleCreate={()=>setOpen(!open)} />
			<Tabs defaultValue={'advance'} items={tabItems} />
		</>
	);
};

export default Bonus;

import React, { useEffect } from 'react';
import MySelect from '../../components/MySelect';
import { useLazyQuery, useQuery } from '../../hooks/useAxios';
import MyModal from '../../components/MyModal';
import { Col } from 'antd';

const ChangeRoleModal = ({
    isOpen,
    setIsOpen,
    form,
    confirmLoading,
    onSubmit,
    handleSubmit
}) => {
    const { data, error, loading, called, refetch } = useLazyQuery('/global/roles');

    useEffect(() => {
        if (isOpen && !called) {
            refetch();
        }
    }
        , [isOpen, called, refetch]);

    const roles = data?.data || [];

    return (
        <MyModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={'Erişim tipini değiştir'}
            handleSubmit={handleSubmit(onSubmit)}
            confirmLoading={confirmLoading}
            formMethods={form}
        >
            <Col span={24}>
                <MySelect
                    name="role_id"
                    label="Erişim tipi"
                    options={roles.map((item) => ({
                        value: item.role_id,
                        label: item.role_tr_name
                    }))}
                />
            </Col>
        </MyModal>
    );
};

export default ChangeRoleModal;

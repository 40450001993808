import { UploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Flex, Form, Row, Typography, Upload } from 'antd';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import MyInput from '../../../components/MyInput';
import { useLazyQuery } from '../../../hooks/useAxios';
import '../styles.css';

const { Title } = Typography;

const Rules = () => {
  const { data, loading, refetch } = useLazyQuery('/business/settings');
  useEffect(() => {
    const refetchSettings = async () => {
      try {
        const response = await refetch();
        console.log(response, 'data1234');
      } catch (error) {
        console.error('Error fetching screens:', error);
      }
    };
    refetchSettings();
  }, [refetch]);

  const form = useForm({
    defaultValues: {
      // logo: data?.data.logo || '',
      logo: '',
      leaves_approve_manager: data?.data?.leaves_approve_manager || false,
      leaves_create_manager: data?.data?.leaves_create_manager || false,
      expenses_approve_manager: data?.data?.expenses_approve_manager || false,
    },
  });

  const { handleSubmit, reset, control } = form;

  useEffect(() => {
    if (data) {
      reset({
        // logo: data?.data.logo || '',
        logo: '',
        leaves_approve_manager: data?.data?.leaves_approve_manager || false,
        leaves_create_manager: data?.data?.leaves_create_manager || false,
        expenses_approve_manager: data?.data?.expenses_approve_manager || false,
      });
    }
  }, [data, reset]);

  const {
    data: updateData,
    error,
    loading: loadingUpdate,
    refetch: refetchUpdate,
  } = useLazyQuery('/business/settings/update', {
    method: 'PATCH',
  });

  const onSubmit = async (formData) => {
    console.log(formData, 'dataSubmit');
    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key === 'logo' && formData.logo) {
          formDataToSend.append('logo', formData.logo.fileList[0].originFileObj);
        } else {
          formDataToSend.append(key, formData[key]);
        }
      });

      const responseA = await refetchUpdate({ data: formDataToSend });
      console.log(responseA, 'responseA');
      // notification.success({
      //   message: 'Personal Created',
      // });
    } catch (error) {
      console.log('error', error);
    }
  };

  if (loading || !data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Card title="Company Rules" className="custom-card">
        <FormProvider {...form}>
          <Form onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <MyInput name="expenses_approve_manager" label="Expenses Approve by Manager" type="switch" />
              </Col>
              <Col span={24}>
                <MyInput name="leaves_approve_manager" label="Leaves Approve by Manager" type="switch" />
              </Col>
              <Col span={24}>
                <MyInput name="leaves_create_manager" label="Leaves Create Manager" type="switch" />
              </Col>
              {/* <Col span={24}>
                <Flex align='center' gap={16}>
                  Logo
                  <img src={`https://node207217-hr-skript.de1.mageteknik.com/${data?.data?.logo}`} width="70px" />
                  <Controller
                    name="logo"
                    control={control}
                    render={({ field }) => (
                      <Upload
                        {...field}
                        beforeUpload={() => false}
                        maxCount={1}
                        onChange={(info) => {
                          field.onChange(info.fileList[0] ? info : null);
                        }}
                        fileList={field.value ? [field.value.file] : []}
                      >
                        <Button icon={<UploadOutlined />}>Upload Logo</Button>
                      </Upload>
                    )}
                  />
                </Flex>
              </Col> */}
              <Col span={24}>
                <Flex justify="space-between">
                  <Button htmlType="submit">Güncelle</Button>
                </Flex>
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </Card>
    </>
  );
};

export default Rules;

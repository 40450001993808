import React from 'react';
import MyModal from '../MyModal';
import MySelect from '../MySelect';
import { useForm } from 'react-hook-form';
import { Col } from 'antd';

export default function FilterUnitModal({ open = false, setOpen = () => { } }) {
    const form = useForm();
    return (
        <MyModal
            title="Birim Filtrele"
            isOpen={open}
            setIsOpen={setOpen}
            handleSubmit={() => { }}
            confirmLoading={false}
            width={480}
            formMethods={form}
        >
            <Col span={24} >
                <MySelect label="Şirket" name="company" options={[]} />
            </Col>
            <Col span={24} >
                <MySelect label="Şube" name="branch" options={[]} />
            </Col>
            <Col span={24} >
                <MySelect label="Departman" name="department" options={[]} />
            </Col>
            <Col span={24} >
                <MySelect label="Birim" name="unit" options={[]} />
            </Col>
        </MyModal>

    );
}

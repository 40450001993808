import { MoneyCollectFilled, MoneyCollectOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import Bonus from '../pages/Bonus';
import Branches from '../pages/Branches';
import Dashboard from '../pages/Dashboard';
import Departments from '../pages/Departments';
import Events from '../pages/Events/Events';
import Expenses from '../pages/Expenses';
import ForgotPassword from '../pages/ForgotPassword';
import Hierarchy from '../pages/Hierarchy/Hiearchy';
import Jobs from '../pages/Jobs';
import TeamLeaders from '../pages/Leaders';
import Leaves from '../pages/Leaves';
import { default as Members, default as TeamMembers } from '../pages/Members';
import Projects from '../pages/Projects';
import Register from '../pages/Register';
import Reports from '../pages/Reports';
import ResetPassword from '../pages/ResetPassword';
import Roles from '../pages/Roles';
import Salaries from '../pages/Salaries';
import BirthdaySetting from '../pages/Settings/BirtdaySettings';
import CompanyInfo from '../pages/Settings/CompanyInfo';
import DepartureReason from '../pages/Settings/DepartureReason';
import ExpenseSettings from '../pages/Settings/ExpenseSettings';
import LeaveSettings from '../pages/Settings/LeaveSettings';
import Rules from '../pages/Settings/Rules';
import Settings from '../pages/Settings/Settings';
import SubscriptionInfo from '../pages/Settings/SubscriptionInfo/SubscriptionInfo ';
import SystemSettings from '../pages/Settings/SystemSettings';
import SignIn from '../pages/SignIn';
import Skills from '../pages/Skills';
import Team from '../pages/Team/Team';
import Teams from '../pages/Teams';
import Titles from '../pages/Titles';
import Worker from '../pages/Worker';
import Workers from '../pages/Workers';

const routes = [
  {
    name: 'Dashboard',
    path: '/',
    element: <Dashboard />,
    sidebar: true,
    title: <Trans i18nKey="Dashboard.title" />,
  },
  {
    name: 'Sign In',
    path: '/sign-in',
    element: <SignIn />,
    auth: false,
  },
  {
    name: 'Forgot Password',
    path: '/forgot-password',
    element: <ForgotPassword />,
    auth: false,
  },
  {
    name: 'Reset Password',
    path: '/auth/reset-password/:token',
    element: <ResetPassword />,
    auth: false,
  },
  {
    name: 'users',
    path: '/workers',
    element: <Workers />,
    sidebar: true,
    title: <Trans i18nKey="Workers.title" />,
  },
  // {
  //   name: 'departments',
  //   path: '/departments',
  //   element: <Departments />,
  //   sidebar: true,
  //   title: <Trans i18nKey="Departments.title" />,
  // },
  // {
  //   name: 'branches',
  //   path: '/branches',
  //   element: <Branches />,
  //   sidebar: true,
  //   title: <Trans i18nKey="Branches.title" />,
  // },
  // {
  //   name: 'titles',
  //   path: '/titles',
  //   element: <Titles />,
  //   sidebar: true,
  //   title: <Trans i18nKey="Titles.title" />,
  // },
  {
    name: 'atildin',
    path: '/atildin',
    element: <> atıldın </>,
  },
  {
    name: 'leaves',
    path: '/leaves',
    element: <Leaves />,
    sidebar: true,
    title: <Trans i18nKey="Leaves.title" />,
  },
  {
    name: 'Expenses',
    path: '/expenses',
    element: <Expenses />,
    sidebar: true,
    title: <Trans i18nKey="Expenses.title" />,
  },
  {
    name: 'Bonus',
    path: '/bonus',
    element: <Bonus />,
    sidebar: true,
    title: 'Avanslar',
    icon: <MoneyCollectOutlined />,
    activeIcon: <MoneyCollectFilled />,
  },
  {
    name: 'Salaries',
    path: '/salaries',
    element: <Salaries />,
    sidebar: true,
    title: <Trans i18nKey="Salaries.title" />,
  },
  {
    name: 'Teams',
    path: '/teams',
    element: <Teams />,
    sidebar: false,
    title: 'Teams',
  },
  {
    name: 'Team',
    path: '/team/:id',
    element: <Team />,
  },
  {
    name: 'Team Leaders',
    path: '/team-leaders',
    element: <TeamLeaders />,
    sidebar: false,
    title: 'Team Leaders',
  },
  {
    name: 'Projects',
    path: '/projects',
    element: <Projects />,
    sidebar: false,
    title: 'Projects',
  },
  {
    name: 'Skills',
    path: '/skills',
    element: <Skills />,
    sidebar: false,
    title: 'Skills',
  },
  {
    name: 'Team Members',
    path: '/team-members',
    element: <TeamMembers />,
    sidebar: false,
    title: 'Team Members',
  },
  {
    name: 'Jobs',
    path: '/jobs',
    element: <Jobs />,
    sidebar: false,
    title: 'Jobs',
  },
  {
    name: 'Members',
    path: '/members',
    element: <Members />,
    sidebar: false,
    title: 'Members',
  },
  {
    name: 'Worker',
    path: '/worker/:id',
    element: <Worker />,
  },
  {
    name: '404',
    path: '*',
    element: (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link to="/">
            <Button component type="primary">
              Back Home
            </Button>
          </Link>
        }
      />
    ),
    auth: false,
  },
  {
    name: 'reports',
    path: '/reports',
    element: <Reports />,
    sidebar: true,
    title: <Trans i18nKey="Reports.title" />,
  },
  {
    name: 'reports',
    path: '/reports/:id',
    element: <Reports />,
  },
  {
    name: 'Settings',
    path: '/settings',
    element: <Settings />,
    sidebar: true,
    title: <Trans i18nKey="Settings.title" />,
    children: [
      {
        name: 'Company Info',
        path: 'company-info',
        element: <CompanyInfo />,
      },
      {
        name: 'Rules',
        path: 'rules',
        element: <Rules />,
      },
      {
        name: 'Subscription Info',
        path: 'subscription-info',
        element: <SubscriptionInfo />,
      },
      // {
      //   name: 'Expense Settings',
      //   path: 'expense-settings',
      //   element: <ExpenseSettings />,
      // },
      {
        name: 'Departure Reason',
        path: 'system-settings/departure-reason',
        element: <DepartureReason />,
      },
      {
        name: 'Departments',
        path: 'departments',
        element: <Departments />,
      },
      {
        name: 'branches',
        path: 'branches',
        element: <Branches />,
      },
      {
        name: 'titles',
        path: 'titles',
        element: <Titles />,
      },
      {
        name: 'roles',
        path: 'roles',
        element: <Roles />,
      },
      {
        name: 'System Settings',
        path: 'system-settings',
        element: <SystemSettings />,
      },
      {
        name: 'Leave Settings',
        path: 'system-settings/leave-settings',
        element: <LeaveSettings />,
      },
      {
        name: 'Expense Settings',
        path: 'system-settings/expense-settings',
        element: <ExpenseSettings />,
      },
      {
        name: 'Birthday Settings',
        path: 'system-settings/birthday-settings',
        element: <BirthdaySetting />,
      },
    ],
  },
  {
    name: 'Events',
    path: '/events',
    element: <Events />,
    sidebar: true,
    title: <Trans i18nKey="Events.title" />,
  },
  {
    name: 'Register',
    path: '/register',
    element: <Register />,
    sidebar: false,
    title: <Trans i18nKey="Register" />,
    auth: false,
  },
  {
    name: 'Hierarchy',
    path: '/hierarchy',
    element: <Hierarchy />,
    sidebar: true,
    title: 'Hierarchy',
  },
];

export default routes;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Checkbox, Button, message, Upload, Flex } from 'antd';

import { InboxOutlined, FilePdfOutlined } from '@ant-design/icons';

import styles from './Aggreement.module.css';
import { useLazyQuery } from '../../hooks/useAxios';
import AggreementTextSample from './AggreementTextSample';

const AgreementModal = ({ visible, onSubmit, userId }) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);

  const [aggrementType, setAggreementType] = useState('text');
  const { Dragger } = Upload;
  const props = {
    name: 'file',
    multiple: false,
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        setIsChecked(true);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const { data: editData, refetch: setAgreement } = useLazyQuery(`/user/edit/${userId}`, {
    method: 'put',
  });

  const handleSubmit = async () => {
    const response = await setAgreement({
      data: {
        company_agreement: true,
      },
    });

    if (response?.result === 'success') {
      onSubmit();
    } else {
      console.error(response?.result?.message);
    }
  };

  return (
    <Modal
      title={t('CompanyAggreement.modalTitle')}
      visible={visible}
      closable={false}
      footer={[
        <Button key="submit" type="primary" onClick={handleSubmit} disabled={!isChecked}>
          {t('CompanyAggreement.confirmButton')}
        </Button>,
      ]}
    >
      {aggrementType === 'text' ? (
        <div>
          <p>{t('CompanyAggreement.aggreementText')}</p>
          <div className={styles.aggreementContainer}>
            <AggreementTextSample />
          </div>
          <Checkbox style={{ marginTop: '10px' }} onChange={handleCheckboxChange}>
            {t('CompanyAggreement.aggreementConfirmText')}
          </Checkbox>
        </div>
      ) : (
        <>
          <Flex align="center" justify="space-between" gap={[5, 5]}>
            <p>{t('CompanyAggreement.uploadText')}</p>
            <Button>
              <FilePdfOutlined style={{ fontSize: '18px' }} />
            </Button>
          </Flex>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('CompanyAggreement.uploadDescription')}</p>
          </Dragger>
        </>
      )}
    </Modal>
  );
};

export default AgreementModal;

import React from 'react';
import { Card, Col, Row, Typography } from 'antd';

import '../styles.css';
import companyInfoData from './companyInfo.json';

const { Title, Text } = Typography;

const CompanyInfo = () => {
  return (
    <Card title="Company Information" className="custom-card">
      <Row gutter={[16, 16]} className="info-list">
        <Col span={12}>
          <Title level={5}>Contact Email:</Title>
          <Text>{companyInfoData.contact_email}</Text>
        </Col>
        <Col span={12}>
          <Title level={5}>Contact Name:</Title>
          <Text>{companyInfoData.contact_name}</Text>
        </Col>
        <Col span={12}>
          <Title level={5}>Contact Phone:</Title>
          <Text>{companyInfoData.contact_phone}</Text>
        </Col>
        <Col span={12}>
          <Title level={5}>Total Workers:</Title>
          <Text>{companyInfoData.total_worker}</Text>
        </Col>
        <Col span={12}>
          <Title level={5}>Default Branch:</Title>
          <Text>{companyInfoData.default_branch}</Text>
        </Col>
        <Col span={12}>
          <Title level={5}>Salary Payment Date:</Title>
          <Text>{companyInfoData.salary_payment_date}</Text>
        </Col>
      </Row>
    </Card>
  );
};

export default CompanyInfo;

import React from 'react'
//Hooks
import { useLazyQuery } from '../../../../hooks/useAxios';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

//Component
import { App, Col } from 'antd';
import MyInput from '../../../../components/MyInput'
import MyModal from '../../../../components/MyModal'
import MySelect from '../../../../components/MySelect';


function BonusCreateModal(props) {
	const { t } = useTranslation();
	const { notification } = App.useApp();
	const form = useForm();

	const { handleSubmit, getValues, setValue, clearErrors } = form;

	const { refetch: createAdvance, loading: createAdvanceLoading } = useLazyQuery('/user/create-advance', {
		method: 'post'
	});


	const onSubmit = async (data) => {
		try {
			await createAdvance({
				data: {
					currency: data.currency,
					amount: data.amount
				}
			});
		} catch (error) {
			console.error('Form submission error:', error);
			notification.error({
				message: 'Error',
				description: 'An error occurred while updating the user role'
			});
		} finally {
			props.setOpen(false);
			props.handleRefreshTable();
		}
	};

	return (
		<MyModal
			isOpen={props.open}
			setIsOpen={props.setOpen}
			title={t('advanceCreateModal.title')}
			handleSubmit={handleSubmit(onSubmit)}
			confirmLoading={createAdvanceLoading}
			formMethods={form}
		>
			<Col span={12}>
				<MyInput
					name="amount"
					label={t('advanceCreateModal.amount')}
					type="number"
				/>
			</Col>

			<Col span={12}>
				<MySelect
					name="currency"
					label={t('advanceCreateModal.currency')}
					options={[
						{ value: 'TRY', label: 'TRY' },
						{ value: 'USD', label: 'USD' }
					]}
					placeholder="Select a currency"
					allowClear
				/>
			</Col>


		</MyModal>
	)
}

export default BonusCreateModal
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '../../hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import { setUserId, setModalVisible } from '../../store/auth';
import AgreementModal from '../AgreementModal/AgreementModal';
import {jwtDecode} from 'jwt-decode';

const WithAgreementCheck = (WrappedComponent) => {
  const TokenCheck = (props) => {
    const dispatch = useDispatch();
    const { userId, isModalVisible } = useSelector((state) => state.auth);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const { refetch } = useLazyQuery(`/user/${userId}`);

    const handleAgreementSubmit = () => {
      dispatch(setModalVisible(false));
    };

    const checkToken = async (token) => {
      try {
        const decodedToken = jwtDecode(token);
        const id = decodedToken.id;

        if (id) {
          dispatch(setUserId(id));
          const response = await refetch();

          if (response?.data?.results[0]?.company_agreement === false) {
            dispatch(setModalVisible(true));
          } else {
            console.log('Company agreement is not false');
          }
        } else {
          console.error('User ID not found in token');
          navigate('/sign-in');
        }
      } catch (error) {
        console.error('Error decoding token or fetching user data:', error);
        navigate('/sign-in');
      }
    };

    useEffect(() => {
      if (token) {
        checkToken(token);
      }
    }, [token]);

    useEffect(() => {
      if (userId) {
        refetch();
      }
    }, [userId, refetch]);

    return (
      <>
        <WrappedComponent {...props} />
        <AgreementModal
          visible={isModalVisible}
          onSubmit={handleAgreementSubmit}
          userId={userId}
        />
      </>
    );
  };

  return TokenCheck;
};

export default WithAgreementCheck;

import { FileSearchOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Select, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import CreatePersonalModal from '../../components/CreatePersonalModal/CreatePersonalModal';
import { Header } from '../../components/Header';
import MySelect from '../../components/MySelect';
import MyTable from '../../components/MyTable';
import useRefreshTable from '../../components/MyTable/useRefreshTable';
import WorkersModal from '../../components/WorkersModal';
import { useLazyQuery } from '../../hooks/useAxios';

const { Option } = Select;

const FiltrationForm = ({ filter, handleChangeFilter, departments, branches, titles }) => {
  return (
    <>
      <Col span={6}>
        <MySelect
          name="status"
          placeholder={<Trans i18nKey="Workers.status" />}
          options={[
            {
              label: <Trans i18nKey="Workers.showAll" />,
              value: 'all',
            },
            {
              label: <Trans i18nKey="Workers.showActive" />,
              value: 'true',
            },
            {
              label: <Trans i18nKey="Workers.showInactive" />,
              value: 'false',
            },
          ]}
         
        />
      </Col>
      <Col span={6}>
        <MySelect
          name="department_id"
          placeholder={<Trans i18nKey="Workers.department" />}
          options={[
            {
              label: <Trans i18nKey="Workers.showAllDepartmant" />,
              value: 'all',
            },
            ...departments.map((dep) => ({
            label: dep.department_name,
            value: dep.department_id,
          }))
          ]
            }
          onChange={(value) => handleChangeFilter('department_id', value)}
          value={filter.department}
        />
      
        
      </Col>
      <Col span={6}>
        <MySelect
          name="branch_id"
          placeholder={<Trans i18nKey="Workers.branch" />}
          options={
            [
              {
              
              label: <Trans i18nKey="Workers.showAllBranch" />,
              value: 'all',
            },
            ...branches.map((branch) => ({
            label: branch.name,
            value: branch.branch_id,
          }))
              
            ]
           }
          onChange={(value) => handleChangeFilter('branch_id', value)}
          value={filter.branch}
        />
      </Col>
      <Col span={6}>
        <MySelect
          name="user_title_id"
          placeholder={<Trans i18nKey="Workers.title_name" />}
          options={
            [
              {  
                label: <Trans i18nKey="Workers.showAllTitle" />,
                value: 'all',
              },
              ...titles.map((title) => ({
            label: title.title_name,
            value: title.user_title_id,
          }))

            ]
          }
          onChange={(value) => handleChangeFilter('user_title_id', value)}
          value={filter.title}
        />
      </Col>
    </>
  );
};

const Workers = () => {
  const { refreshTable, handleRefreshTable } = useRefreshTable();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [filter, setFilter] = useState('all');
  const [departmants, setDepartmants] = useState([]);
  const [titles, setTitles] = useState([]);
  const [branches, setBranches] = useState([]);

  const { data: getDepartmantsData, refetch: fetchGetDepartments } = useLazyQuery(`/global/departments`);
  const { data: getBranchesData, refetch: fetchGetBranches } = useLazyQuery(`/global/branches`);
  const { data: getTitlesData, refetch: fetchGetTitles } = useLazyQuery(`/global/titles`);

  useEffect(() => {
    const fetchData = async () => {
      await fetchGetDepartments();
      await fetchGetTitles();
      await fetchGetBranches();
    };
    fetchData();
  }, [fetchGetDepartments, fetchGetBranches, fetchGetTitles]);

  useEffect(() => {
    if (getDepartmantsData?.result === 'success') {
      setDepartmants(getDepartmantsData.data);
    }
    if (getBranchesData?.result === 'success') {
      setBranches(getBranchesData.data);
    }
    if (getTitlesData?.result === 'success') {
      setTitles(getTitlesData.data);
    }
  }, [getDepartmantsData, getBranchesData, getTitlesData]);

  const handleChangeFilter = (key, value) => {
    setFilter((prev) => ({
      ...prev,
      [key]: value,
    }));
    handleRefreshTable();
  };

  const initialColumns = [
    {
      title: <Trans i18nKey="Workers.columns.fullName" />,
      dataIndex: 'firstname',
      key: 'firstname',
      sorter: true,
      render: (text, record) => (
        <Link to={`/worker/${record.user_id}`}>
          <Avatar
            shape="square"
            style={{
              marginRight: 10,
            }}
          >
            {record?.firstname?.charAt(0) + record?.lastname?.charAt(0)}
          </Avatar>
          {record.firstname} {record?.lastname}
        </Link>
      ),
    },
    {
      title: <Trans i18nKey="Workers.columns.title" />,
      dataIndex: 'title_tr_name',
      key: 'title_tr_name',
    },
    {
      title: <Trans i18nKey="Workers.columns.email" />,
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: <Trans i18nKey="Workers.columns.phone" />,
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text, record) => (
        <Tooltip title={t("Workers.copyPhone")} placement={'left'}>
          <span onClick={() => navigator.clipboard.writeText(record.phone_number)}>{record.phone_number}</span>
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="Tables.action" />,
      key: 'action',
      render: (_, record) => {
        return (
          <Space size="middle">
            <Link to={`/worker/${record.user_id}`}>
              <Button type="primary">
                <FileSearchOutlined />
              </Button>
            </Link>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Header
        enableCreate={true}
        enableCreateTitle={t('Workers.createPersonal')}
        handleCreate={() => setCreateModalOpen(true)}
      />
      <title>{t('Workers.title')}</title>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <WorkersModal open={isOpen} />

          <CreatePersonalModal
            open={createModalOpen}
            setOpen={setCreateModalOpen}
            handleRefreshTable={handleRefreshTable}
          />
        </div>
      </div>

      {departmants.length > 0 && branches.length > 0 && titles.length > 0 ? (
        <MyTable
          initialColumns={initialColumns}
          refreshTable={refreshTable}
          url={
            filter === 'active'
              ? '/user/list?status=true'
              : filter === 'inactive'
              ? '/user/list?status=false'
              : '/user/list'
          }
          rowKey="user_id"
          showStatusFilter={filter}
          Filtration={
            <FiltrationForm
              filter={filter}
              handleChangeFilter={handleChangeFilter}
              departments={departmants}
              branches={branches}
              titles={titles}
            />
          }
          pagination={{ position: ['bottomCenter'] }}
          searchEnabled={true}
        />
      ) : (
        <div>Loading...</div> 
      )}
    </>
  );
};

export default Workers;

import { LoadingOutlined } from '@ant-design/icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { App } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { momentSchema } from '../../helpers/schema';
import { useLazyQuery, useQuery } from '../../hooks/useAxios';

const schema = z
    .object({
        reason_id: z
            .number({
                message: 'Leave type is required',
            })
            .int()
            .positive(),
        total: z.number({
            message: 'Total is required',
        }),
        startDate: momentSchema,
        endDate: momentSchema,
    }).refine(
        (data) => {
            const startDate = dayjs(data.startDate)
            const endDate = dayjs(data.endDate)
            console.log(
                startDate.isBefore(endDate), startDate.isSame(endDate), startDate, endDate
            )
            return startDate.isBefore(endDate) || startDate.isSame(endDate);
        },
        {
            message: 'End date must be after start date',
            path: ['endDate']
        }
    );


const useLeaveModal = ({ initialValues, userId, setOpen ,id,selectedUser}) => {
    const { notification } = App.useApp();
    const form = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            ...initialValues,
            total: 1,
        },
    });

    const { handleSubmit, getValues, setValue, clearErrors } = form;

    const calcData = (data) => {
        console.log(data)
        // setValue('total', data?.data?.requested_days);
    };

    const {
        data,
        error,
        loading: checkLoading,
        refetch: check,
        called,
    } = useLazyQuery('/leave/check', {
        method: 'post',
        onCompleted: (data) => {
            calcData(data);
        },
        onError: (error, data) => {

            notification.error({
                message: 'Error',
                description: error?.response?.data?.data?.error
            });
            calcData(data);
        },
    });
    if(selectedUser){
        userId= selectedUser.user_id
    }
    const { data: leaveTypeData } = useQuery(`/user/leaves/rights/${userId}`, {
        method: 'get',
    });

    const { refetch: createLeave, loading: createLeaveLoading } = useLazyQuery('/leave/create', {
        method: 'post',
    });
    const { refetch: createLeaveId, loading: createLeaveIdLoading } = useLazyQuery(`/leave/create/${userId}`, {
        method: 'post',
    });

    const options = leaveTypeData?.results?.map((item) => ({
        value: item.id,
        label: item.reason_tr,
        remaining_limit: item.allowed_time/24,
        ...item,
    }));

    const onSubmit = async (data) => {
        try {
            const result1 = await check({
                data: {
                    user_id: userId,
                    start_date: data.startDate.format('YYYY-MM-DD'),
                    end_date: data.endDate.format('YYYY-MM-DD'),
                    reason_id: data.reason_id,
                },
            });
            if (result1?.result === 'error') {
                return;
            }

            let result2;
            //userId String değer geldiği içi 2 eşittir.
            if (id == userId) {
                console.log("123")
                result2 = await createLeave({
                    data: {
                        start_date: data.startDate.format('YYYY-MM-DD'),
                        end_date: data.endDate.format('YYYY-MM-DD'),
                        reason_id: data.reason_id,
                        reason: data.description,
                    },
                });
            } else {
                result2 = await createLeaveId({
                    data: {
                        start_date: data.startDate.format('YYYY-MM-DD'),
                        end_date: data.endDate.format('YYYY-MM-DD'),
                        reason_id: data.reason_id,
                        reason: data.description,
                    },
                });
            }
    
            if (result2?.result === 'error') {
                return;
            }
    
            notification.success({
                message: 'Success',
                description: 'Leave request has been created successfully',
            });
            setOpen(false);
        } catch (error) {
            console.error('Form submission error:', error);
            notification.error({
                message: 'Error',
                description: 'An error occurred while creating the leave request',
            });
        }
    };
    const handleDateChange = (data) => {
        const { startDate, endDate, reason_id } = getValues();

        // 1. Gerekli Değişkenleri Tanımla
        if (!endDate || !startDate) return;

        check({
            data: {
                user_id: userId,
                start_date: startDate.format('YYYY-MM-DD'),
                end_date: endDate.format('YYYY-MM-DD'),
                reason_id: reason_id,
            },
        });

        return data;
    };

    let datePickerProps = {};
    let selectProps = {};
    let numberInputProps = {};

    if (createLeaveLoading || checkLoading) {
        datePickerProps = {
            suffixIcon: <LoadingOutlined />,
            disabled: true,
        };
        selectProps = {
            loading: true,
            disabled: true,
        };
        numberInputProps = {
            disabled: true,
        };
    }

    return {
        form,
        handleSubmit,
        handleDateChange,
        options,
        selectProps,
        numberInputProps,
        datePickerProps,
        createLeaveLoading,
        checkLoading,
        onSubmit,
    };
};

export default useLeaveModal;

import { Button, Radio, Space, Tabs, Tag, } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { FileSearchOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';
import { Header } from '../../components/Header';
import LeaveModal from '../../components/LeaveModal/LeaveModal';
import MyTable from '../../components/MyTable';
import useRefreshTable from '../../components/MyTable/useRefreshTable';
import apiLang from '../../helpers/apiLang';
import { useLazyQuery } from '../../hooks/useAxios';
import LeaveDetailModal from './LeaveDetailModal';


const getFilter = (status) => {
    switch (status) {
        case 'upcoming':
            return {
                status: '?sort_by=start_date_desc'
            };
        case 'waiting':
            return {
                status: '?status=1'
            };
        case 'approved':
            return {
                status: '?status=2'
            };
        case 'rejected':
            return {
                status: '?status=3'
            };
        default:
            return {
                status: ''
            };
    }
}

const Leaves = () => {
    const [open, setOpen] = useState(false);
    const [detailOpen, setDetailOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const { refreshTable, handleRefreshTable } = useRefreshTable();
    const [leaveOpen,setLeaveOpen] = useState(false);
    const [status, setStatus] = useState('upcoming')
    const filterTabs = getFilter(status);
    const leaves = true;

    const { userId } = useSelector((state) => state.auth);

    const { data: leaveApprove, refetch: fetchLeaveApprove } = useLazyQuery(`/leave/approve/${selectedId}`, {
        method: 'PATCH',
    });
    
    const handleModalSubmit = (approvalStatus,rejectReason) => {
        fetchLeaveApprove({
            data: { approval_status: approvalStatus,
                reject_reason:rejectReason
             },
        }).then(() => {
            handleRefreshTable();
            setDetailOpen(false);
        });
    };
    useEffect(() => {
        if (open && selectedId) {
            fetchLeaveApprove();
        }
    }, [fetchLeaveApprove, open, selectedId]);

    const commonColumns  = [
        {
            title: 'Durum',
            dataIndex: 'status',
            key: 'status',
            render: (text) => <span>{{ 1:  <Tag color={'warning'}>{'Bekliyor'}</Tag>, 2: <Tag color={'success'}>{'Onaylandı'}</Tag>, 3:<Tag color={'error'}>{'Reddedildi'}</Tag> }[text]}</span>
        },
        {
            title: 'Ad - Soyad',
            dataIndex: 'firstname',
            key: 'firstname',
            render: (text, record) => (
                <span>
                    {record.firstname} {record.lastname}
                </span>
            )
        },
        {
            title: 'Türü',
            dataIndex: apiLang('reason'),
            key: apiLang('reason'),
            render: (text) => <span>{text}</span>
        },
        {
            title: 'Gün Sayısı',
            dataIndex: 'requested_days',
            key: 'requested_days',
            render: (text) => <span>{text}</span>
        },
        {
            title: 'Başlangıç',
            dataIndex: 'start_date',
            key: 'start_date',
            render: (text,
                record
            ) => <span>
                    {
                        dayjs(text).format('DD MMMM YYYY')
                    }
                </span>
        },
        {
            title: 'Bitiş',
            dataIndex: 'end_date',
            key: 'end_date',
            render: (text,
                record
            ) => <span>
                    {
                        dayjs(text).format('DD MMMM YYYY')
                    }
                </span>
        },
        {
            title: 'İşlem Tarihi',
            dataIndex: 'last_action_at  ',
            key: 'last_action_at  ',
            render: (text,
                record
            ) => <span>
                    {
                        dayjs(text).format('DD MMMM YYYY')
                    }
                </span>
        },
        {
            title:'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="primary" onClick={() => handleActionClick(record)}>
                        <FileSearchOutlined />
                    </Button>
                </Space>
            )
        },
        
    ];
    
    const handleActionClick = (record) => {
        setSelectedRow(record);
        setDetailOpen(true);
        setSelectedId(record.id); 
    };

    const columns = status === 'rejected'
    ? [
        ...commonColumns,
        {
            title: 'Reddetme Nedeni',
            dataIndex: 'authorized_reject_reason',
            key: 'authorized_reject_reason',
            render: (text) => <span>{text}</span>,
        }
    ]
    : commonColumns;

    const tabItems = [
        {
            key: 'leave',
            label: 'İzin',
            children: (
                <>
                    <Radio.Group buttonStyle='solid' value={status} onChange={(e) => setStatus(e.target.value)}>
                        <Radio.Button value="upcoming">
                            Yaklaşanlar
                        </Radio.Button>
                        <Radio.Button value="waiting">
                            Onay Bekleyenler
                        </Radio.Button>
                        <Radio.Button value="approved">
                            Onaylananlar
                        </Radio.Button>
                        <Radio.Button value="rejected">
                            Reddedilenler
                        </Radio.Button>
                    </Radio.Group>
                    <LeaveDetailModal
                        open={detailOpen}
                        setOpen={setDetailOpen}
                        data={selectedRow}
                        onSubmit={handleModalSubmit}

                    />
                    <MyTable
                        url={'leave/list' + filterTabs.status}
                        initialColumns={columns}
                        refreshTable={refreshTable}
                    />
                </>
            )
        },
        {
            key: 'status',
            title: 'Durum'
        }
    ];

    return <>
       <Header
        enableCreateTitle="İzin Talebi Oluştur"
        enableCreate={()=>{}}
        handleCreate={() => setLeaveOpen(true)}
        />
        <LeaveModal open={leaveOpen} setOpen={setLeaveOpen} userId={userId} leaves={leaves}/>

        <Tabs defaultValue={'leave'} items={tabItems}
  

        ></Tabs>




        </>
        ;
};

export default Leaves;

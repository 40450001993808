import React, { useState } from 'react';
import { Card, Col, Button, Row } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const DataCard = ({ data, Modal, Extra = false, children, title }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Col span={24}>
                <Card
                    title={title}
                    style={{ flex: 1 }}
                    extra={
                        Extra && typeof Extra === 'boolean' ?
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => setIsOpen(true)}
                            >
                                Düzenle
                            </Button> : <Extra onClick={() => setIsOpen(true)} />
                    }
                >
                    <Row gutter={[16, 24]}>{children}</Row>
                </Card>
            </Col>
            {Modal && <Modal isOpen={isOpen} setIsOpen={setIsOpen} data={data} />}
        </>
    );
};

export default DataCard;

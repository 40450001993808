import { Form, Modal, Row } from 'antd';
import React from 'react';
import './MyModal.css'; // CSS dosyasını import ediyoruz
import { FormProvider } from 'react-hook-form';

const MyModal = ({
    isOpen,
    setIsOpen,
    title,
    children,
    handleSubmit,
    confirmLoading = false,
    width = 640,
    rowGap = 24,
    formMethods,
    isForm = true,
    onCancel,
    ...rest
}) => {
    const handleCancel = () => {
        setIsOpen(false);
    };
    return (
        <>
            <Modal
                handleCancel={handleCancel}
                title={title}
                open={isOpen}
                onCancel={(e, a) => {
                    onCancel && onCancel(e, a);
                    setIsOpen(false)
                }}
                onOk={handleSubmit}
                confirmLoading={confirmLoading}
                width={width}
                centered
                afterClose={(e) => isForm && formMethods?.reset()}
                {...rest}
            >
                {isForm ? (
                    <FormProvider {...formMethods}>
                        <Form onFinish={handleSubmit}>
                            <Row gutter={[16, rowGap]}>{children}</Row>
                        </Form>
                    </FormProvider>
                ) : (
                    children
                )}
            </Modal>
        </>
    );
};

export default MyModal;

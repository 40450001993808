import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { App, Col } from 'antd';
import { useLazyQuery } from '../../../../hooks/useAxios';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import MyInput from '../../../../components/MyInput'
import MyModal from '../../../../components/MyModal'
import MySelect from '../../../../components/MySelect';

const BonusUpdateModal = forwardRef((props, ref) => {

	const { t } = useTranslation();
  const [open, setOpen] = useState();
  const [id, setId] = useState();
	const { notification } = App.useApp();
	const form = useForm();
	const { handleSubmit, getValues, setValue, clearErrors, reset } = form;

	const { refetch: updateAdvance, loading: createAdvanceLoading } = useLazyQuery('/user/update-advance/' + id, { method: 'put' });

	useImperativeHandle(ref, () => ({

		openModal: (rowItem) => {
			setOpen(true);
      reset(rowItem);
      setId(rowItem?.id)
		},
		closeModal: () => {
			setOpen(false);
		}
	}));

	const onSubmit = async (data) => {
		try {
			await updateAdvance({
				data: {
					currency: data.currency,
					amount: data.amount
				}
			});
			notification.success({
				message: t('notificationSuccess.AdvanceUpdate.successTitle')
			});
			props.handleRefreshTable();
		} catch (error) {
			console.error('Form submission error:', error);
			notification.error({
				message: 'Error',
				description: t('advanceCreateModal.errorDescription')
			});
		} finally {
			setOpen(false);
		}
	};

	return (
		<MyModal
			isOpen={open}
			setIsOpen={setOpen}
			title={t('AdvanceCreateModal.titleUpdate')}
			handleSubmit={handleSubmit(onSubmit)}
			confirmLoading={createAdvanceLoading}
			formMethods={form}
		>
			<Col span={12}>
				<MyInput
					name="amount"
					label={t('advanceCreateModal.amount')}
					type="number"
				/>
			</Col>
			<Col span={12}>
				<MySelect
					name="currency"
					label={t('advanceCreateModal.currency')}
					options={[
						{ value: 'TRY', label: 'TRY' },
						{ value: 'USD', label: 'USD' }
					]}
					placeholder={t('advanceCreateModal.currencyPlaceholder')}
					allowClear
				/>
			</Col>
		</MyModal>
	)
});

export default BonusUpdateModal;

import React from 'react';
import { Col } from 'antd';
import { z } from 'zod';
import MySelect from '../../../../components/MySelect';
import ChangeModal from '../ChangeModal/ChangeModal';

const schema = z.object({
    marital_status: z.string().optional().nullable(),
    number_of_children: z.number().int().optional().nullable()
});

const FamilyModal = ({ isOpen, setIsOpen, data }) => {
    return (
        <ChangeModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            data={data}
            schema={schema}
            success="Aile bilgileriniz başarıyla güncellendi"
            error="Aile bilgileriniz güncellenirken bir hata oluştu"
            title="Aile"
        >
            <Col span={12}>
                <MySelect
                    name="marital_status"
                    label="Medeni Durum"
                    options={[
                        { label: 'Bekar', value: 'bekar' },
                        { label: 'Evli', value: 'married' },
                        { label: 'Dul', value: 'dul' },
                        { label: 'Boşanmış', value: 'boşanmış' }
                    ]}
                />
            </Col>
            <Col span={12}>
                <MySelect
                    label="Çocuk Sayısı"
                    name="number_of_children"
                    options={[
                        { label: 'Yok', value: 0 },
                        { label: '1', value: 1 },
                        { label: '2', value: 2 },
                        { label: '3', value: 3 },
                        { label: '4', value: 4 },
                        { label: '5', value: 5 },
                        { label: '6', value: 6 },
                        { label: '7', value: 7 },
                        { label: '8', value: 8 },
                        { label: '9', value: 9 },
                        { label: '10', value: 10 },
                        { label: '11', value: 11 },
                        { label: '12', value: 12 },
                        { label: '13', value: 13 },
                        { label: '14', value: 14 },
                        { label: '15', value: 15 },
                        { label: '16', value: 16 },
                        { label: '17', value: 17 },
                        { label: '18', value: 18 },
                        { label: '19', value: 19 }
                    ]}
                />
            </Col>
        </ChangeModal>
    );
};

export default FamilyModal;

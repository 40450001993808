import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Row, Col } from 'antd';
import MySelect from '../MySelect';
import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import paramsToObject from '../../helpers/searchParams';
import { useEffect } from 'react';

const MyTableFiltration = ({ children }) => {
    let [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation()

    const formMethods = useForm({
        defaultValues: {
            ...Object.fromEntries(
                Object.entries(paramsToObject(searchParams.entries()))
                    .filter(([key, value]) => key.startsWith('t_'))
                    .map(([key, value]) => [key.slice(2), value != 'undefined' ? value : undefined])
            )
        }
    });
    const { handleSubmit, watch, getValues } = formMethods;

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            setSearchParams((prev) => {
                const entries = prev.entries(); //returns an iterator of decoded [key,value] tuples
                const params = paramsToObject(entries);
                return queryString.stringify({
                    ...params,
                    ...Object.fromEntries(
                        Object.entries(getValues()).map(([key, value]) => [
                            `t_${key}`,
                            value
                        ])
                    )
                });
            });
        });

        return () => subscription.unsubscribe();
    }, [watch, setSearchParams, getValues]);

    useEffect(() => {
        formMethods.reset({
            ...Object.fromEntries(
                Object.entries(paramsToObject(searchParams.entries()))
                    .filter(([key, value]) => key.startsWith('t_'))
                    .map(([key, value]) => [key.slice(2), value])
            )
        });
    }, [location.search])
    return (
        <FormProvider {...formMethods}>
            <Form onFinish={handleSubmit}>
                <Row gutter={[16, 24]}>
                    {children}
                </Row>
            </Form>
        </FormProvider>
    );
};

export default MyTableFiltration;

import { Card, Col, Row } from 'antd';
import React, { useState } from 'react';
import {
    AuditOutlined,
    FormOutlined,
    UserOutlined,
    WalletOutlined
} from '@ant-design/icons';
import CreateReportModal from '../../components/CreateReportModal';
import { useTranslation } from 'react-i18next';
import FieldModal from '../../components/FieldModal/FieldModal';
import { useParams } from 'react-router-dom';
import ReportDetail from './ReportDetail';
import { Header } from '../../components/Header';
const { Meta } = Card;

const data = {
    financialReports: [
        {
            id: 'monthlyCost',
            tag: 'REPORTS.MONTHLY_COST_REPORT',
            filterByEmploymentStartDate: false
        },
        {
            id: 'employeePaymentInfo',
            tag: 'REPORTS.SALARY_INFO',
            filterByEmploymentStartDate: true
        },
        {
            id: 'transactionCost',
            tag: 'REPORTS.TRANSACTION_COST',
            filterByEmploymentStartDate: false
        }
    ],
    executiveReports: [
        {
            id: 'unusedLeaveAllowances',
            tag: 'REPORTS.EMPLOYEE_LEAVE_INFO',
            filterByEmploymentStartDate: true
        },
        {
            id: 'leaveReport',
            tag: 'REPORTS.EMPLOYEE_MONTHLY_LEAVE_REPORT',
            filterByEmploymentStartDate: false
        },
        {
            id: 'communicationInformation',
            tag: 'REPORTS.EMPLOYEE_COMMUNICATION_INFO_REPORT',
            filterByEmploymentStartDate: true
        },
        {
            id: 'assetList',
            tag: 'REPORTS.EMPLOYEE_ASSET_REPORT',
            filterByEmploymentStartDate: false
        },
        {
            id: 'employmentDuration',
            tag: 'REPORTS.EMPLOYMENT_DURATION',
            filterByEmploymentStartDate: true
        },
        {
            id: 'terminatedEmployees',
            tag: 'REPORTS.TERMINATED_EMPLOYEES_INFO',
            filterByEmploymentStartDate: false
        },
        {
            id: 'turnover',
            tag: 'REPORTS.TURNOVER_INFO',
            filterByEmploymentStartDate: true
        },
        {
            id: 'accountAccess',
            tag: 'REPORTS.ACCOUNT_ACCESS_REPORT',
            filterByEmploymentStartDate: false
        },
        {
            id: 'leaveRule',
            tag: 'REPORTS.EMPLOYEE_LEAVE_RULE_REPORT',
            filterByEmploymentStartDate: true
        },
        {
            id: 'approvalProcesses',
            tag: 'REPORTS.APPROVAL_PROCESSES',
            filterByEmploymentStartDate: false
        },
        {
            id: 'hiring',
            tag: 'REPORTS.HIRING_REPORT',
            filterByEmploymentStartDate: false
        }
    ],
    staffReports: [
        {
            id: 'ageDistribution',
            tag: 'REPORTS.EMPLOYEE_AGE_DIST',
            filterByEmploymentStartDate: true
        },
        {
            id: 'genderDistribution',
            tag: 'REPORTS.EMPLOYEE_GENDER_DIST',
            filterByEmploymentStartDate: true
        },
        {
            id: 'militaryService',
            tag: 'REPORTS.EMPLOYEE_MILITARY_SERVICE_DIST',
            filterByEmploymentStartDate: true
        },
        {
            id: 'employeeEducationalStatus',
            tag: 'REPORTS.EMPLOYEE_EDUCATION_LEVEL_DIST',
            filterByEmploymentStartDate: true
        },
        {
            id: 'disableEmployees',
            tag: 'REPORTS.DISABLE_EMPLOYEES',
            filterByEmploymentStartDate: true
        },
        {
            id: 'employee-list',
            tag: 'REPORTS.EMPLOYEE_LIST',
            filterByEmploymentStartDate: true
        },
        {
            id: 'birthdayList',
            tag: 'REPORTS.EMPLOYEE_BIRTHDAY_REPORT',
            filterByEmploymentStartDate: false
        },
        {
            id: 'trainingList',
            tag: 'REPORTS.EMPLOYEE_TRAINING_INFO',
            filterByEmploymentStartDate: false
        },
        {
            id: 'unitsDistribution',
            tag: 'REPORTS.EMPLOYEE_UNIT_DIST',
            filterByEmploymentStartDate: true
        },
        {
            id: 'bloodTypeDistribution',
            tag: 'REPORTS.EMPLOYEE_BLOOD_TYPE_DIST',
            filterByEmploymentStartDate: true
        },
        {
            id: 'specialFields',
            tag: 'REPORTS.EMPLOYEE_SPECIAL_FIELDS',
            filterByEmploymentStartDate: true
        },
        {
            id: 'workingDayCalendar',
            tag: 'REPORTS.WORKING_DAY_CALENDAR_REPORT',
            filterByEmploymentStartDate: false
        }
    ]
};

const columns = [
    {
        icon: <UserOutlined style={{ fontSize: '64px', padding: '24px' }} />,
        title: 'Çalışan',
        description:
            'Yaş, cinsiyet, eğitim durumu gibi dağılım bilgilerini kullanarak raporlar oluşturun.',
        id: 'staffReports'
    },
    {
        icon: <WalletOutlined style={{ fontSize: '64px', padding: '24px' }} />,
        title: 'Finans',
        description:
            'Aylık maliyet, maaş, diğer ödeme bilgileriyle ilgili raporlar oluşturun.',
        id: 'financialReports'
    },
    {
        icon: <AuditOutlined style={{ fontSize: '64px', padding: '24px' }} />,
        title: 'İdari',
        description:
            'Çalışan izin, zimmet, turnover gibi bilgileri kullanarak raporlar oluşturun.',
        id: 'executiveReports'
    },
    {
        icon: <FormOutlined style={{ fontSize: '64px', padding: '24px' }} />,
        title: 'Kendin Oluştur',
        description:
            'İstediğiniz alanları seçerek kendinize özel rapor oluşturun.'
    }
];

const Reports = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [type, setType] = useState('financialReports');
    const [open, setOpen] = useState(true);

    if (id) {
        return <ReportDetail id={id} />;
    }

    return (
        <>
            <Header />
            <Row gutter={[16, 16]}>
                <FieldModal />
                <title>{t('Reports.title')}</title>
                <CreateReportModal
                    data={data[type]}
                    open={open}
                    setOpen={setOpen}
                />
                {columns.map((column, index) => (
                    <Col lg={6} key={index} flex="auto">
                        <Card
                            hoverable
                            cover={column.icon}
                            onClick={() => {
                                if (column.id) {
                                    setType(column.id);
                                    setOpen(true);
                                } else {
                                }
                            }}
                            style={{
                                height: '100%'
                            }}
                        >
                            <Meta
                                title={column.title}
                                description={column.description}
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default Reports;

import dayjs from "dayjs";
import { z } from "zod";

const momentSchema = z.union([
    z.null(),
    z.custom(
        (value) => {
            // Day.js objesi olup olmadığını kontrol ediyoruz
            return dayjs.isDayjs(value) && value.isValid();
        },
        {
            message: 'Invalid date'
        }
    )
]);

export {
    momentSchema
}
import { Card, Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const SystemSettings = () => {
  return (
    <>
      <Card title="System Settings">
        <Row gutter={[16, 16]}>
          <Col sm={24} md={8}>
            <Card title="Şirket ayarları" extra={<a href="#">Düzenle</a>}>
              test
            </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={[16, 16]}>
          <Col sm={24} md={8}>
            <Card title="İzin ayarları" extra={<Link to={'/settings/system-settings/leave-settings'}>Düzenle</Link>}>
              Şirketinizin izin ayarlarını düzenleyin. İzinleri kimin onaylayabileceğini, kimin oluşturabileceğini
              düzenleyin.
            </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={[16, 16]}>
          <Col sm={24} md={8}>
            <Card title="Avans ayarları" extra={<Link to={'/settings/system-settings/expense-settings'}>Düzenle</Link>}>
              Şirketinizin avans ayarlarını düzenleyin. Avansları kimin onaylayabileceğini, avans seçeneklerini
              düzenleyin.
            </Card>
          </Col>
        </Row>
        <br />

        <Row gutter={[16, 16]}>
          <Col sm={24} md={8}>
            <Card
              title="Doğum günü ayarları"
              extra={<Link to={'/settings/system-settings/birthday-settings'}>Düzenle</Link>}
            >
              Şirketinizin doğum günü ayarlarını düzenleyin.
            </Card>
          </Col>
        </Row>
        <br />

        <Row gutter={[16, 16]}>
          <Col sm={24} md={8}>
            <Card title="Ayrılma Nedeni Ayarları" extra={<Link to={'/settings/system-settings/departure-reason'}>Düzenle</Link>}>
              Şirketinizin ayrılma nedeni ayarlarını düzenleyin. Ayrılma nedenlerini kimin ayarlayabileceğini ve ayrılma nedeni seçeneklerini düzenleyin.
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default SystemSettings;

import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useLazyQuery } from '../../hooks/useAxios';

const filterTableParams = (params) => {
    return Object.fromEntries(
        Object.entries(params).filter(([key, value]) => value !== 'all')
    );
}

const useMyTable = ({
    columns = [],
    initialHiddenColumns = [],
    url = '',
    initialPageSize = 10,
    maxPageSize = 50,
    enablePagination = true,
    enableSorting = true,
    enableSearching = false,
    refreshTable,
    isOffline = false
}) => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const parseParams = useCallback(
        (search) => {
            const params = queryString.parse(search);
            const pageSize = Math.min(
                initialPageSize || parseInt(params.pageSize),
                maxPageSize
            );
            return {
                pagination: enablePagination
                    ? {
                        current:
                            (initialPageSize || parseInt(params.pageSize)) >
                                maxPageSize
                                ? 1
                                : parseInt(params.page) || 1,
                        pageSize: pageSize
                    }
                    : null,
                sorter: enableSorting
                    ? {
                        field: params.sortField || null,
                        order: params.sortOrder || null
                    }
                    : null,
                search: enableSearching ? params.search || '' : null,
                ...params
            };
        },
        [
            initialPageSize,
            maxPageSize,
            enablePagination,
            enableSorting,
            enableSearching
        ]
    );

    const [tableParams, setTableParams] = useState(() =>
        parseParams(location.search)
    );

    const [hiddenColumns, setHiddenColumns] = useState(initialHiddenColumns);

    const { data, loading, refetch } = useLazyQuery(url);

    const updateUrlParams = useCallback(
        (params) => {
            const sanitizedParams = {
                ...(enablePagination && { page: params?.pagination?.current }),
                ...(enablePagination && {
                    pageSize: Math.min(params.pagination?.pageSize, maxPageSize)
                }),
                sortField: params.sorter?.field,
                sortOrder: params.sorter?.order,
                search: params.search
            };

            const prevParams = queryString.parse(location.search);
            const newParams = {
                ...prevParams,
                ...sanitizedParams
            };
            setSearchParams(queryString.stringify(newParams));
        },
        [maxPageSize, enablePagination, location.search, tableParams]
    );

    const fetchData = useCallback(
        (extraParams = {}) => {

            const newParams = {
                ...filterTableParams(tableParams),
                pagination: {
                    ...tableParams.pagination,
                    ...extraParams.pagination
                },
                sorter: {
                    ...tableParams.sorter,
                    ...extraParams.sorter
                },
                search: tableParams.search
            };
            const { current, pageSize } = newParams.pagination;
            const { field, order } = newParams.sorter;
            const { search } = newParams;
            const tStarts = Object.fromEntries(
                Object.entries(newParams)
                    .filter(([key, value]) => key.startsWith('t_'))
                    .map(([key, value]) => [key.slice(2), value])
            );
            const params = {
                page: current,
                pageSize: pageSize,
                sort_by:
                    field && order
                        ? `${field}_${order == 'ascend' ? 'asc' : 'desc'}`
                        : null,
                search: search || null
            };
            const withTParams = {
                ...params,
                ...tStarts
            };
            if (!isOffline) {
                refetch({ params: withTParams }).then((result) => {
                    if (result && result.data) {
                        const { results = [], total = 0 } = result.data;
                        if (results.length === 0 && current > 1) {
                            const forPaginationParams = {
                                ...params,
                                page: 1,
                                ...Object.fromEntries(
                                    Object.entries(tStarts).map(
                                        ([key, value]) => [`t_${key}`, value]
                                    )
                                )
                            };
                            setSearchParams(
                                queryString.stringify(forPaginationParams)
                            );
                        } else {
                            setTableParams((prev) => ({
                                ...prev,
                                ...tStarts,
                                pagination: enablePagination && {
                                    ...prev.pagination,
                                    total: total
                                }
                            }));
                        }
                    }
                });
            }
        },
        [
            tableParams?.pagination?.current,
            tableParams?.pagination?.pageSize,
            tableParams?.sorter,
            tableParams?.search,
            refetch,
            isOffline,
            enablePagination
        ]
    );

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (refreshTable) {
            refreshTable(fetchData);
        }
    }, [refreshTable, fetchData]);

    const [firstRender, setFirstRender] = useState(false);

    useEffect(() => {
        if (firstRender) {
            setTableParams(parseParams(location.search));
        }
        setFirstRender(true);
    }, [location.search, parseParams]);

    const handleTableChange = useCallback(
        (pagination, filters, sorter) => {
            console.log(pagination, 'asd', tableParams);
            let newParams = {}
            if (
                pagination.current === tableParams.pagination.current &&
                pagination.pageSize === tableParams.pagination.pageSize
            ) {
                const pageSize = Math.min(pagination.pageSize, maxPageSize);
                const newPagination = {
                    ...pagination,
                    pageSize: pageSize,
                    current: 1
                };
                newParams = {
                    pagination: newPagination,
                    sorter: {
                        field: sorter.field,
                        order: sorter.order
                    },
                    search: tableParams.search || undefined // Ensure search is preserved
                };
            } else {
                const pageSize = Math.min(pagination.pageSize, maxPageSize);
                const newPagination = {
                    ...pagination,
                    pageSize: pageSize,
                    current: pagination.current
                };
                newParams = {
                    pagination: newPagination,
                    sorter: {
                        field: sorter.field,
                        order: sorter.order
                    },
                    search: tableParams.search || undefined // Ensure search is preserved
                };
            }
            updateUrlParams(newParams);
        },
        [maxPageSize, tableParams.search, updateUrlParams]
    );

    const handleToggleColumn = useCallback(
        (checkedValues) => {
            setHiddenColumns(
                columns
                    .map((column) => column.key)
                    .filter((key) => !checkedValues.includes(key))
            );
        },
        [columns]
    );

    const handleSearch = useCallback(
        (value) => {
            const newParams = {
                ...tableParams,
                search: value,
                pagination: {
                    ...tableParams.pagination,
                    current: 1,
                    pageSize: Math.min(
                        tableParams.pagination.pageSize,
                        maxPageSize
                    )
                }
            };
            setTableParams(newParams);
            updateUrlParams(newParams);
        },
        [tableParams, maxPageSize, updateUrlParams]
    );

    const getColumns = useCallback(() => {
        return columns.filter((column) => {
            const defaultSort =
                tableParams.sorter.field === column.key &&
                tableParams.sorter.order;

            column.defaultSortOrder = defaultSort;
            return !hiddenColumns.includes(column.key);
        });
    }, [columns, hiddenColumns]);

    const pageSizeOptions = [10, 25, 50, 100];

    const newPagination = {
        ...tableParams.pagination,
        total: data?.data?.total || data?.data?.row_count,
        pageSizeOptions: pageSizeOptions.filter((e) => e <= maxPageSize)
    };

    return {
        columns,
        data: data?.data?.results || data?.data || [],
        loading,
        handleTableChange,
        pagination: newPagination,
        handleToggleColumn,
        hiddenColumns,
        handleSearch,
        getColumns,
        defaultSearchValue: tableParams.search
    };
};

export default useMyTable;

import React from 'react';
import { Col, Typography } from 'antd';
import EducationModal from './EducationModal';
import DataCard from '../DataCard/DataCard';

const { Text } = Typography;

const Education = ({ data }) => {
    return (
        <DataCard
            title="Eğitim"
            Extra={true}
            data={data}
            Modal={EducationModal}
        >
            <Col span={12}>
                <Text strong>Eğitim Durumu:</Text>{' '}
                {data?.education_status || '—'}
            </Col>
            <Col span={12}>
                <Text strong>Tamamlanan En Yüksek Eğitim Seviyesi:</Text>{' '}
                {data?.education_level || '—'}
            </Col>
            <Col span={12}>
                <Text strong>Son Tamamlanan Eğitim Kurumu:</Text>{' '}
                {data?.educational_institution || '—'}
            </Col>
        </DataCard>
    );
};

export default Education;

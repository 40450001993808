import { ConfigProvider, App as AntApp } from 'antd';
import React from 'react';
import { Provider } from 'react-redux';
import store from '../store/store';
import trTR from 'antd/locale/tr_TR';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';

dayjs.locale('tr');

const Providers = ({ children }) => {
    return (
        <Provider store={store}>
            <ConfigProvider
                locale={trTR}
                theme={{
                    token: {
                        fontSize: 13
                    },
                    components: {
                        Modal: {
                            wireframe: true,
                            titleFontSize: 18
                        }
                        // Input: {
                        //     colorBgContainer: '#f0f2f5',
                        // },
                        // InputNumber: {
                        //     colorBgContainer: '#f0f2f5'
                        // },
                        // DatePicker: {
                        //     colorBgContainer: '#f0f2f5'
                        // },
                        // Select: {
                        //     colorBgContainer: '#f0f2f5',
                        //     fontSize: 13
                        // }
                    }
                }}
                modal={{
                    styles: {
                        header: {
                            // borderBottom: '1px solid rgba(22, 42, 76, .15)'
                            // marginBottom: 32,
                            padding: '20px 24px'
                        },
                        body: {
                            padding: '10px 24px'
                        }
                    }
                }}
            >
                <AntApp>{children}</AntApp>
            </ConfigProvider>
        </Provider>
    );
};

export default Providers;

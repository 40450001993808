import React from 'react'
import MyModal from '../../components/MyModal'
import { useForm } from 'react-hook-form'
import { Col } from 'antd'
import MyInput from '../../components/MyInput'
import MySelect from '../../components/MySelect'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import dayjs from 'dayjs'
import { momentSchema } from '../../helpers/schema'

const schema = z.object({
    fire_date: momentSchema,
    fire_reason: z.string().nonempty(),
    fire_note: z.string().nonempty(),
})

const FireEmployeeModal = ({ isOpen, setIsOpen, onSubmit, loading }) => {
    const form = useForm({
        resolver: zodResolver(schema),
    })
    const { handleSubmit } = form

    return (
        <MyModal
            title="Role Modal"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            handleSubmit={handleSubmit(onSubmit)}
            confirmLoading={loading}
            rowGap={16}
            formMethods={form}
        >
            <Col span={24} >
                <MyInput type='date' name="fire_date" label="Fire Date" />
            </Col>
            <Col span={24}>
                <MySelect name="fire_reason" label="Fire Reason" options={[
                    { label: 'Reason 1', value: '1' },
                    { label: 'Reason 2', value: '2' },
                    { label: 'Reason 3', value: '3' },
                    { label: 'Reason 4', value: '4' },
                ]} />
            </Col>
            <Col span={24}>
                <MyInput type='textarea' name="fire_note" label="Fire Note" />
            </Col>
        </MyModal>
    )
}

export default FireEmployeeModal

import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'

const Footer = () => {

    return (
        <div style={{ textAlign: 'center', backgroundColor: '#f0f2f5', padding: '10px 0' }}>
          <p>&copy; {new Date().getFullYear()} Skript Digital Tüm hakları saklıdır.</p>
          <p>
        <Link to="#">KVKK Aydınlatma Metni</Link> 
        <span className="separator"> | </span>
        <Link to="#">Gizlilik Politikası</Link>
        <span className="separator"> | </span>
        <Link to="#" className="terms-link">Kullanım Şartları</Link>
      </p>
        </div>
      );
    };

export default Footer;